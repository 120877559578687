import React, { Component } from "react";
import { API } from "aws-amplify";
import { PatientRecord } from "../../models/PatientRecord";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  IconButton,
  Fab,
  Tooltip,
  Typography,
  Paper,
  Snackbar,
  CircularProgress,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
} from "@mui/material";
import { PersonAdd, Delete } from "@mui/icons-material";
import { AddPatientRecordDialog } from "./AddPatientRecordDialog";
import { RouteComponentProps } from "react-router";
import { DateView } from "../utils/DateView";
import { Alert } from "@mui/material";
import DotIcon from "../../images/Icons/DotIcon";
import DockIcon from "../../images/Icons/DockIcon";

interface IPathParams {
  groupId: string;
}

interface IPatientManagementState {
  patientRecords: PatientRecord[];
  openAddPatientRecordDialog: boolean;
  groupName: string;
  groupId: string;
  errorText: string | undefined;
  inProgress: boolean;
}

interface IPatientRecordsRouteState {
  groupName: string;
}

export class PatientManagement extends Component<
  RouteComponentProps<IPathParams, {}, IPatientRecordsRouteState>,
  IPatientManagementState
> {
  constructor(props: any) {
    super(props);

    let groupName: string;
    const groupId: string = this.props.match.params.groupId;
    if (
      this.props.location.state &&
      this.props.location.state.groupName !== ""
    ) {
      groupName = this.props.location.state.groupName;
    } else {
      groupName = this.props.match.params.groupId;

      API.get("BytefliesCloudPlatformAPI", `groups`, {}).then((response) => {
        if (response && response instanceof Array) {
          const found = response.find((element) => element.groupId === groupId);
          if (found) {
            this.setState({ groupName: found.groupName });
          }
        }
      });
    }

    this.state = {
      patientRecords: [],
      openAddPatientRecordDialog: false,
      groupName: groupName,
      groupId: groupId,
      errorText: undefined,
      inProgress: false,
    };
  }

  public componentDidMount() {
    this.getPatientRecords();
  }

  public render() {
    return (
      <div>
        <Paper className={"patient-container"}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Patient</TableCell>
                <TableCell>Start time</TableCell>
                <TableCell>End time</TableCell>
                <TableCell>Devices</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.patientRecords.length > 0 ? (
                this.state.patientRecords.map((patientRecord) => {
                  return (
                    <TableRow key={patientRecord.id} hover={true}>
                      <TableCell>{patientRecord.patient}</TableCell>
                      <TableCell>
                        {DateView.toString(
                          new Date(patientRecord.startTime * 1000)
                        )}
                      </TableCell>
                      <TableCell>
                        {DateView.toString(
                          new Date(patientRecord.endTime * 1000)
                        )}
                      </TableCell>
                      <TableCell>
                        <List dense={true}>
                          {patientRecord.devices?.map((d, i) => {
                            return (
                              <ListItem key={i}>
                                <ListItemIcon>
                                  {d.type === "dock" ? <DockIcon /> : ""}
                                  {d.type === "dot" ? <DotIcon /> : ""}
                                </ListItemIcon>
                                <ListItemText>{d.name}</ListItemText>
                              </ListItem>
                            );
                          })}
                        </List>
                      </TableCell>
                      <TableCell align="center">
                        <Grid
                          container
                          justifyContent="center"
                          direction="row"
                          spacing={0}
                        >
                          <Grid item>
                            <Tooltip title="Delete patient record">
                              <IconButton
                                size="small"
                                aria-label="patientrecords"
                                onClick={() => {
                                  if (patientRecord !== undefined) {
                                    this.deletePatientRecord(patientRecord);
                                  }
                                }}
                                disabled={
                                  patientRecord === undefined ||
                                  patientRecord.id === undefined
                                }
                              >
                                <Delete color="primary" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : this.state.inProgress ? (
                <TableRow key={"empty"}>
                  <TableCell colSpan={3} align="center">
                    <CircularProgress color="inherit" />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={"empty"}>
                  <TableCell colSpan={3}>
                    <Typography align="center">
                      No patient records were found.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
        <AddPatientRecordDialog
          open={this.state.openAddPatientRecordDialog}
          groupName={this.state.groupName}
          groupId={this.state.groupId}
          onClose={() => {
            this.getPatientRecords();
            this.setState({
              openAddPatientRecordDialog: false,
            });
          }}
        />

        <Tooltip title="Add a patient record" aria-label="add">
          <Fab
            sx={{
              position: "fixed",
              bottom: "15px",
              right: "15px",
            }}
            color="primary"
            aria-label="add"
            onClick={() => {
              this.setState({
                openAddPatientRecordDialog: true,
              });
            }}
          >
            <PersonAdd />
          </Fab>
        </Tooltip>
        <Snackbar
          open={this.state.errorText !== undefined}
          autoHideDuration={10000}
          onClose={() => {
            this.setState({ errorText: undefined });
          }}
        >
          <Alert
            onClose={() => {
              this.setState({ errorText: undefined });
            }}
            severity="error"
          >
            {this.state.errorText}
          </Alert>
        </Snackbar>
      </div>
    );
  }

  private getPatientRecords() {
    this.setState({ inProgress: true });
    API.get(
      "BytefliesCloudPlatformAPI",
      `groups/${this.props.match.params.groupId}/patientrecords`,
      {}
    ).then(
      (response: PatientRecord[]) => {
        response.sort((a: PatientRecord, b: PatientRecord) => {
          if (!a.patient || !b.patient) {
            return 0;
          } else {
            return a.patient.localeCompare(b.patient);
          }
        });
        this.setState({
          patientRecords: response,
          inProgress: false,
        });
      },
      (rejected) => {
        console.log(rejected);
        this.setState({
          patientRecords: [],
          errorText: "Something went wrong getting the patient records.",
          inProgress: false,
        });
      }
    );
  }

  private deletePatientRecord(patientRecord: PatientRecord) {
    if (patientRecord === undefined || patientRecord.id === undefined) {
      console.error("patient record is undefined");
      return;
    }

    this.setState({ inProgress: true });
    API.del(
      "BytefliesCloudPlatformAPI",
      `groups/${this.props.match.params.groupId}/patientrecords/${patientRecord.id}`,
      {}
    ).then(
      (response: any) => {
        this.getPatientRecords();
      },
      (rejected) => {
        console.log(rejected);
        this.setState({
          errorText: "Something went wrong deleting the patient record.",
          inProgress: false,
        });
        this.getPatientRecords();
      }
    );
  }
}
