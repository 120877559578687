import React, { useState } from "react";
import { Grid, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { SampleRateSelection } from "./SampleRateSelection";
import { IDockConfiguration } from "./DockConfigurationInterface";
import {
  getConfigurationProfiles,
  IDockConfigurationProfile,
} from "./ConfigurationProfiles";
import { SignalTypeSelection } from "./SignalTypeSelection";

interface IEditorProps {
  defaultConfiguration: IDockConfiguration;
  onChange: (event: IDockConfiguration) => void;
}

export function ConfigurationEditor(props: IEditorProps) {
  const profiles = getConfigurationProfiles();
  const [myConfiguration, setMyConfiguration] = useState({
    ...props.defaultConfiguration,
    title: "",
  } as IDockConfigurationProfile);

  const identical = (
    profile1: IDockConfigurationProfile,
    profile2: IDockConfigurationProfile
  ) => {
    return (
      profile1.title === profile2.title &&
      profile1.channelOneConfig === profile2.channelOneConfig &&
      profile1.channelTwoConfig === profile2.channelTwoConfig &&
      profile1.gyroscopeFs === profile2.gyroscopeFs &&
      profile1.gyroscopeOn === profile2.gyroscopeOn &&
      profile1.accelerometerFs === profile2.accelerometerFs &&
      profile1.accelerometerOn === profile2.accelerometerOn
    );
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          Select a base profile:
          <RadioGroup>
            {profiles.map((profile, i) => (
              <FormControlLabel
                value={i}
                label={profile.title}
                key={i}
                control={
                  <Radio
                    checked={identical(myConfiguration, profile)}
                    onChange={(_) => {
                      const clone = { ...profile } as IDockConfigurationProfile;
                      setMyConfiguration(clone);
                      props.onChange(clone);
                    }}
                    key={i}
                  />
                }
              />
            ))}
          </RadioGroup>
        </Grid>

        <Grid item xs={12}>
          &nbsp;
        </Grid>

        <Grid item xs={4}>
          Channel 1:
        </Grid>
        <Grid item xs={8}>
          <SignalTypeSelection
            value={myConfiguration.channelOneConfig}
            onChange={(signalType) => {
              const clone = {
                ...myConfiguration,
                channelOneConfig: signalType,
              };
              setMyConfiguration(clone);
              props.onChange(clone);
            }}
          />
        </Grid>

        <Grid item xs={4}>
          Channel 2:
        </Grid>
        <Grid item xs={8}>
          <SignalTypeSelection
            value={myConfiguration.channelTwoConfig}
            onChange={(signalType) => {
              const clone = {
                ...myConfiguration,
                channelTwoConfig: signalType,
              };
              setMyConfiguration(clone);
              props.onChange(clone);
            }}
          />
        </Grid>

        <Grid item xs={4}>
          Accelerometer:
        </Grid>
        <Grid item xs={3}>
          Sample Rate
        </Grid>
        <Grid item xs={5}>
          <SampleRateSelection
            value={myConfiguration.accelerometerFs}
            onSampleRateChange={(enabled, sampleRate) => {
              const clone = {
                ...myConfiguration,
                accelerometerOn: enabled,
                accelerometerFs: sampleRate,
              };
              setMyConfiguration(clone);
              props.onChange(clone);
            }}
          />
        </Grid>

        <Grid item xs={4}>
          Gyroscope:
        </Grid>
        <Grid item xs={3}>
          Sample Rate
        </Grid>
        <Grid item xs={5}>
          <SampleRateSelection
            value={myConfiguration.gyroscopeFs}
            onSampleRateChange={(enabled, sampleRate) => {
              const clone = {
                ...myConfiguration,
                gyroscopeOn: enabled,
                gyroscopeFs: sampleRate,
              };
              setMyConfiguration(clone);
              props.onChange(clone);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
