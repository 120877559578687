import React, { Component } from "react";
import { TextField, Chip, CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { getDocksOfGroup } from "../../api";
import DotIcon from "../../images/Icons/DotIcon";
import DockIcon from "../../images/Icons/DockIcon";

interface IDialogState {
  possibleDockNames: string[];
  inProgress: boolean;
}

export interface Device {
  type: string;
  name: string;
}

export interface DeviceSelectionEvent {
  devices: Device[];
}

export interface IDialogProps {
  groupId?: string;
  onChange: (event: DeviceSelectionEvent) => void;
}

export class DeviceSelection extends Component<IDialogProps, IDialogState> {
  constructor(props: IDialogProps) {
    super(props);
    this.state = {
      inProgress: true,
      possibleDockNames: [],
    };
  }

  componentDidMount() {
    if (this.props.groupId) {
      getDocksOfGroup(this.props.groupId).then(
        (docks) => {
          if (docks) {
            this.setState({
              possibleDockNames: docks,
              inProgress: false,
            });
          }
        },
        (rejected) => {
          console.error(rejected);
          this.setState({
            inProgress: false,
            possibleDockNames: [],
          });
        }
      );
    }
  }

  public render() {
    return (
      <Autocomplete
        multiple={true}
        options={this.state.possibleDockNames}
        freeSolo={true}
        filterSelectedOptions={true}
        limitTags={3}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              key={index}
              variant="outlined"
              icon={this.isDock(option) ? <DockIcon /> : <DotIcon />}
              label={option}
              // {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Devices (dock names or dot ID's)"
            placeholder="Press enter to add"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {this.state.inProgress ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        onChange={(_, value) => {
          const devices = value.map((name) => {
            return {
              type: this.isDock(name) ? "dock" : "dot",
              name: name,
            } as Device;
          });

          this.props.onChange({
            devices: devices,
          } as DeviceSelectionEvent);
        }}
      />
    );
  }

  private isDock(name: string): boolean {
    return name.toLowerCase().startsWith("dock");
  }
}
