import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormGroup,
  Typography,
} from "@mui/material";
import { API } from "aws-amplify";
import React, { Component } from "react";

export interface IApiRequestState {
  response: string | undefined;
}

export class ApiRequest extends Component<{}, IApiRequestState> {
  constructor(props: any) {
    super(props);
    this.state = { response: undefined };
  }

  public async sendGET(): Promise<any> {
    API.get("BytefliesCloudPlatformAPI", "hello", {}).then(
      (response) => {
        this.setState({ response: response.message });
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  }

  public render() {
    const style = {
      width: "50vw",
      margin: "auto",
    };
    return (
      <Card style={style}>
        <CardContent>
          <Typography>Test the helloworld API Endpoint.</Typography>
          <textarea
            value={this.state.response}
            style={{ width: "100%", height: "10vh" }}
          ></textarea>
        </CardContent>
        <CardActions style={{ justifyContent: "center" }}>
          <FormGroup>
            <Button
              color={"secondary"}
              variant={"contained"}
              type="submit"
              onClick={() => this.sendGET()}
            >
              Send GET Request
            </Button>
          </FormGroup>
        </CardActions>
      </Card>
    );
  }
}
