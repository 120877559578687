import React, {useState} from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {DeviceBleConfiguration} from "../../../dist/openapi/recording-service";
import {addBleDevice} from "../../api";

interface IDialogProps {
  open: boolean;
  onClose: () => void;
}
export function AddBleDeviceDialog(props: IDialogProps) {
  const [inProgress, setInProgress] = useState(false);
  const { open, onClose } = props;
  const [mac, setMac] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [passkey, setPasskey] = useState("");
  const [apiError, setApiError] = useState<Error>();

  const createModel = (): DeviceBleConfiguration => ({
    mac: mac,
    serialNumber: serialNumber,
    authentication: passkey.length > 0 ? {
      passkey: passkey,
    } : undefined,
  });

  const canBeCreated = (device: DeviceBleConfiguration) => {
    if (device.serialNumber === "" && device.mac === "") {
      return false;
    }
    return isValidMac(device.mac);
  };

  const isValidMac = (mac: string | undefined | null) => {
    const regex =
      /((([a-zA-z0-9]{2}[-:]){5}([a-zA-z0-9]{2}))|(([a-zA-z0-9]{2}:){5}([a-zA-z0-9]{2})))$/;
    return !(mac === null ||
        mac === undefined ||
        mac === "" ||
        mac!.indexOf(":") === -1 ||
        !regex.test(mac));
  };

  const makeDeviceId = (device: DeviceBleConfiguration) => {
    let str = device.mac;
    if (str === undefined || str === null || str === "") {
      str = device.serialNumber;
    }
    if (str === undefined || str === null || str === "") {
      return "";
    }

    return str!
      .toLowerCase()
      .replaceAll(":", "")
      .replaceAll(" ", "")
      .replaceAll("-", "_")
      .replaceAll("!", "");
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add a SpotCheck device</DialogTitle>
      <DialogContent>
        {apiError && (
          <Alert severity="error">
            Something went wrong. Please try again later.
            {apiError.message}
          </Alert>
        )}
        <TextField
          disabled={true}
          margin="dense"
          name="deviceId"
          label="Device ID"
          fullWidth
          value={makeDeviceId(createModel())}
        />
        <TextField
          disabled={false}
          inputProps={{
            maxLength: 20,
          }}
          autoFocus
          margin="dense"
          name="mac"
          label="MAC address"
          fullWidth
          required
          error={mac !== "" && !isValidMac(mac)}
          helperText={
            mac !== "" && !isValidMac(mac)
              ? "Please enter a valid MAC address (e.g. 00:00:00:00:00:00)"
              : ""
          }
          value={mac}
          onChange={(e) => setMac(e.target.value)}
        />
        <TextField
          disabled={false}
          inputProps={{
            maxLength: 30,
          }}
          margin="dense"
          name="serial"
          label="Serial number"
          fullWidth
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value)}
        />
        <TextField
          disabled={false}
          inputProps={{
            maxLength: 8,
          }}
          margin="dense"
          name="passKey"
          label="Pass key"
          fullWidth
          value={passkey}
          onChange={(e) => setPasskey(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={inProgress}
          onClick={() => {
            onClose();
          }}
          color="secondary"
        >
          Cancel
        </Button>

        <Button
          disabled={inProgress || !canBeCreated(createModel())}
          onClick={async () => {
            try {
              setInProgress(true);
              setApiError(undefined);
              const device = createModel();
              const deviceId = makeDeviceId(device);

              console.log(deviceId, JSON.stringify(device));
              await addBleDevice(deviceId, device);
              onClose();
            } catch (error) {
              const e = error as Error;
              console.error(e);
              setApiError(e);
            } finally {
              setInProgress(false);
            }
          }}
          color="primary"
        >
          {inProgress ? <CircularProgress /> : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
