import { API } from "aws-amplify";
import {
  ListDevicesFromDockResponseBody,
  GetDockStatusResponse,
  DeviceBleConfiguration,
  OmhDataPointResponseBody,
} from "../../dist/openapi/recording-service";

export interface DockApi {
  dockId: string;
}

export interface DockAutoUpdate {
  dockId: string;
  autoUpdate: boolean;
}

export interface DeviceInfo {
  Info: {
    DeviceID: string;
    FirstSeen: string;
    AutoUpgrade: boolean;
    DeviceType: string;
    CurrentArtifact: string;
    DesiredArtifact: string;
    ResolvedArtifact: string;
    GroupName: string;
  };
  Attributes: {};
  DeploymentLastFive: {
    Started: string;
    LastUpdated: string;
    DStatus: string;
    Substate: string;
    InitialArtifact: string;
    TargetArtifact: string;
    Logfile: string;
  }[];
}

async function getAvailableDocks(): Promise<string[]> {
  return API.get("BytefliesCloudPlatformAPI", "docks?available=true", {}).then(
    (response) => {
      if (response && response.docks && response.docks instanceof Array) {
        return response.docks.map((value: DockApi) => value.dockId);
      }
      return [];
    }
  );
}

async function getAllDocks(): Promise<DockApi[]> {
  return API.get("BytefliesCloudPlatformAPI", "docks", {}).then((response) => {
    if (response && response.docks && response.docks instanceof Array) {
      return response.docks;
    }
    return [];
  });
}

export interface DockConnection {
  thingName: string;
  firmwareVersion: string;
  desiredFirmwareVersion: string;
  groupId: string;
  connectivity: boolean;
  timestamp: number;
  // dots: DotStatus[];
}

export async function getDockConnections(
  groupId: string | undefined,
  dockId?: string
): Promise<DockConnection[]> {
  let url =
    groupId === undefined || groupId === ""
      ? `dockconnections`
      : `groups/${groupId}/DockConnections`;

  if (dockId !== undefined && dockId !== null && dockId !== "") {
    url = `${url}?dockId=${dockId}`;
  }
  return API.get("BytefliesCloudPlatformAPI", url, {}).then(
    (response) => {
      if (response && response instanceof Array) {
        const docks = response as DockConnection[];
        return docks;
      }
      throw new Error("Response is not an array");
    },
    (rejected) => {
      const err = rejected as Error;
      throw err;
    }
  );
}

export async function getDockStatus(
  groupId: string | undefined,
  dockId: string
): Promise<GetDockStatusResponse> {
  const url =
    groupId === undefined || groupId === ""
      ? `/docks/${dockId}/status`
      : `groups/${groupId}/docks/${dockId}/status`;
  return API.get("BytefliesCloudPlatformAPI", url, {}).then(
    (response) => {
      if (response && typeof response === "object") {
        const dock = response as GetDockStatusResponse;
        return dock;
      }
      throw new Error("Invalid response");
    },
    (rejected) => {
      const err = rejected as Error;
      throw err;
    }
  );
}

export async function listDevicesOfDock(
  groupId: string,
  dockId: string
): Promise<ListDevicesFromDockResponseBody> {
  const url = `groups/${groupId}/docks/${dockId}/devices`;
  const response: ListDevicesFromDockResponseBody = await API.get(
    "BytefliesCloudPlatformAPI",
    url,
    {}
  );
  if (response && typeof response === "object") {
    return response;
  }
  throw new Error("Invalid response");
}

export async function deleteDeviceFromDock(
  groupId: string,
  dockId: string,
  deviceId: string
): Promise<void> {
  const url = `groups/${groupId}/docks/${dockId}/devices/${deviceId}`;
  return await API.del("BytefliesCloudPlatformAPI", url, {});
}

export async function addDeviceToDock(
  groupId: string,
  dockId: string,
  deviceId: string
): Promise<void> {
  const url = `groups/${groupId}/docks/${dockId}/devices/${deviceId}`;
  return await API.put("BytefliesCloudPlatformAPI", url, { body: {} });
}

export async function addBleDevice(
  deviceId: string,
  conf: DeviceBleConfiguration
): Promise<void> {
  if (!conf || !conf.mac) {
    throw new Error("Invalid configuration");
  }
  const url = `devices/ble/${deviceId}`;
  return await API.put("BytefliesCloudPlatformAPI", url, { body: conf });
}

async function getDocksOfGroup(groupId: string): Promise<string[]> {
  return API.get(
    "BytefliesCloudPlatformAPI",
    `groups/${groupId}/docks`,
    {}
  ).then((response) => {
    if (response && response.docks && response.docks instanceof Array) {
      return response.docks.map((value: DockApi) => value.dockId);
    }
    return [];
  });
}

async function getAutoUpdateFromGroup(
  groupId: string
): Promise<DockAutoUpdate[]> {
  return API.get(
    "BytefliesCloudPlatformAPI",
    `groups/${groupId}/docks/autoupdate`,
    {}
  ).then((response) => {
    if (response && response instanceof Array) {
      return response;
    }
    return [];
  });
}

async function putAutoUpdateToGroup(
  groupId: string,
  updates: DockAutoUpdate[]
): Promise<any> {
  return API.put(
    "BytefliesCloudPlatformAPI",
    `groups/${groupId}/docks/autoupdate`,
    { body: updates }
  );
}

async function addDockToGroup(dockId: string, groupId: string): Promise<any> {
  return API.put(
    "BytefliesCloudPlatformAPI",
    `groups/${groupId}/docks/${dockId}`,
    {}
  ).then(
    (response) => {
      console.log(response);
    },
    (rejected) => {
      console.log(rejected);
    }
  );
}

async function deleteDockFromGroup(dockId: string, groupId: string) {
  return API.del(
    "BytefliesCloudPlatformAPI",
    `groups/${groupId}/docks/${dockId}`,
    {}
  ).then(
    (response) => {
      console.log(response);
    },
    (rejected) => {
      console.log(rejected);
    }
  );
}

interface IotJob {
  id: string;
}

async function putWifiScan(dockId: string): Promise<IotJob> {
  const response = await API.put(
    "BytefliesCloudPlatformAPI",
    `docks/${dockId}/wifi`,
    {}
  );
  return response as IotJob;
}

export type SchemaName =
  | "blood-pressure"
  | "heart-rate"
  | "body-weight"
  | "body-temperature"
  | "oxygen-saturation";

async function listDatapoints(
  groupId: string,
  dockId: string,
  createdOnOrAfter: Date,
  schemaName: SchemaName
): Promise<OmhDataPointResponseBody[]> {
  const searchParams = new URLSearchParams();
  if (dockId !== undefined) {
    searchParams.set("dockId", dockId);
  }
  searchParams.set("created_on_or_after", `${createdOnOrAfter.toISOString()}`);
  if (schemaName !== undefined) {
    searchParams.set("schema_name", schemaName);
  }

  const response = await API.get(
    "BytefliesCloudPlatformAPI",
    `groups/${groupId}/datapoints?${searchParams.toString()}`,
    {}
  );
  return response as OmhDataPointResponseBody[];
}

export {
  getAvailableDocks,
  getAllDocks,
  getDocksOfGroup,
  addDockToGroup,
  deleteDockFromGroup,
  getAutoUpdateFromGroup,
  putAutoUpdateToGroup,
  putWifiScan,
  listDatapoints,
};
