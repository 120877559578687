import React from "react";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Logo } from "./dock.svg";

export default function DockIcon(props: {}) {
  return (
    <SvgIcon {...props}>
      <Logo />
    </SvgIcon>
  );
}
