import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";
import React, { Component, useState } from "react";
import { API } from "aws-amplify";
import { putRecording } from "../../api";

interface IMoveRecordingProps {
  groupId: string;
  recordingId: string;
  open: boolean;
  onClose: () => void;
}

export function MoveRecordingDialog(props: IMoveRecordingProps) {
  const [errorText, setErrorText] = useState<string>();
  const [inProgress, setInProgress] = useState(false);
  const [targetGroupId, setTargetGroupId] = useState<string>();

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        setErrorText(undefined);
      }}
    >
      <DialogTitle id="alert-dialog-title">Move Recording</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          Moving a recording to another group should be done with caution. The
          recording will be deleted from this group and will be added to the
          target group.
        </Alert>
        <TextField
          disabled={false}
          autoFocus
          margin="dense"
          name="mac"
          label="Target group ID"
          fullWidth
          value={targetGroupId}
          onChange={(e) => setTargetGroupId(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            try {
              setInProgress(true);
              await putRecording(props.groupId, props.recordingId, {
                groupId: targetGroupId,
              });
              props.onClose();
            } catch (error) {
              console.error(error);
              setErrorText(
                "Something went wrong when moving the recording. Please try again."
              );
            } finally {
              setInProgress(false);
            }
          }}
          disabled={
            inProgress ||
            props.groupId === undefined ||
            props.groupId === "" ||
            props.recordingId === undefined ||
            props.recordingId === "" ||
            targetGroupId === undefined ||
            targetGroupId === "" ||
            targetGroupId.indexOf("-") === -1 ||
            targetGroupId === props.groupId
          }
          color="primary"
        >
          {inProgress ? <CircularProgress /> : "Move"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
