import { API } from "aws-amplify";
import { Group } from "../models/Group";

interface ApiGroup {
  groupId: string;
  groupName?: string;
  groupDescription?: string;
}

async function getGroups(): Promise<Group[]> {
  return API.get("BytefliesCloudPlatformAPI", `groups`, {}).then(
    (response) => {
      if (response && response instanceof Array) {
        return response.map(
          (value: ApiGroup) =>
            ({
              id: value.groupId,
              name: value.groupName,
              description: value.groupDescription,
            } as Group)
        );
      }
      return [];
    },
    (rejected) => {
      console.error(rejected);
      throw new Error(rejected);
    }
  );
}
async function getGroupById(id: string): Promise<Group | undefined> {
  return await getGroups().then((response) => {
    if (response && response instanceof Array) {
      return response.find((element) => element.id === id);
    }
  });
}

async function deleteGroup(group: Group) {
  return await API.del(
    "BytefliesCloudPlatformAPI",
    `groups?groupId=${group.id}`,
    {}
  );
}

export { getGroups, getGroupById, deleteGroup };
