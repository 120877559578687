export enum ModeString {
  NORMAL = "normal",
  CALIBRATION = "calibration",
  CONFIGURATION = "configuration",
}

export interface IPathParams {
  dockName: string;
}

export interface IShadowResponse {
  shadow: {
    payload: string;
  };
}

export interface IDockShadowReport {
  state: {
    reported: {
      mode: ModeString;
      configSignals: ISignal[];
    };
  };
}

export type SignalType = "EEG" | "ECG" | "EMG" | "ACC" | "GYR" | "RESP";

export interface ISignal {
  type: SignalType;
  fs: number;
  channel?: number;
}
