import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Component } from "react";

interface IAdminState {
  isAdmin: boolean;
}

interface AdminProps {
  children?: React.ReactNode;
}

export class Admin extends Component<AdminProps, IAdminState> {
  constructor(props: any) {
    super(props);
    this.state = { isAdmin: false };
    this.getUser();
  }

  public render() {
    return this.state.isAdmin && this.props.children;
  }

  private getUser(): void {
    Auth.currentAuthenticatedUser().then(
      (user: CognitoUser) => {
        const a = Admin.isAdmin(user);
        this.setState({ isAdmin: a });
      },
      (reject) => {
        console.error(reject);
        this.setState({ isAdmin: false });
      }
    );
  }

  public static isAdmin(user: CognitoUser | undefined): boolean {
    if (user === undefined) {
      return false;
    }

    const session = user.getSignInUserSession();
    if (session) {
      const payload = session.getIdToken().decodePayload();
      const groups = payload["cognito:groups"];
      if (groups && groups.includes("Administrators")) {
        return true;
      }
    }
    return false;
  }
}
