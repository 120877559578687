import { Button } from "@mui/material";
import React, { Fragment, useState } from "react";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Admin } from "../admin/Admin";
import {
  Delete,
  Autorenew,
  FileCopy,
  DriveFileMove,
} from "@mui/icons-material";
import { DeleteRecordingDialog } from "./DeleteRecordingDialog";
import { API } from "aws-amplify";
import { ListRecordingsResponse } from "../../models/Recording";
import { GetRecordingResponse } from "../../../dist/openapi/recording-service";
import { MoveRecordingDialog } from "./MoveRecordingDialog";

interface IRecordingMenuProps {
  groupId: string;
  recording: GetRecordingResponse;
  onDelete?: (groupId?: string, recordingId?: string) => void;
  onRetrigger?: (groupId?: string, recordingId?: string) => void;
}

export function copyRecording(
  recording: GetRecordingResponse | ListRecordingsResponse
) {
  // Creating a new object makes sure we guarantee the format independent of where we copy it from
  const c: ListRecordingsResponse = {
    id: recording.id,
    startDate: recording.startDate,
    dockName: recording.dockName || "",
    dotId: recording.dotId || "",
    duration: recording.duration,
    patient: recording.patient,
    uploadDate: recording.uploadDate || 0,
    uploadEndDate: recording.uploadEndDate || 0,
    signals: [],
    status: recording.status,
  };
  navigator.clipboard.writeText(JSON.stringify(c, undefined, 2));
}

export function RecordingActionsButton(props: IRecordingMenuProps) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const [deleteMenuOpen, setDeleteMenuOpen] = useState(false);
  const [moveMenuOpen, setMoveMenuOpen] = useState(false);
  const [errorText, setErrorText] = useState<string>();

  React.useEffect(() => {
    Auth.currentAuthenticatedUser().then(
      (user: CognitoUser) => {
        setIsAdmin(Admin.isAdmin(user));
      },
      (reject) => {
        console.error(reject);
        setIsAdmin(false);
      }
    );
  }, [setIsAdmin]);

  const triggerAlgorithms = () => {
    console.log("Algorithms retriggering");

    API.put(
      "BytefliesCloudPlatformAPI",
      `groups/${props.groupId}/recordings/${props.recording.id}`,
      {
        header: { contentType: "application/json" },
        body: { triggerAlgorithms: true },
      }
    ).then(
      (_response) => {
        console.log("Algorithms retriggered");
        if (props.onRetrigger !== undefined) {
          props.onRetrigger(props.groupId, props.recording.id);
        }
      },
      (rejected) => {
        console.error(rejected);
        setErrorText(
          "Something went wrong when triggering the algorithms. Please try again."
        );
      }
    );
  };

  return (
    <Fragment>
      {isAdmin ? (
        <Button
          data-cy="delete-recording-button"
          variant="outlined"
          title="Delete recording"
          size="small"
          onClick={() => {
            setDeleteMenuOpen(true);
          }}
          startIcon={<Delete color="primary" />}
        >
          Delete recording
        </Button>
      ) : undefined}
      &nbsp;
      {isAdmin ? (
        <Button
          data-cy="retrigger-recording-button"
          variant="outlined"
          title="Trigger algorithms"
          size="small"
          onClick={() => {
            triggerAlgorithms();
          }}
          startIcon={<Autorenew color="primary" />}
        >
          Trigger algorithms
        </Button>
      ) : undefined}
      <DeleteRecordingDialog
        open={deleteMenuOpen}
        groupId={props.groupId}
        recordingId={props.recording.id}
        onClose={() => {
          setDeleteMenuOpen(false);
          setActionMenuOpen(false);

          if (props.onDelete !== undefined) {
            props.onDelete(props.groupId, props.recording.id);
          }
        }}
      />
      &nbsp;
      {isAdmin ? (
        <Button
          disabled={props.recording === undefined}
          variant="outlined"
          title="Copy metadata"
          size="small"
          onClick={() => {
            copyRecording(props.recording);
          }}
          startIcon={<FileCopy color="primary" />}
        >
          Copy metadata
        </Button>
      ) : undefined}
      &nbsp;
      {isAdmin ? (
        <Button
          disabled={props.recording === undefined}
          variant="outlined"
          title="Move recording to another group"
          size="small"
          onClick={() => {
            setMoveMenuOpen(true);
          }}
          startIcon={<DriveFileMove color="primary" />}
        >
          Move recording
        </Button>
      ) : undefined}
      <MoveRecordingDialog
        open={moveMenuOpen}
        groupId={props.groupId}
        recordingId={props.recording.id}
        onClose={() => {
          setMoveMenuOpen(false);
        }}
      />
    </Fragment>
  );
}
