import React from "react";

const svg = (props: any) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={props.width || "20"}
    height={props.height || "20"}
  >
    <title>{props.title || "Battery voltage (%)"}</title>
    <circle
      fill={props.fill || "none"}
      strokeWidth={3}
      stroke={props.stroke || "black"}
      strokeMiterlimit={10}
      cx="50"
      cy="50"
      r="47"
    />
    <g transform="translate(22,22) scale(0.150,0.150)">
      <path
        d="M279.522,36.163h-22.086V20c0-11.028-8.972-20-20-20h-97.85c-11.028,0-20,8.972-20,20v16.163H97.5
		c-16.542,0-30,13.458-30,30v280.858c0,16.542,13.458,30,30,30h182.022c16.542,0,30-13.458,30-30V66.163
		C309.522,49.621,296.064,36.163,279.522,36.163z M97.5,347.021V66.163h182.022l0.003,280.858H97.5z"
      />
      <rect x="122.586" y="266.5" width="131.85" height="59.663" />
      <rect x="122.586" y="176.63" width="131.85" height="59.663" />
    </g>
  </svg>
);

export default function BatteryIcon(props: {
  width?: number;
  height?: number;
  stroke?: string;
  fill?: string;
  title?: string;
}): JSX.Element {
  return svg(props);
}
