import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { IRecordingRouteState } from "../../models/RecordingRouteState";
import { AssignmentInd } from "@mui/icons-material";
import { getGroups } from "../../api/Groups";
import { Group } from "../../models/Group";
import { Alert } from "@mui/material";
import DockIcon from "../../images/Icons/DockIcon";
import { Admin } from "../admin/Admin";
import AssignmentIcon from "@mui/icons-material/Assignment";

export function GroupList() {
  const [loaded, setLoaded] = useState(false);
  const [groups, setGroups] = useState<Group[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [errorText, setErrorText] = useState<string>();
  const history = useHistory();

  const handleClick = (event: any, group: Group) => {
    event.stopPropagation();
    if (group) {
      history.push({ pathname: `/groups/${group.id}/recordings` }, {
        groupName: group.name,
      } as IRecordingRouteState);
    }
  };

  const handleDocksClick = (event: any, group: Group) => {
    event.stopPropagation();
    if (group) {
      history.push({ pathname: `/groups/${group.id}/docks` }, {
        groupName: group.name,
      } as IRecordingRouteState);
    }
  };

  const handlePatientMgmtClick = (event: any, group: Group) => {
    event.stopPropagation();
    if (group) {
      history.push({ pathname: `/groups/${group.id}/patientrecords` }, {
        groupName: group.name,
      } as IRecordingRouteState);
    }
  };

  React.useEffect(() => {
    Auth.currentAuthenticatedUser().then(
      (user: CognitoUser) => {
        setIsAdmin(Admin.isAdmin(user));
      },
      (reject) => {
        console.error(reject);
      }
    );
  }, [setIsAdmin]);

  React.useEffect(() => {
    getGroups().then(
      (groups) => {
        if (groups) {
          setGroups(groups);
          setLoaded(true);
        }
      },
      (rejected) => {
        setErrorText("Something went wrong loading. Please try again.");
        setLoaded(true);
      }
    );
  }, [getGroups, setGroups, setLoaded, setErrorText]);

  if (groups) {
    return (
      <Paper>
        <Table size="small" data-cy="groups">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="center">{isAdmin ? "Actions" : ""}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.length > 0 ? (
              groups.map((group) => {
                return (
                  <TableRow
                    key={group.id}
                    data-cy={`group-${group.id}`}
                    hover={true}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleClick(e, group)}
                  >
                    <TableCell title={`GroupID: ${group.id}`}>
                      {group.name}
                    </TableCell>
                    <TableCell>{group.description}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Docking stations">
                        <IconButton
                          size="small"
                          aria-label="docks"
                          onClick={(e) => handleDocksClick(e, group)}
                        >
                          <DockIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Patient Management">
                        <IconButton
                          size="small"
                          aria-label="patientmgmt"
                          onClick={(e) => handlePatientMgmtClick(e, group)}
                        >
                          <AssignmentInd color="primary" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Datapoints">
                        <IconButton
                          size="small"
                          aria-label="datapoints"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (group) {
                              history.push(
                                {
                                  pathname: `/groups/${group.id}/datapoints`,
                                },
                                {
                                  groupName: group.name,
                                } as IRecordingRouteState
                              );
                            }
                          }}
                        >
                          <AssignmentIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : !loaded ? (
              <TableRow key={"empty"}>
                <TableCell colSpan={3} align="center">
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key={"empty"}>
                <TableCell colSpan={3}>
                  <Typography align="center">No Groups were found.</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Snackbar
          open={errorText !== undefined}
          autoHideDuration={10000}
          onClose={() => {
            setErrorText(undefined);
          }}
        >
          <Alert
            onClose={() => {
              setErrorText(undefined);
            }}
            severity="error"
          >
            {errorText}
          </Alert>
        </Snackbar>
      </Paper>
    );
  } else {
    return <div />;
  }
}
