import { createTheme } from "@mui/material/styles";
import { Theme } from "@aws-amplify/ui-react";

// https://byteflies.atlassian.net/wiki/spaces/BH/pages/32800894/House+Style
export const BYTEFLIES_BLUE_DARK = "#103a4d";
export const BYTEFLIES_BLUE = "#207499";
export const BYTEFLIES_BLUE_LIGHT = "#79acc2";

export const PENTAGRAM_WHITE = "#ffffff";
export const PENTAGRAM_LIGHT_GREY = "#f2f2f2";

export const bytefliesTheme = createTheme({
  palette: {
    primary: {
      main: BYTEFLIES_BLUE_DARK,
    },
    secondary: {
      main: BYTEFLIES_BLUE,
    },
    background: {
      paper: PENTAGRAM_WHITE,
      default: PENTAGRAM_LIGHT_GREY,
    },
  },
  typography: {
    fontFamily: "Open Sans",
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: PENTAGRAM_WHITE,
          backgroundColor: BYTEFLIES_BLUE_DARK,
        },
      },
      defaultProps: {
        color: "inherit",
      },
    },
  },
});

export const amplifyTheme: Theme = {
  name: "byteflies",
  tokens: {
    fonts: {
      default: { static: { value: bytefliesTheme.typography.fontFamily! } },
    },
    colors: {
      background: {
        primary: { value: PENTAGRAM_WHITE },
      },
    },
    components: {
      button: {
        primary: {
          color: { value: BYTEFLIES_BLUE_DARK },
          backgroundColor: { value: bytefliesTheme.palette.secondary.main },
        },
        color: { value: PENTAGRAM_WHITE },
      },
      authenticator: {},
    },
  },
};
