import { CircularProgress, Tooltip } from "@mui/material";
import React, { Component } from "react";
import {
  GetRecordingResponse,
  RecordingProcessingStatus,
} from "../../../dist/openapi/recording-service";

import { ErrorOutline } from "@mui/icons-material";
import { API } from "aws-amplify";

interface IRecordingStatusProps {
  groupId: string;
  recordingId: string;
  status?: RecordingProcessingStatus;
  onRefresh?: (recording: GetRecordingResponse) => void;
}

interface IRecordingStatusState {
  interval: NodeJS.Timeout | undefined;
}

export class RecordingStatusIcon extends Component<
  IRecordingStatusProps,
  IRecordingStatusState
> {
  constructor(props: IRecordingStatusProps) {
    super(props);

    this.state = { interval: undefined };
  }

  public componentWillUnmount(): void {
    if (this.state.interval !== undefined) {
      clearInterval(this.state.interval);
    }
  }

  public componentDidUpdate(prevProps: IRecordingStatusProps): void {
    if (
      this.props.groupId !== prevProps.groupId ||
      this.props.recordingId !== prevProps.recordingId ||
      this.props.status !== prevProps.status
    ) {
      this.refreshStatus();
    }
  }

  public componentDidMount(): void {
    const interval = setInterval(() => this.refreshStatus(), 5000);
    this.setState({ interval: interval });
    this.refreshStatus();
  }

  private refreshStatus() {
    if (this.props.status !== "processing") {
      return;
    }
    console.trace(
      `Refresh status ${this.props.groupId} ${this.props.recordingId}`
    );

    API.get(
      "BytefliesCloudPlatformAPI",
      `groups/${this.props.groupId}/recordings/${this.props.recordingId}`,
      {}
    ).then(
      (response: GetRecordingResponse) => {
        if (response) {
          if (this.props.onRefresh !== undefined) {
            this.props.onRefresh(response);
          }
        }
      },
      (rejected) => {
        console.error(rejected);
      }
    );
  }

  public render() {
    const status = () => {
      switch (this.props.status) {
        case "processing":
          return (
            <Tooltip title={this.props.status}>
              <CircularProgress size="1rem" />
            </Tooltip>
          );
        case "failed":
          return (
            <Tooltip title={this.props.status}>
              <ErrorOutline fontSize="small" />
            </Tooltip>
          );
        default:
          return null;
      }
    };
    return status();
  }
}
