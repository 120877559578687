import React from "react";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Logo } from "./ce.svg";

export const CeIcon = React.forwardRef(function CeIcon(props, ref) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 280 200"
      titleAccess="CE mark"
      ref={ref as React.RefObject<SVGSVGElement>}
    >
      <Logo>CE mark</Logo>
    </SvgIcon>
  );
});
