import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import HeightIcon from "@mui/icons-material/Height";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

import { Alert, ToggleButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import "./ACC.css";
import { useData } from "./useData";
import Plotly, { Shape } from "plotly.js-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

interface Props {
  selection: { start: number; end: number };
  onSelect(point: number): void;
  websocket?: string;
}

const ACC: React.FC<Props> = (props: Props) => {
  const [shapes, setShapes] = useState<Partial<Shape>[]>([]);
  const [dragmode, setDragmode] = useState<"pan" | "zoom">("pan");

  const range = 5 * 60 * 1000; // 5 minutes (2.5 before, 2.5 after)

  const [accSelection, setAccSelection] = useState<{
    start: number;
    end: number;
  }>({
    start: props.selection.start - range / 2,
    end: props.selection.end + range / 2,
  });

  useEffect(() => {
    setAccSelection({
      start: props.selection.start - range / 2,
      end: props.selection.end + range / 2,
    });
  }, [props.selection, range]);

  const data = useData("acc-live");

  useEffect(() => {
    if (props.selection) {
      const newShapes: Partial<Shape>[] = [];
      newShapes.push({
        type: "rect",
        xref: "x",
        yref: "paper",
        x0: props.selection.start,
        x1: props.selection.end,
        y0: 0,
        y1: 1,
        fillcolor: "#CCCCCC",
        opacity: 0.5,
        layer: "below",
        line: {
          width: 0,
        },
      });
      setShapes(newShapes);
    }
  }, [props.selection]);

  return (
    <div
      style={{
        textAlign: "center",
        minHeight: 120,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {data.loading && (
        <CircularProgress
          disableShrink
          size={20}
          style={{
            position: "absolute",
            pointerEvents: "none",
            margin: 5,
            right: 0,
            bottom: 0,
            zIndex: 100,
            marginLeft: 30,
            marginBottom: 20,
          }}
        />
      )}
      {data.error ? (
        <Alert
          severity="error"
          style={{
            position: "absolute",
            zIndex: 100,
            marginLeft: 30,
            marginBottom: 20,
          }}
        >
          {data.error}
        </Alert>
      ) : !props.websocket ? (
        <Alert
          severity="info"
          style={{
            position: "absolute",
            zIndex: 100,
            marginLeft: 30,
            marginBottom: 20,
          }}
        >
          {"No ACC available"}
        </Alert>
      ) : (
        ""
      )}
      {
        <>
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              right: 0,
              top: 0,
            }}
          >
            <ToggleButton
              value={"pan"}
              disabled={dragmode === "pan"}
              style={{
                padding: 0,
                margin: 2,
                backgroundColor: "white",
                color: dragmode === "pan" ? "" : "grey",
              }}
              onClick={() => {
                setDragmode("pan");
              }}
            >
              <HeightIcon
                style={{
                  transform: "rotate(90deg)",
                }}
              />
            </ToggleButton>
            <ToggleButton
              value={"zoom"}
              disabled={dragmode === "zoom"}
              style={{
                padding: 0,
                margin: 2,
                backgroundColor: "white",
                color: dragmode === "zoom" ? "" : "grey",
              }}
              onClick={() => {
                setDragmode("zoom");
              }}
            >
              <ZoomInIcon />
            </ToggleButton>
            <ToggleButton
              value={"reset"}
              style={{
                padding: 0,
                margin: 2,
                backgroundColor: "white",
                color: "grey",
              }}
              onClick={() => {
                setAccSelection({
                  start: props.selection.start - range / 2,
                  end: props.selection.end + range / 2,
                });
              }}
            >
              <FullscreenIcon />
            </ToggleButton>
          </div>
          <Plot
            className="acc"
            onRelayout={(e) => {
              if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
                const start = dayjs(e["xaxis.range[0]"]).valueOf();
                const end = dayjs(e["xaxis.range[1]"]).valueOf();
                setAccSelection({ start: start, end: end });
              }
            }}
            useResizeHandler
            style={{ width: "100%" }}
            onClick={(e) => {
              if (e.points[0] && e.points[0].x) {
                const start = dayjs(e.points[0].x).valueOf();
                props.onSelect(start);
              }
            }}
            data={[
              {
                x: data.data?.xAxis,
                y: data.data?.chX,
                type: "scattergl",
                mode: "lines",
                name: "X",
                hoverinfo: "none",
                line: {
                  color: "#78cb6b",
                  width: 1,
                },
              },
              {
                x: data.data?.xAxis,
                y: data.data?.chY,
                type: "scattergl",
                mode: "lines",
                name: "Y",
                hoverinfo: "none",
                line: {
                  color: "#56BAEC",
                  width: 1,
                },
              },
              {
                x: data.data?.xAxis,
                y: data.data?.chZ,
                type: "scattergl",
                mode: "lines",
                name: "Z",
                hoverinfo: "none",
                line: {
                  color: "#FFAEAE",
                  width: 1,
                },
              },
            ]}
            layout={{
              height: 120,
              margin: {
                l: 20,
                r: 0,
                b: 15,
                t: 5,
              },
              plot_bgcolor: "white",
              paper_bgcolor: "rgba(0,0,0,0)",
              dragmode: dragmode,
              xaxis: {
                type: "date",
                tickformat: "%H:%M:%S",
                range: [accSelection.start, accSelection.end],
              },
              yaxis: {
                showline: false,
                zeroline: false,
                showgrid: false,
                fixedrange: dragmode === "pan",
                range: [-2, 2],
              },
              showlegend: true,
              legend: {
                x: 1,
                y: 0.4,
              },
              shapes,
            }}
            config={{
              plotGlPixelRatio: 2,
              displayModeBar: false,
              displaylogo: false,
              modeBarButtonsToRemove: [
                "select2d",
                "zoomIn2d",
                "zoomOut2d",
                "lasso2d",
                "autoScale2d",
                "hoverClosestCartesian",
                "hoverCompareCartesian",
                "zoom3d",
                "pan3d",
                "resetCameraDefault3d",
                "resetCameraLastSave3d",
                "hoverClosest3d",
                "orbitRotation",
                "tableRotation",
                "zoomInGeo",
                "zoomOutGeo",
                "resetGeo",
                "hoverClosestGeo",
                "toImage",
                "sendDataToCloud",
                "hoverClosestGl2d",
                "hoverClosestPie",
                "toggleHover",
                "resetViews",
                "toggleSpikelines",
              ],
            }}
          />
        </>
      }
    </div>
  );
};

export default ACC;
