import { API } from "aws-amplify";
import {
  GetRecordingResponse,
  ListRecordingsResponse,
  PutRecordingRequest,
} from "../models/Recording";
import axios from "axios";
import { Moment } from "moment";

async function putRecording(
  groupId: string,
  recordingId: string,
  update: PutRecordingRequest
): Promise<any> {
  return API.put(
    "BytefliesCloudPlatformAPI",
    `groups/${groupId}/recordings/${recordingId}`,
    { body: update }
  );
}

interface UploadRequestResponse {
  uploadUrl: string;
}

export async function getAlgorithmUploadUrl(
  groupId: string,
  recordingId: string,
  algorithmId: string
) {
  if (algorithmId === undefined || algorithmId === "") {
    throw new Error("algorithmId is blank");
  }

  const response = await API.get(
    "BytefliesCloudPlatformAPI",
    `groups/${groupId}/recordings/${recordingId}/algorithms/${algorithmId}/uploadrequest`,
    {}
  );
  console.log(response);
  return response as UploadRequestResponse;
}

export async function uploadFile(file: File, uploadUrl: string) {
  if (uploadUrl === undefined || uploadUrl === "") {
    throw new Error("uploadUrl is blank");
  } else if (file === undefined || file === null) {
    throw new Error("file is undefined");
  }

  const resp = await axios.put(uploadUrl, file, {
    headers: {
      // Use application/octet-stream when uploading to S3
      "Content-Type": "application/octet-stream",
    },
  });

  console.log(
    "Upload response",
    resp.status,
    resp.statusText,
    JSON.stringify(resp.data)
  );
}

export async function listRecordings(
  groupId: string,
  startDate: Moment,
  endDate: Moment,
  dockId?: string,
  dotId?: string
) {
  const searchParams = new URLSearchParams();
  searchParams.set("limit", "1000");
  searchParams.set("begin", `${startDate.unix()}`);
  searchParams.set("end", `${endDate.unix()}`);
  if (dockId) {
    searchParams.set("dockName", dockId);
  }
  if (dotId) {
    searchParams.set("dotId", dotId);
  }

  const response = await API.get(
    "BytefliesCloudPlatformAPI",
    `groups/${groupId}/recordings?${searchParams.toString()}`,
    {}
  );
  if (response && response instanceof Array) {
    return response as ListRecordingsResponse[];
  } else {
    throw new Error("Invalid return value");
  }
}

export async function getRecording(groupId: string, recordingId: string) {
  const response = await API.get(
    "BytefliesCloudPlatformAPI",
    `groups/${groupId}/recordings/${recordingId}`,
    {}
  );
  if (response) {
    return response as GetRecordingResponse;
  } else {
    throw new Error("Invalid return value");
  }
}
export { putRecording };
