import React from "react";
import { Theme } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import { Button, Tooltip } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";

interface ClipboardItemProps {
  title: string;
  label: string;
  disableAllCaps?: boolean;
  "data-cy"?: string;
}

function copyToClipboard(event: any, content: string | undefined) {
  event.stopPropagation();
  if (content) {
    navigator.clipboard.writeText(content);
  }
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export function ClipboardItem(props: ClipboardItemProps): JSX.Element {
  return (
    <React.Fragment>
      <LightTooltip
        title={
          <React.Fragment>
            {props.title} <FileCopyIcon style={{ fontSize: 10 }} />
          </React.Fragment>
        }
      >
        <Button
          data-cy={props["data-cy"]}
          style={{
            display: "inline-block",
            minHeight: 0,
            minWidth: 0,
            padding: 0,
            textTransform: props.disableAllCaps ? "none" : "uppercase",
          }}
          onClick={(e) => copyToClipboard(e, props.title)}
        >
          {props.label}
        </Button>
      </LightTooltip>
    </React.Fragment>
  );
}
