import React from "react";

const svg = (props: any) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={props.width || "20"}
    height={props.height || "20"}
  >
    <title>{props.title || "EEG"}</title>
    <circle
      fill={props.fill || "none"}
      strokeWidth={3}
      stroke={props.stroke || "black"}
      strokeMiterlimit={10}
      cx="50"
      cy="50"
      r="47"
    />
    <path d="M59.06,67.38h0a1,1,0,0,0,.07.15Z" />
    <path d="M64.47,42.37c.09,0,7.34.08,11.1,5.71,1-1.79,2.34-4.95,1.13-8.1-1.87-4.86-7.61-6.47-7.67-6.48l-.64-.18-.2-.63C68.13,32.5,66.63,28,60.86,28c-5.93,0-8.18,3.87-8.28,4a4.84,4.84,0,0,0-.33,3.7,2.91,2.91,0,0,0,2.68,1.72,1.21,1.21,0,0,1,0,2.41,5.69,5.69,0,0,1-4.35-2.16,6,6,0,0,1-4.8,2.46,5,5,0,0,1-1.09-.12,1.22,1.22,0,0,1-.89-1.45,1.2,1.2,0,0,1,1.44-.89c.32.06,3,.51,4.39-3.23a7.36,7.36,0,0,1,.87-3.62,10.25,10.25,0,0,1,2.93-3.06,8.82,8.82,0,0,0-4.75-1.67c-2.51-.08-5.06,1.08-7.55,3.44l-.37.36-.52,0c-.29,0-7.19-.27-9.4,6l-.19.52-.52.2S26.78,38,25.67,40.91a6.87,6.87,0,0,0,.22,4.92c4.36-3.08,10.37-.51,10.65-.39a1.2,1.2,0,1,1-1,2.2c-.1,0-6.06-2.55-9.23,1-3,3.35-1,6.2-.79,6.52l.09.13c.19.33,4.48,7.6,12.16,5.13a15.14,15.14,0,0,1-2.48-4.61,1.2,1.2,0,1,1,2.3-.69s1.4,4.51,5.26,6.54c2.37,1.24,5.22,1.34,8.49.29l.44-.19a1.12,1.12,0,0,1,.28-.18.55.55,0,0,1,.18-.06A12.32,12.32,0,0,0,57,56.22c.25-.59,1.94-4.1,7-4.1a1.21,1.21,0,0,1,0,2.41c-3.72,0-4.77,2.51-4.81,2.62l0,.1a15.18,15.18,0,0,1-5,5.85c1.86,2.56,5.13,1.81,5.28,1.78l1-.24.4.94c1.08,2.6,3.26,7.78,3.78,8.83a.32.32,0,0,0,.2.18,8.06,8.06,0,0,0,5.24-2.76l.27-.27a2,2,0,0,0-.23-1.17l-3.75-7.51,1.76-.15s3.83-.38,5.85-3a7.61,7.61,0,0,0,1.12-6.17c-1.49-8.61-10.31-8.75-10.69-8.76a1.2,1.2,0,1,1,0-2.4Zm-10.2,5a1.2,1.2,0,0,1-.85.36,3.52,3.52,0,0,0-2.85,1A4.75,4.75,0,0,0,50,52.21,1.23,1.23,0,0,1,49,53.65a1,1,0,0,1-.25,0,1.21,1.21,0,0,1-1.17-1c0-.13-.67-3.27,1.09-5.45a5.76,5.76,0,0,1,4.75-1.94,1.2,1.2,0,0,1,.83,2.07Z" />
  </svg>
);

export default function BrainIcon(props: {
  width?: number;
  height?: number;
  stroke?: string;
  fill?: string;
  title?: string;
}): JSX.Element {
  return svg(props);
}
