import React from "react";

const svg = (props: any) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={props.width || "20"}
    height={props.height || "20"}
  >
    <title>{props.title || "EOG"}</title>
    <g>
      <path
        fill={props.fill || "none"}
        strokeWidth={3}
        stroke={props.stroke || "black"}
        strokeMiterlimit={10}
        d="M50,3C24.04,3,3,24.04,3,50s21.04,47,47,47s47-21.04,47-47S75.96,3,50,3z"
      />
    </g>
    <path
      d="M50,38.28c-20.66,0-31.59,14.87-31.59,14.87S29.34,68.02,50,68.02s31.59-14.87,31.59-14.87S70.66,38.28,50,38.28z M50,65.09
    c-6.59,0-11.94-5.34-11.94-11.94c0-6.59,5.34-11.94,11.94-11.94s11.94,5.34,11.94,11.94C61.94,59.74,56.59,65.09,50,65.09z"
    />
    <line
      fill="#FFFFFF"
      stroke="#000000"
      strokeWidth="2.8346"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      x1="50"
      y1="25.76"
      x2="50"
      y2="34.19"
    />
    <line
      fill="#FFFFFF"
      stroke="#000000"
      strokeWidth="2.8346"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      x1="26.65"
      y1="30.63"
      x2="31.14"
      y2="37.75"
    />
    <line
      fill="#FFFFFF"
      stroke="#000000"
      strokeWidth="2.8346"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      x1="12.24"
      y1="41.85"
      x2="18.41"
      y2="47.58"
    />
    <line
      fill="#FFFFFF"
      stroke="#000000"
      strokeWidth="2.8346"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      x1="87.76"
      y1="41.85"
      x2="81.59"
      y2="47.58"
    />
    <line
      fill="#FFFFFF"
      stroke="#000000"
      strokeWidth="2.8346"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      x1="72.35"
      y1="30.63"
      x2="67.85"
      y2="37.75"
    />
  </svg>
);

export default function EyeIcon(props: {
  width?: number;
  height?: number;
  stroke?: string;
  title?: string;
  fill?: string;
}): JSX.Element {
  return svg(props);
}
