import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import { addDeviceToDock } from "../../api";

interface IDialogProps {
  groupId: string;
  dockId: string;
  open: boolean;
  onCancel: () => void;
  onDeviceAttached: (groupId: string, dockId: string, deviceId: string) => void;
}
export function BleDeviceDialog(props: IDialogProps) {
  const [inProgress, setInProgress] = useState(false);
  const { open, groupId, dockId, onCancel, onDeviceAttached } = props;
  const [deviceId, setDeviceId] = useState("");
  const [apiError, setApiError] = useState<Error>();

  const isValidDeviceId = (deviceId: string) => {
    if (
      deviceId === null ||
      deviceId === undefined ||
      typeof deviceId !== "string"
    ) {
      return "Device ID is mandatory";
    }
    if (deviceId.indexOf(" ") >= 0 || deviceId.indexOf(":") >= 0) {
      return "Device ID should not contain spaces or colons";
    }
    if (deviceId.toLowerCase() !== deviceId) {
      return "Device ID should be lower case";
    }
    return null;
  };
  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Attach a SpotCheck device to a Docking Station
      </DialogTitle>
      <DialogContent>
        {apiError && <Alert severity="error">{apiError.message}</Alert>}

        <TextField
          disabled={false}
          required
          inputProps={{
            maxLength: 30,
          }}
          error={isValidDeviceId(deviceId) !== null}
          helperText={
            isValidDeviceId(deviceId) === null
              ? null
              : isValidDeviceId(deviceId)
          }
          margin="dense"
          name="deviceId"
          label="Device ID"
          fullWidth
          value={deviceId}
          onChange={(e) => setDeviceId(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={inProgress}
          onClick={() => {
            onCancel();
          }}
          color="secondary"
        >
          Cancel
        </Button>

        <Button
          disabled={inProgress || deviceId === null || deviceId === ""}
          onClick={async () => {
            try {
              setInProgress(true);
              console.log(deviceId);
              await addDeviceToDock(groupId, dockId, deviceId);
              setApiError(undefined);
              onDeviceAttached(groupId, dockId, deviceId);
            } catch (error) {
              console.log(error);
              const e = error as Error;
              setApiError(e);
            } finally {
              setInProgress(false);
            }
          }}
          color="primary"
        >
          {inProgress ? <CircularProgress /> : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
