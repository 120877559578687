export type Quality = "PASS" | "FAIL" | "CHECK";

export interface ListRecordingsResponse {
  id: string;
  dockName: string;
  dotId: string;
  patient: string;
  startDate: number;
  uploadDate: number;
  uploadEndDate: number;
  duration: number;
  signals: ListRecordingsResponseSignal[];
  status: RecordingStatus;
}

export interface ListRecordingsResponseSignal {
  id: string;
  type: string;
  quality?: Quality;
  channel?: number;
  location?: Location;
}

export type RecordingStatus = "processing" | "done" | "failed" | undefined;

export interface GetRecordingResponse {
  id: string;
  dockName: string;
  dotId: string;
  patient: string;
  groupId: string;
  startDate: number;
  uploadDate: number;
  uploadEndDate: number;
  duration: number;
  firmwareVersion: string;
  signals: SignalResponse[];
  status: RecordingStatus;
}

export interface PutRecordingRequest {
  patient?: string;
  groupId?: string;
  signals?: SignalRequest[];
}

export interface SignalResponse {
  id: string;
  type: string;
  quality?: Quality;
  channel?: number;
  rawData?: string;
  samplingRate: number;
  conversionFactor: number;
  algorithms?: AlgorithmResponse[];
  location?: Location;
}

export interface SignalRequest {
  id: string;
  location: Location | undefined;
}

export interface AlgorithmResponse {
  id: string;
  type: string;
  col: number;
  added: number;
}

export type Location = LocationLabeled | LocationLead | LocationBipolar;

export interface LocationLabeled {
  label: string;
}

export interface LocationBipolar {
  positive: string;
  negative: string;
}

export interface LocationLead {
  lead: string;
}

export function sortSignal(
  s1: ListRecordingsResponseSignal,
  s2: ListRecordingsResponseSignal
): number {
  if (
    s1.channel !== undefined &&
    s2.channel !== undefined &&
    s1.type.startsWith("E") &&
    s2.type.startsWith("E")
  ) {
    return s1.channel > s2.channel ? 1 : -1;
  }
  return s1.type > s2.type ? 1 : -1;
}

export function getDisplayName(signal: string) {
  if (signal === "BAT") {
    return "Battery Level";
  } else if (signal === "GYR") {
    return "Gyroscope";
  } else if (signal === "ACC") {
    return "Accelerometer";
  } else if (signal === "LEAD_OFF") {
    return "Lead-off";
  } else if (signal === "EVENT") {
    return "Event Log";
  } else if (signal === "RESP") {
    return "Respiration";
  } else if (signal === "SPO2") {
    return "Oxygen Saturation";
  } else if (signal === "SYNC") {
    return "Time Synchronization";
  }
  return signal;
}
