import React from "react";
import { decode } from "cbor-x";
import { EventMessage } from "../Events";
import { Buffer } from "buffer";

function decodePacket(eventMessage: EventMessage) {
  // filter non-livestream messages
  if (eventMessage["detail-type"] !== "livestream") {
    return;
  }

  // read in base64 data in buffer
  const data = Buffer.from(eventMessage.detail, "base64");

  // skip too small empty
  if (data.byteLength < 3) {
    return;
  }

  // cbor decode
  const packet = decode(data);

  return packet;
}

export function useData(type: "acc-live" | "eeg-live", websocket?: WebSocket) {
  // CONCEPTS:
  // data: sample data that is returned, this is equal to the samples between start and end
  // cache: sample data outside of what is requested which is being held on or prefetched
  // chunksize: size of the debounce on start & end before a data or cache update is triggered
  // buffersize: how big the cache is on either side

  const [dataEnd, setDataEnd] = React.useState<number | undefined>();

  const [data, setData] = React.useState<any>({
    ch1: [] as number[],
    ch2: [] as number[],
    ch1Unit: String,
    ch2Unit: String,
    xAxis1: [] as number[],
    xAxis2: [] as number[],
    accX: [] as number[],
    accY: [] as number[],
    accZ: [] as number[],
    gyrX: [] as number[],
    gyrY: [] as number[],
    gyrZ: [] as number[],
    accUnit: String,
    gyrUnit: String,
    xAxisAcc: [] as number[],
    xAxisGyr: [] as number[],
  });
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    const handleMessage = async (message: MessageEvent) => {
      let eventMessage: EventMessage;

      // skip empty messages
      if (!message.data || message.data === "") {
        return;
      }

      // parse message
      try {
        eventMessage = JSON.parse(message.data);
      } catch {
        console.log("Invalid JSON");
        return;
      }

      // decode packet
      const packets = decodePacket(eventMessage);
      if (!packets) {
        return;
      }

      setData((prevState: any) => {
        // initiate
        let data = prevState;
        if (prevState === undefined) {
          data = { ch1: [], ch2: [], xAxis1: [], xAxis2: [] };
        }

        for (const packet of packets) {
          // ch1
          if (packet.ch.startsWith("0_")) {
            data.ch1Unit = packet.un;
            if (data.ch1 === undefined) {
              data.ch1 = [];
            }
            data.ch1 = data.ch1.concat(packet.pl);
          }
          // ch2
          if (packet.ch.startsWith("1_")) {
            data.ch2Unit = packet.un;
            if (data.ch2 === undefined) {
              data.ch2 = [];
            }
            data.ch2 = data.ch2.concat(packet.pl);

            // xAxis
            if (data.xAxis1 === undefined) {
              data.xAxis1 = [] as number[];
            }
            const samplingRate = packet.sr;
            const startDate = new Date(packet.ts).getTime();
            for (let i = 0; i < packet.pl.length; i++) {
              const x = startDate + i * (1000 / samplingRate);
              if (!data.xAxis1.includes(x)) data.xAxis1.push(x);
            }
          }
          // accX
          if (packet.ch.startsWith("2_0")) {
            data.accUnit = packet.un;
            if (data.accX === undefined) {
              data.accX = [];
            }
            data.accX = data.accX.concat(packet.pl);
            // xAxisAcc
            if (data.xAxisAcc === undefined) {
              data.xAxisAcc = [] as number[];
            }
            const samplingRate = packet.sr;
            const startDate = new Date(packet.ts).getTime();
            for (let i = 0; i < packet.pl.length; i++) {
              const x = startDate + i * (1000 / samplingRate);
              if (!data.xAxisAcc.includes(x)) data.xAxisAcc.push(x);
            }
          }
          // accY
          if (packet.ch.startsWith("2_1")) {
            data.accUnit = packet.un;
            if (data.accY === undefined) {
              data.accY = [];
            }
            data.accY = data.accY.concat(packet.pl);
          }
          // accZ
          if (packet.ch.startsWith("2_2")) {
            data.accUnit = packet.un;
            if (data.accZ === undefined) {
              data.accZ = [];
            }
            data.accZ = data.accZ.concat(packet.pl);
          }
          // gyrX
          if (packet.ch.startsWith("3_0")) {
            data.gyrUnit = packet.un;
            if (data.gyrX === undefined) {
              data.gyrX = [];
            }
            data.gyrX = data.gyrX.concat(packet.pl);
          }
          // gyrY
          if (packet.ch.startsWith("3_1")) {
            data.gyrUnit = packet.un;
            if (data.gyrY === undefined) {
              data.gyrY = [];
            }
            data.gyrY = data.gyrY.concat(packet.pl);
          }
          // gyrZ
          if (packet.ch.startsWith("3_2")) {
            data.gyrUnit = packet.un;
            if (data.gyrZ === undefined) {
              data.gyrZ = [];
            }
            data.gyrZ = data.gyrZ.concat(packet.pl);
          }
        }

        let maxLength = 100 * 125; // drop data to prevent overflow
        data.xAxis1 = data.xAxis1.slice(-maxLength);
        data.ch1 = data.ch1.slice(-maxLength);
        data.ch2 = data.ch2.slice(-maxLength);

        maxLength = 100 * 50; // drop data to prevent overflow
        data.xAxisAcc = data.xAxisAcc.slice(-maxLength);
        data.xAxisGyr = data.xAxisGyr.slice(-maxLength);
        data.accX = data.accX.slice(-maxLength);
        data.accY = data.accY.slice(-maxLength);
        data.accZ = data.accZ.slice(-maxLength);
        data.gyrX = data.gyrX.slice(-maxLength);
        data.gyrY = data.gyrY.slice(-maxLength);
        data.gyrZ = data.gyrZ.slice(-maxLength);

        // TODO find a more beautifull way to determine xAxis
        // TODO coop with drop-outs?

        return data;
      });

      for (const packet of packets) {
        if (!dataEnd || new Date(packet.ts).getTime() > dataEnd) {
          setDataEnd(new Date(packet.ts).getTime());
        }
      }
    };

    if (type === "eeg-live") {
      if (websocket) {
        websocket.addEventListener("message", handleMessage);
      }
    }

    return () => {
      if (websocket) {
        websocket.removeEventListener("message", handleMessage);
      }
    };
  }, [websocket, type]);

  return {
    error,
    data,
    end: dataEnd,
    dataEnd,
    loading: false, // TODO
  };
}
