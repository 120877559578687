import React, { ChangeEvent, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Divider,
  CircularProgress,
  TableHead,
  DialogActions,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Group } from "../../models/Group";
import {
  getAutoUpdateFromGroup,
  putAutoUpdateToGroup,
  DockAutoUpdate,
} from "../../api";

export interface IDockDialogProps {
  group?: Group;
  open: boolean;
  onClose: () => void;
}
export function ManageAutoUpdateDialog(props: IDockDialogProps) {
  const { group, open, onClose } = props;
  const [inProgress, setInProgress] = useState(false);
  const [items, setItems] = useState<DockAutoUpdate[]>([]);

  React.useEffect(() => {
    if (group?.id) {
      setItems([]);
      setInProgress(true);
      getAutoUpdateFromGroup(group?.id)
        .then((docksFromGroup) => {
          setItems(docksFromGroup);
          setInProgress(false);
        })
        .catch(() => {
          setInProgress(false);
        });
    }
  }, [group, setItems, setInProgress]);

  const onSelectAll = async (checked: boolean) => {
    const i = items.map((item) => {
      item.autoUpdate = checked;
      return item;
    });
    setItems(i);
  };

  const saveAutoUpdate = async () => {
    if (group?.id) {
      setInProgress(true);
      await putAutoUpdateToGroup(group?.id, items);
      setInProgress(false);
      onClose();
    }
  };

  const calcIndeterminate = () => {
    const selLength = items.filter((elem) => elem.autoUpdate).length;
    return selLength !== items.length && selLength !== 0;
  };

  const calcAggregateSelection = (): boolean => {
    const selLength = items.filter((elem) => elem.autoUpdate).length;
    return selLength === items.length && items.length !== 0;
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">
        Manage automatic updates for {group?.name}
      </DialogTitle>
      <DialogContent className="dockDialog">
        <Paper>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "70%" }}>DockID</TableCell>
                <TableCell
                  style={{ width: "20%" }}
                  padding="none"
                  align="right"
                  size="small"
                >
                  {calcAggregateSelection() ? "Unselect all" : "Select all"}
                </TableCell>
                <TableCell padding="none" align="center" size="small">
                  <Checkbox
                    inputProps={{ "aria-label": "primary checkbox" }}
                    checked={calcAggregateSelection()}
                    indeterminate={calcIndeterminate()}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      onSelectAll(event.target.checked)
                    }
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {inProgress ? <CircularProgress /> : null}
                </TableCell>
              </TableRow>
              {items.length > 0 ? (
                items.map((item) => {
                  return (
                    <TableRow key={item.dockId} hover={true}>
                      <TableCell style={{ width: "90%" }} colSpan={2}>
                        {item.dockId}
                      </TableCell>
                      <TableCell padding="none" align="center" size="small">
                        <IconButton
                          size="small"
                          disabled={inProgress}
                          onClick={() => {}}
                        >
                          <Checkbox
                            inputProps={{ "aria-label": "primary checkbox" }}
                            checked={item.autoUpdate}
                            onChange={() => {
                              item.autoUpdate = !item.autoUpdate;
                              setItems([...items]);
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover={true}>
                  <TableCell align="left" colSpan={2}>
                    No Docking Station found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={inProgress}
          onClick={() => saveAutoUpdate()}
          color="primary"
          variant="outlined"
        >
          {inProgress ? <CircularProgress /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
