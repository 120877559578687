import React, { Component } from "react";

import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { API } from "aws-amplify";
import moment, { Moment } from "moment";
import { CeIcon } from "../../images/Icons/CeIcon";
import { Admin } from "../admin/Admin";
import { CloudUpload } from "@mui/icons-material";
import { getAlgorithmUploadUrl, uploadFile } from "../../api/Recording";
import { DropzoneDialog } from "react-mui-dropzone";

interface IAlgorithmProps {
  groupId: string;
  recordingId: string;
  algorithmId: string;
  algorithmType: string;
  algorithmDate?: Moment;
  url?: string;
  buttonIcon: JSX.Element;
}

interface IAlgorithmState {
  uploadDialogOpen: boolean;
}

interface CECriteria {
  type: string;
  start?: Moment;
}

export function isCE(
  algorithmType: string,
  algorithmDate?: Moment | undefined
): boolean {
  const criterias: Array<CECriteria> = [
    { type: "ACTINDEX", start: moment(20201015, "YYYYMMDD") },
    { type: "HEARTRATE", start: moment(20201015, "YYYYMMDD") },
    { type: "EDR_RESP", start: moment(20201015, "YYYYMMDD") },
    { type: "ECG" },
    { type: "EEG" },
    { type: "EOG" },
    { type: "EMG" },
    { type: "ACC" },
    { type: "GYR" },
    { type: "BAT" },
    { type: "LEAD_OFF" },
    { type: "EVENT" },
  ];

  const passesCrit = function (criteria: CECriteria): boolean {
    return (
      criteria.type === algorithmType &&
      (criteria.start === undefined ||
        (algorithmDate !== undefined && algorithmDate >= criteria.start))
    );
  };

  const location = criterias.findIndex(passesCrit);
  return location !== -1;
}

export class Algorithm extends Component<IAlgorithmProps, IAlgorithmState> {
  constructor(props: IAlgorithmProps) {
    super(props);
    this.state = { uploadDialogOpen: false };
  }

  public render(): JSX.Element {
    return (
      <Grid
        container
        spacing={1}
        justifyContent="flex-start"
        alignContent="stretch"
      >
        <Grid item xs={4}>
          <Button
            key={this.props.algorithmId}
            color="primary"
            title={`Download ${this.props.algorithmType}`}
            variant="contained"
            href={this.props.url}
            startIcon={this.props.buttonIcon}
            onClick={() =>
              this.getAlgorithm(
                this.props.groupId,
                this.props.recordingId,
                this.props.algorithmId
              )
            }
            fullWidth={true}
          >
            {this.props.algorithmType}
          </Button>
        </Grid>

        <Grid item>
          <Typography variant="caption">{this.getMark()}</Typography>
        </Grid>

        <Admin>
          <DropzoneDialog
            open={this.state.uploadDialogOpen}
            dialogTitle="Upload a new algorithm version. This will update the existing algorithm and cannot be undone."
            acceptedFiles={["text/csv"]}
            filesLimit={1}
            maxFileSize={100 * 1000 * 1000}
            onClose={() => {
              this.setState({ uploadDialogOpen: false });
            }}
            onSave={async (files, e) => {
              try {
                for (const file of files) {
                  const response = await getAlgorithmUploadUrl(
                    this.props.groupId,
                    this.props.recordingId,
                    this.props.algorithmId
                  );
                  if (
                    response !== undefined &&
                    response.uploadUrl !== undefined
                  ) {
                    await uploadFile(file, response.uploadUrl);
                  } else {
                    throw new Error("Unable to retrieve an upload url");
                  }
                }

                this.setState({ uploadDialogOpen: false });
              } catch (error) {
                console.error("Failed to upload", error);
                throw error;
              }
            }}
          />
          <Grid item xs={1}>
            {this.props.algorithmId !== undefined &&
              this.props.algorithmId !== "" && (
                <IconButton
                  color="primary"
                  component="span"
                  disabled={
                    this.props.groupId === undefined ||
                    this.props.groupId === "" ||
                    this.props.recordingId === undefined ||
                    this.props.recordingId === "" ||
                    this.props.algorithmId === undefined ||
                    this.props.algorithmId === "" ||
                    typeof this.props.algorithmId !== "string"
                  }
                  onClick={() => {
                    this.setState({
                      uploadDialogOpen: true,
                    });
                  }}
                  size="large"
                >
                  <CloudUpload />
                </IconButton>
              )}
          </Grid>
        </Admin>
      </Grid>
    );
  }

  private getMark() {
    if (isCE(this.props.algorithmType, this.props.algorithmDate)) {
      return (
        <Tooltip title="CE mark">
          <CeIcon />
        </Tooltip>
      );
    } else {
      return "Investigational Use Only";
    }
  }

  private getAlgorithm(groupId: string, recordingId: string, id: string) {
    API.get(
      "BytefliesCloudPlatformAPI",
      `groups/${groupId}/recordings/${recordingId}/algorithms/${id}`,
      {}
    ).then(
      (response: { uri: string }) => {
        if (response) {
          window.location.href = response.uri;
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  }
}
