import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  FormControl,
  IconButton,
  Link,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
  Button,
  Alert,
  ToggleButton,
} from "@mui/material";
import dayjs from "dayjs";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import debounce from "lodash.debounce";
import { GraphSelection } from "./GraphInterfaces";
import "./RAW.css";
import Plotly, { Annotations, Shape } from "plotly.js-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";
import { useData } from "./useData";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
const Plot = createPlotlyComponent(Plotly);

const Fili = require("fili");

interface Props {
  selection: { start: number; end: number };
  ch1Title: string;
  ch2Title: string;
  onSelectionChange: (s: GraphSelection) => void;
  websocket?: WebSocket;
}

const RAW: React.FC<Props> = (props: Props) => {
  const [currentSelection, setCurrentSelection] = useState<{
    start: number;
    end: number;
  }>({
    start: props.selection.start * 1000,
    end: props.selection.end * 1000,
  });

  const [live, setLive] = useState<boolean>(true);
  const [outOfBounds, setOutOfBounds] = useState<boolean>(false);
  React.useEffect(() => {
    /*
    if (props.headRecording) {
    } else {
      setOutOfBounds(false);
    }
    */
    // eslint-disable-next-line
  }, [currentSelection]);

  const data = useData("eeg-live", props.websocket);

  const [filteredCh1, setFilteredCh1] = useState<number[] | undefined>();
  const [filteredCh2, setFilteredCh2] = useState<number[] | undefined>();
  const [x, setX] = useState<number[] | undefined>();

  const [plotShapes, setPlotShapes] = useState<Partial<Shape>[]>([]);
  const [plotAnnotations, setPlotAnnotations] = useState<
    Partial<Annotations>[]
  >([]);

  const [resolution, setResolution] = useState(1 / 10 ** 3);
  const [lowpass, setLowpass] = useState(0);
  const [highpass, setHighpass] = useState(0);
  const [notch, setNotch] = useState(0);

  const setSetting = (
    setting: "resolution" | "lowpass" | "highpass" | "notch",
    n: number
  ) => {
    localStorage.setItem(`${setting}`, "" + n);
  };

  const getSetting = (
    setting: "resolution" | "lowpass" | "highpass" | "notch"
  ) => {
    const saved = localStorage.getItem(`${setting}`);
    switch (setting) {
      case "resolution": {
        if (saved) {
          return parseFloat(saved);
        } else {
          return 1 / 10 ** 3;
        }
      }
      case "lowpass": {
        if (saved) {
          return parseFloat(saved);
        } else {
          return 70;
        }
      }
      case "highpass": {
        if (saved) {
          return parseFloat(saved);
        } else {
          return 0.5;
        }
      }
      case "notch": {
        if (saved) {
          return parseFloat(saved);
        } else {
          return 50;
        }
      }
    }
  };

  React.useEffect(() => {
    setResolution(getSetting("resolution"));
    setLowpass(getSetting("lowpass"));
    setHighpass(getSetting("highpass"));
    setNotch(getSetting("notch"));
    // eslint-disable-next-line
  }, []);

  const applyFilter = React.useCallback(
    (
      data: number[],
      fs: number,
      highpass: number | undefined,
      lowpass: number | undefined,
      notch: number | undefined
    ) => {
      if (!data) {
        console.log("empty data, can't filter");
        return;
      }

      // padding of the last known value is added to both sides before filtering to reduce weird patterns at the edges of the chunks
      const padding = 60 * fs;
      const headPadding = Array<number>(padding).fill(data[0]);
      const tailPadding = Array<number>(padding).fill(data[data.length - 1]);
      let filteredData = [...headPadding, ...data, ...tailPadding];

      const calc = new Fili.CalcCascades();
      if (highpass) {
        const highpassFilter = new Fili.IirFilter(
          calc.highpass({
            order: 4,
            characteristic: "butterworth",
            Fs: fs,
            BW: 1,
            Fc: highpass,
          }),
          true
        );
        filteredData = highpassFilter.filtfilt(filteredData);
      }

      if (lowpass) {
        const lowpassFilter = new Fili.IirFilter(
          calc.lowpass({
            order: 4,
            characteristic: "butterworth",
            Fs: fs,
            BW: 1,
            Fc: lowpass,
          }),
          true
        );
        filteredData = lowpassFilter.filtfilt(filteredData);
      }
      if (notch) {
        const notchFilter = new Fili.IirFilter(
          calc.bandstop({
            order: 4,
            characteristic: "butterworth",
            Fs: fs,
            Fc: notch,
            BW: 0.5,
          })
        );
        filteredData = notchFilter.filtfilt(filteredData);
      }
      return filteredData.slice(padding, filteredData.length - padding);
    },
    []
  );

  useEffect(() => {
    if (data.data) {
      setFilteredCh1(
        applyFilter(
          data.data.ch1?.map((v: number) => v),
          250,
          highpass,
          lowpass,
          notch
        )
      );
      setFilteredCh2(
        applyFilter(
          data.data.ch2?.map((v: number) => v),
          250,
          highpass,
          lowpass,
          notch
        )
      );
      setX(data.data.xAxis1);
    }
  }, [
    applyFilter,
    data.data,
    data.data.ch1,
    data.data.ch2,
    data.data.xAxis1,
    data.dataEnd,
    highpass,
    lowpass,
    notch,
  ]);

  const debouncedSelectionChange = React.useMemo(
    () =>
      debounce(() => props.onSelectionChange(currentSelection), 300, {
        maxWait: 1 * 1000,
      }),
    [props, currentSelection]
  );

  useEffect(() => {
    return () => {
      debouncedSelectionChange.cancel();
    };
  }, [debouncedSelectionChange]);

  React.useEffect(() => {
    // update root selection with debounce
    debouncedSelectionChange();
    // eslint-disable-next-line
  }, [currentSelection]);

  useEffect(() => {
    if (
      props.selection.start * 1000 !== currentSelection.start ||
      props.selection.end * 1000 !== currentSelection.end
    ) {
      setCurrentSelection({
        start: props.selection.start * 1000,
        end: props.selection.end * 1000,
      });
    }
    // eslint-disable-next-line
  }, [props.selection]);

  useEffect(() => {
    const titles: Partial<Annotations>[] = [
      {
        x: 0,
        y: 0.98,
        xref: "paper",
        yref: "paper",
        text: props.ch2Title,
        showarrow: false,
        ax: 0,
        ay: 0,
        font: {
          color: "black",
        },
      },
      {
        x: 0,
        y: 0.02,
        xref: "paper",
        yref: "paper",
        text: props.ch1Title,
        showarrow: false,
        ax: 0,
        ay: 0,
        font: {
          color: "black",
        },
      },
    ];
    setPlotAnnotations(titles);
    // eslint-disable-next-line
  }, [data.end, props.ch1Title, props.ch2Title]);

  /*
  useEffect(() => {
    // live
    if (live) {
        if (data.end) {
          setCurrentSelection({ start: data.end - 10 * 1000, end: data.end });
        }
    }
  }, [live, data.end]);
  */

  useEffect(() => {
    // live
    let scroller: NodeJS.Timer;
    if (live && data.end) {
      scroller = setInterval(() => {
        const now = Date.now() - 1 * 1000;
        setCurrentSelection({ start: now - 10 * 1000, end: now });
      }, 20);
    }
    // TODO dynamically scroll in relation to data.end
    return () => {
      if (scroller) {
        clearTimeout(scroller);
      }
    };
  }, [live, data.end]);

  const scaleUnit = React.useCallback((value: number) => {
    let scale = "";
    if (value < 1 / 10 ** 6) {
      scale = "n";
      value = value * 10 ** 9;
    } else if (value < 1 / 10 ** 3) {
      scale = "µ";
      value = value * 10 ** 6;
    } else if (value < 1) {
      scale = "m";
      value = value * 10 ** 3;
    }
    return { value, scale };
  }, []);

  const calculateRange = React.useCallback(
    (resolution: number, place: number, places: number) => {
      const full = 0.5 + places * (2 + 0.25);
      const zero = 0.5 + (place - 1) * (2 + 0.25) + 1;
      return [-zero * resolution, (full - zero) * resolution];
    },
    []
  );

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 10,
          margin: 7,
          boxShadow: "0px 0px 5px -3px",
        }}
      >
        <div
          className="eeg"
          style={{
            width: "100%",
            minHeight: 500,
            height: "100%",
            backgroundColor: "white",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 15,
                paddingRight: 5,
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  display: "inline",
                  whiteSpace: "nowrap",
                  marginTop: 12,
                  marginBottom: 12,
                }}
              >
                Raw Data
              </Typography>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span
                  style={{
                    color: "grey",
                    paddingLeft: "20px",
                    fontSize: "smaller",
                  }}
                >
                  Amplitude:
                </span>
                {resolution !== undefined &&
                  [
                    1 / 10 ** 6,
                    2 / 10 ** 6,
                    5 / 10 ** 6,
                    1 / 10 ** 5,
                    2 / 10 ** 5,
                    5 / 10 ** 5,
                    1 / 10 ** 4,
                    2 / 10 ** 4,
                    5 / 10 ** 4,
                    1 / 10 ** 3,
                    2 / 10 ** 3,
                    5 / 10 ** 3,
                    1 / 10 ** 2,
                    2 / 10 ** 2,
                    5 / 10 ** 2,
                    1 / 10 ** 1,
                    2 / 10 ** 1,
                    5 / 10 ** 1,
                  ].map((u) => {
                    const unit = "V";
                    const scaled = scaleUnit(u);
                    return (
                      <ToggleButton
                        value={u}
                        key={u}
                        size="small"
                        selected={resolution === u}
                        disabled={resolution === u}
                        onChange={() => {
                          setResolution(u);
                          setSetting("resolution", u);
                        }}
                        style={{
                          width: 50,
                          textTransform: "none",
                          paddingTop: 2,
                          paddingBottom: 2,
                          paddingLeft: 4,
                          paddingRight: 4,
                          fontWeight: 400,
                          margin: 2,
                          color: resolution === u ? "" : "grey",
                          backgroundColor: "white",
                        }}
                      >
                        <span>
                          {scaled.value}
                          <span style={{ fontSize: "smaller" }}>
                            {scaled.scale}
                            {unit}
                          </span>
                        </span>
                      </ToggleButton>
                    );
                  })}
                <span
                  style={{
                    color: "grey",
                    paddingLeft: "15px",
                    paddingRight: "5px",
                    fontSize: "smaller",
                  }}
                >
                  Filters:
                </span>
                <span>
                  <FormControl
                    size="small"
                    focused={false}
                    style={{ width: "65px" }}
                  >
                    <Select
                      style={{ color: "grey" }}
                      value={highpass}
                      onChange={(e) => {
                        setHighpass(e.target.value as number);
                        setSetting("highpass", e.target.value as number);
                      }}
                    >
                      <MenuItem value={0.5}>
                        0.5
                        <span style={{ fontSize: "smaller" }}>Hz</span>
                      </MenuItem>
                      <MenuItem value={1}>
                        1<span style={{ fontSize: "smaller" }}>Hz</span>
                      </MenuItem>
                      <MenuItem value={0}>OFF</MenuItem>
                    </Select>
                    <FormHelperText style={{ marginTop: "0px" }}>
                      Highpass
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    size="small"
                    focused={false}
                    style={{ width: "65px", paddingLeft: 5 }}
                  >
                    <Select
                      style={{ color: "grey" }}
                      value={lowpass}
                      onChange={(e) => {
                        setLowpass(e.target.value as number);
                        setSetting("lowpass", e.target.value as number);
                      }}
                    >
                      <MenuItem value={15}>
                        15
                        <span style={{ fontSize: "smaller" }}>Hz</span>
                      </MenuItem>
                      <MenuItem value={30}>
                        30
                        <span style={{ fontSize: "smaller" }}>Hz</span>
                      </MenuItem>
                      <MenuItem value={70}>
                        70
                        <span style={{ fontSize: "smaller" }}>Hz</span>
                      </MenuItem>
                      <MenuItem value={0}>OFF</MenuItem>
                    </Select>
                    <FormHelperText style={{ marginTop: "0px" }}>
                      Lowpass
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    size="small"
                    focused={false}
                    style={{ width: "90px", paddingLeft: 5 }}
                  >
                    <Select
                      style={{ color: "grey" }}
                      value={notch}
                      onChange={(e) => {
                        setNotch(e.target.value as number);
                        setSetting("notch", e.target.value as number);
                      }}
                    >
                      <MenuItem value={50}>
                        On
                        <span style={{ fontSize: "smaller" }}> (50Hz)</span>
                      </MenuItem>
                      <MenuItem value={60}>
                        On
                        <span style={{ fontSize: "smaller" }}> (60Hz)</span>
                      </MenuItem>
                      <MenuItem value={0}>OFF</MenuItem>
                    </Select>
                    <FormHelperText style={{ marginTop: "0px" }}>
                      Notch
                    </FormHelperText>
                  </FormControl>
                </span>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {data.loading && (
                <CircularProgress
                  disableShrink
                  size={20}
                  style={{
                    position: "absolute",
                    pointerEvents: "none",
                    margin: 5,
                    right: 0,
                    bottom: 0,
                    zIndex: 100,
                    marginLeft: 30,
                    marginBottom: 30,
                  }}
                />
              )}
              {data.error ? (
                <Alert
                  severity="error"
                  style={{
                    position: "absolute",
                    zIndex: 100,
                    marginLeft: 30,
                    marginBottom: 20,
                  }}
                >
                  {data.error}
                </Alert>
              ) : !data.data.xAxis1.length ? (
                <Alert
                  severity="info"
                  style={{
                    position: "absolute",
                    zIndex: 100,
                    marginLeft: 30,
                    marginBottom: 20,
                  }}
                >
                  {"No data available"}
                </Alert>
              ) : props.websocket && outOfBounds ? (
                <Alert
                  severity="info"
                  style={{
                    position: "absolute",
                    zIndex: 100,
                    marginLeft: 30,
                    marginBottom: 20,
                  }}
                >
                  {"No data available at this time"}
                  <Link
                    onClick={() => {
                      if (props.websocket) {
                        // TODO
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      display: "block",
                    }}
                  >
                    {"Go to next start"}
                  </Link>
                </Alert>
              ) : (
                ""
              )}
              <div
                onWheel={(e: any) => {
                  const delta = Math.abs(e.deltaY);
                  // const factor = delta * 3;
                  const factor =
                    delta > 200
                      ? 1000
                      : delta > 100
                      ? 400
                      : delta > 20
                      ? 200
                      : 100;

                  const val = e.deltaY > 0 ? -factor : factor;
                  setCurrentSelection({
                    start: currentSelection.start + val,
                    end: currentSelection.end + val,
                  });
                }}
                id={"eegGraphContainer"}
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "25px",
                    marginTop: 10,
                    marginBottom: 20,
                    position: "relative",
                    opacity: 0.8,
                    fontSize: 13,
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: `${(0.5 / (4.5 + 4)) * 100}%`,
                      right: 0,
                      transform: "translateY(-50%)",
                    }}
                  >
                    {scaleUnit(resolution).value}
                    {scaleUnit(resolution).scale}
                    {"V"}
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      top: `${(1.5 / (4.5 + 4)) * 100}%`,
                      right: 0,
                      transform: "translateY(-50%)",
                    }}
                  >
                    0
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      top: `${(2.5 / (4.5 + 4)) * 100}%`,
                      right: 0,
                      transform: "translateY(-50%)",
                    }}
                  >
                    -{scaleUnit(resolution).value}
                    {scaleUnit(resolution).scale}
                    {"V"}
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      bottom: `${(5.5 / (4.5 + 4)) * 100}%`,
                      right: 0,
                      transform: "translateY(50%)",
                    }}
                  >
                    {scaleUnit(resolution).value}
                    {scaleUnit(resolution).scale}
                    {"V"}
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      bottom: `${(4.5 / (4.5 + 4)) * 100}%`,
                      right: 0,
                      transform: "translateY(50%)",
                    }}
                  >
                    0
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      bottom: `${(3.5 / (4.5 + 4)) * 100}%`,
                      right: 0,
                      transform: "translateY(50%)",
                    }}
                  >
                    -{scaleUnit(resolution).value}
                    {scaleUnit(resolution).scale}
                    {"V"}
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Plot
                    onRelayout={(e) => {
                      if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
                        const start = dayjs(e["xaxis.range[0]"]).valueOf();
                        const end = dayjs(e["xaxis.range[1]"]).valueOf();
                        setCurrentSelection({ start: start, end: end });
                      }
                    }}
                    useResizeHandler
                    style={{ width: "100%" }}
                    data={[
                      {
                        x: x,
                        y: filteredCh1,
                        type: "scattergl",
                        mode: "lines",
                        name: props.ch1Title,
                        hoverinfo: "none",
                        line: {
                          color: "black",
                          width: 1,
                        },
                      },
                      {
                        x: x,
                        y: filteredCh2,
                        type: "scattergl",
                        name: props.ch2Title,
                        mode: "lines",
                        hoverinfo: "none",
                        yaxis: "y2",
                        line: {
                          color: "black",
                          width: 1,
                        },
                      },
                      {
                        x: data.data.xAxisAcc,
                        y: data.data.accX,
                        type: "scattergl",
                        mode: "lines",
                        hoverinfo: "none",
                        yaxis: "y3",
                        line: {
                          color: "red",
                          width: 1,
                        },
                      },
                      {
                        x: data.data.xAxisAcc,
                        y: data.data.accY,
                        type: "scattergl",
                        mode: "lines",
                        hoverinfo: "none",
                        yaxis: "y3",
                        line: {
                          color: "green",
                          width: 1,
                        },
                      },
                      {
                        x: data.data.xAxisAcc,
                        y: data.data.accZ,
                        type: "scattergl",
                        mode: "lines",
                        hoverinfo: "none",
                        yaxis: "y3",
                        line: {
                          color: "blue",
                          width: 1,
                        },
                      },
                    ]}
                    layout={{
                      margin: {
                        l: 10,
                        r: 0,
                        b: 20,
                        t: 10,
                        pad: 2,
                      },
                      plot_bgcolor: "transparent",
                      paper_bgcolor: "transparent",
                      dragmode: "pan",
                      xaxis: {
                        type: "date",
                        tickformat: "%H:%M:%S",
                        dtick: 1000,
                        range: [currentSelection?.start, currentSelection?.end],
                      },
                      yaxis: {
                        domain: [0, 1],
                        showline: false,
                        zeroline: false,
                        showgrid: false,
                        fixedrange: true,
                        showticklabels: false,
                        range: [4.5 * -resolution, 4 * resolution],
                      },
                      yaxis2: {
                        domain: [0, 1],
                        showline: false,
                        zeroline: false,
                        showgrid: false,
                        fixedrange: true,
                        showticklabels: false,
                        range: [7 * -resolution, 1.5 * resolution],
                      },
                      yaxis3: {
                        domain: [0, 1],
                        showline: false,
                        zeroline: false,
                        showgrid: false,
                        fixedrange: true,
                        showticklabels: false,
                        range: calculateRange(1, 1, 4),
                      },
                      yaxis4: {
                        domain: [0, 1],
                        showline: false,
                        zeroline: false,
                        showgrid: false,
                        fixedrange: true,
                        showticklabels: false,
                        range: calculateRange(1, 2, 4),
                      },
                      showlegend: false,
                      shapes: plotShapes,
                      annotations: plotAnnotations,
                    }}
                    config={{
                      displayModeBar: false,
                      plotGlPixelRatio: 2,
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                paddingLeft: "35px",
                position: "relative",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  paddingLeft: 35,
                  left: 0,
                  opacity: 0.8,
                  fontSize: 13,
                }}
              >
                {dayjs(currentSelection.start).format("dddd D MMM YYYY")}
              </span>
            </div>
            <div
              style={{
                paddingTop: 2,
                textAlign: "center",
                paddingLeft: "35px",
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <IconButton
                aria-label="before"
                size="small"
                onClick={() => {
                  setCurrentSelection({
                    start: currentSelection.start - 10000,
                    end: currentSelection.end - 10000,
                  });
                }}
              >
                <FastRewindIcon />
              </IconButton>
              <IconButton
                aria-label="before"
                size="small"
                onClick={() => {
                  setCurrentSelection({
                    start: currentSelection.start - 5000,
                    end: currentSelection.end - 5000,
                  });
                }}
              >
                <SkipPreviousIcon />
              </IconButton>
              <IconButton
                aria-label="next"
                size="small"
                onClick={() => {
                  setCurrentSelection({
                    start: currentSelection.start + 5000,
                    end: currentSelection.end + 5000,
                  });
                }}
              >
                <SkipNextIcon />
              </IconButton>
              <IconButton
                aria-label="before"
                size="small"
                onClick={() => {
                  setCurrentSelection({
                    start: currentSelection.start + 10000,
                    end: currentSelection.end + 10000,
                  });
                }}
              >
                <FastForwardIcon />
              </IconButton>
              <Button
                size="small"
                variant="contained"
                color="error"
                style={{
                  right: 5,
                  backgroundColor: !live ? "grey" : undefined,
                  minWidth: "unset",
                  padding: "2px 8px",
                  position: "absolute",
                }}
                onClick={() => {
                  setLive(!live);
                }}
              >
                {live ? (
                  <RadioButtonCheckedIcon
                    style={{ padding: "5px 2px 5px 0px" }}
                  />
                ) : (
                  <RadioButtonUncheckedIcon
                    style={{ padding: "5px 2px 5px 0px" }}
                  />
                )}
                Live
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RAW;
