import React from "react";

const svg = (props: any) => (
  <svg
    width={props.width || "20"}
    height={props.height || "20"}
    version="1.1"
    viewBox="0 0 82.553 82.553"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.title || "Event"}</title>
    <g transform="translate(29.277 29.277)">
      <path d="m0 0h24v24h-24z" fill="none" />
    </g>
    <g transform="translate(27.319 29.107)">
      <g
        transform="matrix(2.963 0 0 2.4696 -21.598 -17.466)"
        strokeWidth=".36968"
      >
        <path d="M17 10H7v2h10v-2zm2-7h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zm-5-5H7v2h7v-2z" />
      </g>
      <path
        d="m13.958-27.831c-22.094 0-40 17.906-40 40 0 22.094 17.906 40 40 40 22.094 0 40-17.906 40-40 0-22.094-17.906-40-40-40z"
        clipRule="evenodd"
        fill="none"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="3"
      />
    </g>
  </svg>
);

export default function EventIcon(props: {
  width?: number;
  height?: number;
  stroke?: string;
  fill?: string;
  title?: string;
}): JSX.Element {
  return svg(props);
}
