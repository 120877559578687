import { Auth, CognitoUser } from "@aws-amplify/auth";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import React, { Component } from "react";

interface IAccessTokensState {
  user: CognitoUser | undefined;
}

export class AccessTokens extends Component<{}, IAccessTokensState> {
  constructor(props: any) {
    super(props);
    this.state = { user: undefined };
    Auth.currentUserPoolUser().then((user) => {
      this.setState({ user });
    });
  }

  public TokenView(props: {
    name: string;
    token: string;
    expiresAt?: number;
    defaultOpen: boolean;
  }) {
    return (
      <Accordion defaultExpanded={props.defaultOpen}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          {props.name}
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="Token"
            fullWidth={true}
            multiline={true}
            value={props.token}
            disabled
          />
          {props.expiresAt && (
            <TextField label="Expires at" value={props.expiresAt} disabled />
          )}
        </AccordionDetails>
      </Accordion>
    );
  }

  public render() {
    if (!this.state.user || !this.state.user.getSignInUserSession()) {
      return null;
    }

    return (
      <div>
        <this.TokenView
          name="ID Token"
          token={this.state.user
            .getSignInUserSession()!
            .getIdToken()
            .getJwtToken()}
          expiresAt={this.state.user
            .getSignInUserSession()!
            .getIdToken()
            .getExpiration()}
          defaultOpen={false}
        />
        <this.TokenView
          name="Refresh Token"
          token={this.state.user
            .getSignInUserSession()!
            .getRefreshToken()
            .getToken()}
          defaultOpen={false}
        />
        <this.TokenView
          name="Access Token"
          token={this.state.user
            .getSignInUserSession()!
            .getAccessToken()
            .getJwtToken()}
          expiresAt={this.state.user
            .getSignInUserSession()!
            .getAccessToken()
            .getExpiration()}
          defaultOpen={false}
        />
      </div>
    );
  }
}
