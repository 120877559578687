import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import React, { Component } from "react";
import { API } from "aws-amplify";

interface IDeleteRecordingProps {
  groupId: string;
  recordingId: string;
  open: boolean;
  onClose: () => void;
}

interface IDeleteRecordingState {
  errorText: string | undefined;
}

export class DeleteRecordingDialog extends Component<
  IDeleteRecordingProps,
  IDeleteRecordingState
> {
  public render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => {
          this.setState({
            errorText: undefined,
          });
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Delete Recording?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will remove the recording from your group. A backup will remain
            available on the Byteflies Systems.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.onClose();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.deleteRecording();
            }}
            color="primary"
            autoFocus
          >
            Delete Recording
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private deleteRecording() {
    API.del(
      "BytefliesCloudPlatformAPI",
      `groups/${this.props.groupId}/recordings/${this.props.recordingId}`,
      {}
    ).then(
      (response) => {
        if (response) {
          this.props.onClose();
        }
      },
      (rejected) => {
        console.log(rejected);
        this.setState({
          errorText:
            "Something went wrong when deleting the recording. Please try again.",
        });
      }
    );
  }
}
