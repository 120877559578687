import React, { Component, Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Button,
  DialogActions,
  Grid,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { API } from "aws-amplify";
import { DeviceInfo } from "../../api";

interface IDialogState {
  updateStatus: DeviceInfo | undefined;
  apiError: boolean;
  inProgress: boolean;
}

export interface IDeviceInfoProps {
  dockId: string;
  open: boolean;
  onClose: () => void;
}
export class DeviceInfoDialog extends Component<
  IDeviceInfoProps,
  IDialogState
> {
  constructor(props: IDeviceInfoProps) {
    super(props);
    this.state = {
      updateStatus: undefined,
      inProgress: false,
      apiError: false,
    };
  }

  componentDidUpdate(prevProps: IDeviceInfoProps) {
    if (this.props.dockId !== "" && this.props.dockId !== prevProps.dockId) {
      this.getDockUpdateStatus(this.props.dockId);
    }
  }

  public render() {
    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          Device information for {this.props.dockId}
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Typography>Device Type:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {this.state.updateStatus?.Info?.DeviceType}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography>Desired Artifact:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {this.state.updateStatus?.Info?.DesiredArtifact}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography>Current Artifact:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {this.state.updateStatus?.Info?.CurrentArtifact}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography>Resolved Artifact:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {this.state.updateStatus?.Info?.ResolvedArtifact}
              </Typography>
            </Grid>

            {this.state.updateStatus?.DeploymentLastFive?.map(
              (deployment, index) => (
                <Fragment key={index}>
                  <Grid item xs={6}>
                    <Typography>Updated:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {deployment.Started} - {deployment.LastUpdated}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Target:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{deployment.TargetArtifact}</Typography>
                  </Grid>
                </Fragment>
              )
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.onClose();
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.onClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private getDockUpdateStatus(dockId: string) {
    console.log("Update");
    this.setState({ inProgress: true });
    API.get(
      "BytefliesCloudPlatformAPI",
      `devices/${dockId}/updatestatus`,
      {}
    ).then(
      (response) => {
        console.log(response);
        if (response) {
          this.setState({
            updateStatus: response as DeviceInfo,
            apiError: false,
            inProgress: false,
          });
        }
      },
      (rejected) => {
        console.error(rejected);
        this.setState({
          updateStatus: undefined,
          apiError: true,
          inProgress: false,
        });
      }
    );
  }
}
