import { Button } from "@mui/material";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Admin } from "../admin/Admin";
import DockIcon from "../../images/Icons/DockIcon";
import { AssignmentInd } from "@mui/icons-material";
import { IRecordingRouteState } from "../../models/RecordingRouteState";

interface IGroupMenuProps {
  groupId: string;
  groupName: string;
}

interface IGroupMenuState {
  isAdmin: boolean;
  actionMenuOpen: boolean;
  anchorEl: Element | undefined;
}

export class GroupActionsButton extends Component<
  IGroupMenuProps,
  IGroupMenuState
> {
  constructor(props: any) {
    super(props);

    this.getUser();

    this.state = {
      isAdmin: false,
      actionMenuOpen: false,
      anchorEl: undefined,
    };
  }

  public render() {
    return (
      <Fragment>
        {this.state.isAdmin ? (
          <Link
            to={{
              pathname: `/groups/${this.props.groupId}/docks`,
              state: {
                groupName: this.props.groupName,
              } as IRecordingRouteState,
            }}
            color="inherit"
            style={{ textDecoration: "none" }}
          >
            <Button
              startIcon={<DockIcon />}
              variant="outlined"
              size="small"
              title="Docking stations"
            >
              Docking stations
            </Button>
          </Link>
        ) : undefined}
        &nbsp;
        {this.state.isAdmin ? (
          <Link
            to={{
              pathname: `/groups/${this.props.groupId}/patientrecords`,
              state: {
                groupName: this.props.groupName,
              } as IRecordingRouteState,
            }}
            color="inherit"
            style={{ textDecoration: "none" }}
          >
            <Button
              startIcon={<AssignmentInd color="primary" />}
              variant="outlined"
              size="small"
              title="Patient Management"
            >
              Patient Management
            </Button>
          </Link>
        ) : undefined}
      </Fragment>
    );
  }

  private getUser(): void {
    Auth.currentAuthenticatedUser().then(
      (user: CognitoUser) => {
        this.setState({ isAdmin: Admin.isAdmin(user) });
      },
      (reject) => {
        console.error(reject);
      }
    );
  }
}
