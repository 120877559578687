import React from "react";

const svg = (props: any) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={props.width || "20"}
    height={props.height || "20"}
  >
    <title>{props.title || "ECG"}</title>
    <path
      fill={props.fill || "none"}
      strokeWidth={3}
      stroke={props.stroke || "black"}
      strokeMiterlimit={10}
      d="M50,3A47,47,0,1,0,97,50,47,47,0,0,0,50,3Z"
    />
    <path d="M60.5,31.42a13.78,13.78,0,0,0-9.6,3.89l-1,1-1-1a13.8,13.8,0,0,0-9.61-3.89A13.35,13.35,0,0,0,25.85,44l0,.24v.17c0,.22,0,.46,0,.72.78,17,23,27.13,23.22,27.23a1.85,1.85,0,0,0,.62.12h.39a1.58,1.58,0,0,0,.61-.14c.23-.1,22.42-10.27,23.26-27.19,0-.28,0-.52,0-.74v-.2A1,1,0,0,1,74,44C73.74,37.08,67.71,31.42,60.5,31.42Z" />
  </svg>
);

export default function HeartIcon(props: {
  width?: number;
  height?: number;
  stroke?: string;
  fill?: string;
  title?: string;
}): JSX.Element {
  return svg(props);
}
