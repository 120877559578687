import { Link, Typography, CssBaseline, Box, Stack } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  Authenticator,
  AmplifyProvider,
  Image,
  View,
} from "@aws-amplify/ui-react";
import { Home } from "../home/Home";
import React from "react";
import blueLogo from "../../images/dotsButterfly_blue.png";
import { amplifyTheme, bytefliesTheme } from "./theme";
import {
  getDeviceToken,
  deleteDeviceToken,
  initiateCustomFlow,
} from "xfa-connect-sdk-js";
import { CircularProgress } from "@mui/material";

import "@aws-amplify/ui-react/styles.css";

import "./App.css";

function App() {
  const components = {
    Header() {
      return (
        <View textAlign="center">
          <Image alt="logo" src={blueLogo} width={100} />
        </View>
      );
    },

    Footer() {
      return (
        <Stack direction="column" justifyContent="center">
          <Box sx={{ height: "4em" }} />
          <Typography variant="caption" align="center" flexGrow={1}>
            &copy; 2020 Byteflies NV, all rights reserved.
          </Typography>

          <Link
            href="https://www.byteflies.com/terms-conditions"
            align="center"
            flexGrow={1}
          >
            <Typography variant="caption" align="center" flexGrow={1}>
              Terms and Conditions
            </Typography>
          </Link>

          <Link
            href="https://www.byteflies.com/privacy-policy"
            align="center"
            flexGrow={1}
          >
            <Typography variant="caption" align="center" flexGrow={1}>
              Privacy Policy
            </Typography>
          </Link>
        </Stack>
      );
    },

    SignIn: {
      Header() {
        return (
          <Typography align="center" variant="h6">
            Sign in to your Byteflies account
          </Typography>
        );
      },
    },

    ConfirmSignIn: {
      Header() {
        return <Typography>Enter Information:</Typography>;
      },
      Footer() {
        return <Typography>Footer Information</Typography>;
      },
    },
  };

  return (
    <ThemeProvider theme={bytefliesTheme}>
      <CssBaseline />
      <AmplifyProvider theme={amplifyTheme}>
        <Authenticator
          loginMechanisms={["username"]}
          hideSignUp={true}
          components={components}
        >
          {({ signOut, user }) => {
            // require XFA token (for @byteflies.com users)
            if (user?.username?.endsWith("@byteflies.com")) {
              // request token (if valid)
              const deviceToken = getDeviceToken();

              // if no token, initiate custom flow
              if (!deviceToken) {
                initiateCustomFlow(
                  window.location.href,
                  user?.username,
                  "6be1ff09-b6f6-4ce7-84d0-b77fb6aa3769"
                );

                // show a spinner
                return (
                  <div className="spinnerCenter">
                    <CircularProgress />
                  </div>
                );
              }
            }

            // show home page
            return (
              <Home
                user={user}
                signOut={() => {
                  // Removing XFA token
                  deleteDeviceToken();

                  // signing out
                  signOut!();
                }}
              />
            );
          }}
        </Authenticator>
      </AmplifyProvider>{" "}
    </ThemeProvider>
  );
}

export default App;
