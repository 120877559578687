import React from "react";

const svg = (props: any) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={props.width || "20"}
    height={props.height || "20"}
  >
    <title>{props.title || "EMG"}</title>
    <path
      fill={props.fill || "none"}
      strokeWidth={3}
      stroke={props.stroke || "black"}
      strokeMiterlimit={10}
      d="M50,3A47,47,0,1,0,97,50,47,47,0,0,0,50,3Z"
    />
    <path d="M69.14,63.78c2.93-1.58,5.27-3.76,5.12-6.68A3.11,3.11,0,0,0,73.37,55c-.84-.64.45-7.93-14.23-21,0,0-11.34-12.29-13.77-4.95s-1.74,10,7.63,8.37L58.4,51.6a15.51,15.51,0,0,0-9,1.69S41,48.73,33,53.68V69.82s1.86,3,4.54,2.75a25.5,25.5,0,0,0,15.18-1.84S64.54,67.55,69.14,63.78Z" />
  </svg>
);

export default function MuscleIcon(props: {
  width?: number;
  height?: number;
  stroke?: string;
  fill?: string;
  title?: string;
}): JSX.Element {
  return svg(props);
}
