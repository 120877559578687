import React, { Component } from "react";
import { API } from "aws-amplify";
import { Admin } from "../admin/Admin";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Button,
  FormGroup,
  LinearProgress,
} from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import {
  ModeString,
  IPathParams,
  IShadowResponse,
  IDockShadowReport,
} from "../../models/DockManagement";

interface IDockCalibrationState {
  currentStep: number;
  retries: number;
  requestError: boolean;
}

enum Steps {
  SetupCalibration = 0,
  SendCalibration = 1,
  CalibrateSensorDots = 2,
}

export class DockCalibration extends Component<
  RouteComponentProps<IPathParams>,
  IDockCalibrationState
> {
  private dockName: string;
  private FIBONACCI = [0, 1, 1, 2, 3, 5];

  constructor(props: RouteComponentProps<IPathParams>) {
    super(props);
    this.dockName = this.props.match.params.dockName;
    this.state = {
      currentStep: 0,
      retries: 0,
      requestError: false,
    };
  }

  public componentDidMount() {
    API.get(
      "BytefliesCloudPlatformAPI",
      `docks/${this.dockName}/shadow`,
      {}
    ).then(
      (response: IShadowResponse) => {
        const shadow: IDockShadowReport = JSON.parse(response.shadow.payload);
        if (shadow && shadow.state.reported.mode === ModeString.CALIBRATION) {
          this.handleNext(Steps.SendCalibration);
        }
      },
      (rejected) => {
        console.log(rejected);
        this.goToError();
      }
    );
  }

  public render() {
    return (
      <Admin>
        <Typography variant="h6">
          These are the calibration steps for Docking Station{" "}
          {this.props.match.params.dockName}
        </Typography>

        <Stepper activeStep={this.state.currentStep} orientation="vertical">
          {this.getStepTitles().map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <div>{this.getStepContent(index)}</div>
                <div>
                  <div>
                    {[
                      Steps.SetupCalibration,
                      Steps.CalibrateSensorDots,
                    ].includes(this.state.currentStep) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleNext(this.state.currentStep)}
                      >
                        {this.state.currentStep ===
                        this.getStepTitles().length - 1
                          ? "Finish"
                          : "Start"}
                      </Button>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {this.state.currentStep === this.getStepTitles().length && (
          <Paper square elevation={0} className={"resetText"}>
            {!this.state.requestError && (
              <Typography>
                The Docking Station is now out of calibration mode, you can
                calibrate more Sensor Dots if you click on 'Start Again'
              </Typography>
            )}
            {this.state.requestError && (
              <Typography color="error">
                Something went wrong while setting up the calibration, please
                try again later.
              </Typography>
            )}
            <Button onClick={() => this.handleReset()}>
              {this.state.requestError ? "Try Again" : "Start Again"}
            </Button>
          </Paper>
        )}
      </Admin>
    );
  }

  private handleNext(current: number) {
    if (current === Steps.SetupCalibration) {
      this.sendCalibration(true);
      this.confirmCalibrationSet();
    } else if (current === Steps.CalibrateSensorDots) {
      this.sendCalibration(false);
    }
    this.setState({
      currentStep: current + 1,
    });
  }

  private async sendCalibration(turnOn: boolean) {
    try {
      const params = turnOn
        ? {
            mode: ModeString.CALIBRATION,
          }
        : {
            mode: ModeString.NORMAL,
          };
      await API.put(
        "BytefliesCloudPlatformAPI",
        `docks/${this.dockName}/config`,
        { body: params }
      );
    } catch (err) {
      console.log(err);
      this.goToError();
    }
  }

  private confirmCalibrationSet() {
    if (this.state.retries > this.FIBONACCI.length) {
      this.goToError();
      return;
    }

    API.get(
      "BytefliesCloudPlatformAPI",
      `docks/${this.dockName}/shadow`,
      {}
    ).then(
      (response: IShadowResponse) => {
        const shadow: IDockShadowReport = JSON.parse(response.shadow.payload);
        if (shadow) {
          if (shadow.state.reported.mode === ModeString.CALIBRATION) {
            this.setState({ retries: 0 });
            this.handleNext(Steps.SendCalibration);
          } else {
            this.setState({ retries: this.state.retries + 1 });
            setTimeout(
              () => this.confirmCalibrationSet(),
              this.FIBONACCI[
                Math.min(this.state.retries, this.FIBONACCI.length - 1)
              ] * 1000
            );
          }
        } else {
          this.setState({ retries: this.state.retries + 1 });
          setTimeout(
            () => this.confirmCalibrationSet(),
            this.FIBONACCI[
              Math.min(this.state.retries, this.FIBONACCI.length - 1)
            ] * 1000
          );
        }
      },
      (rejected) => {
        console.log(rejected);
        this.goToError();
      }
    );
  }

  private goToError() {
    if (this.state.requestError) {
      return;
    }
    this.setState({ requestError: true });
    this.handleNext(Steps.CalibrateSensorDots);
  }

  private handleReset() {
    this.setState({
      currentStep: 0,
      requestError: false,
      retries: 0,
    });
  }

  private getStepTitles() {
    return [
      "Set up your calibration",
      `Setting ${this.dockName} to calibration mode`,
      "Calibrate your Sensor Dots",
    ];
  }

  public getStepContent(step: number): JSX.Element {
    switch (step) {
      case 0:
        return <div></div>;
      case 1:
        return (
          <div>
            <Typography>
              Sending the calibration state to {this.dockName}
            </Typography>
            <LinearProgress />
            {this.state.retries >= 4 && (
              <Typography color="error">
                We seem to be having trouble setting the calibration state, is
                your Docking Station connected to the internet?
              </Typography>
            )}
          </div>
        );
      case 2:
        return (
          <div>
            <FormGroup className={"configSelect"}>
              <Typography>You are set up to calibrate Sensor Dots</Typography>
            </FormGroup>
            <Paper elevation={2} className={"instructions"}>
              <Typography>
                - Place a Sensor Dot on any slot of {this.dockName}
              </Typography>
              <Typography>
                - The calibration command will be sent out every 10s.
              </Typography>
              <Typography>
                - When calibration was successful, the Sensor Dot will keep
                blinking Orange rapidly until it is undocked. On failure, the
                LED will turn red and the Sensor Dot will reboot.
              </Typography>
              <Typography>
                - On success, take the Sensor Dot out from the Docking Station,
                and that one is done!
              </Typography>
              <Typography variant={"subtitle2"}>
                Note: The Sensor Dot does not calibrate again after successfully
                doing it once while still being docked. For recalibration,
                please undock it and put in it de Docking Station again.
                IMPORTANT: over the lifetime of a dot, it can only be calibrated
                a maximum of 14 times.
              </Typography>
            </Paper>
          </div>
        );
      default:
        return <div>'Unknown step'</div>;
    }
  }
}
