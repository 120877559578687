import React from "react";

const svg = (props: any) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={props.width || "20"}
  >
    <title>{props.title || "Accelerometer"}</title>
    <path
      fill={props.fill || "none"}
      strokeWidth={3}
      stroke={props.stroke || "black"}
      strokeMiterlimit={10}
      d="M50.2,3.18A46.81,46.81,0,1,0,97,50,46.85,46.85,0,0,0,50.2,3.18Z"
    />
    <path d="M61.27,27.14c3.28.29,5.15,1.75,5.17,4.65s-1.76,4.62-4.66,4.62a4.23,4.23,0,0,1-4.47-4.23C57.1,29.18,59.07,27.84,61.27,27.14Z" />
    <path d="M29.79,32H40.45a1.81,1.81,0,1,1,0,3.62H29.79a1.81,1.81,0,1,1,0-3.62Z" />
    <path d="M34.55,46.37H23.89a1.81,1.81,0,1,1,0-3.62H34.55a1.81,1.81,0,1,1,0,3.62Z" />
    <path d="M74.55,52.46c-.53,2.47-2.42,1.18-3.67.91a15.62,15.62,0,0,1-8.4-5c-2.12,4.08-2.2,7.1,1.33,9.69,1.55,1.15,2.28,17.06,1.24,18.4-.74,1-1.75.45-2.65.55-1.23.11-1.65-.65-1.65-1.74,0-2.49,0-5,0-7.5,0-4.95,0-4.95-4.58-8.07-.28.23-.69.41-.78.68-.9,2.67-.49,7.6-2.5,7.66-4.85.18-9.78-1.5-14.64-2.65-1.11-.27-.58-1.64-.43-2.53.26-1.35,1.06-1.72,2.48-1.64,3.22.15,7.07,3.43,9.37.31,1.84-2.49,1.49-6.63,2.09-10,.37-2,.74-4,1.13-6.22-3.92-.19-3.66,2.11-3.8,4.43-.1,1.54,1.36,4.05-2.11,4.19S44.41,51.6,44.59,50c.27-2.51-1.66-6,1.22-7.41,4.27-2,8.46-4.71,13.4-4.79,2.12,0,3.15,1.93,4.07,3.66,2,3.73,4.7,6.77,9,7.45C74.84,49.3,74.94,50.7,74.55,52.46Z" />
  </svg>
);

export default function AccIcon(props: {
  width?: number;
  stroke?: string;
  fill?: string;
  title?: string;
}): JSX.Element {
  return svg(props);
}
