import React, { Component, Fragment } from "react";
import GyrIcon from "../../images/Icons/GyrIcon";
import EyeIcon from "../../images/Icons/EyeIcon";
import MuscleIcon from "../../images/Icons/MuscleIcon";
import BrainIcon from "../../images/Icons/BrainIcon";
import HeartIcon from "../../images/Icons/HeartIcon";
import AccIcon from "../../images/Icons/AccIcon";
import BatteryIcon from "../../images/Icons/BatteryIcon";
import LeadOffIcon from "../../images/Icons/LeadOffIcon";
import EventIcon from "../../images/Icons/EventIcon";
import LungIcon from "../../images/Icons/LungIcon";
import PPGIcon from "../../images/Icons/PPGIcon";
import SyncIcon from "../../images/Icons/SyncIcon";
import {
  sortSignal,
  getDisplayName,
  Location,
  LocationLabeled,
  LocationLead,
  LocationBipolar,
  Quality,
} from "../../models/Recording";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Grid,
  Button,
} from "@mui/material";
import { ClipboardItem } from "./ClipboardItem";
import { Algorithm } from "./Algorithm";
import { Description, BarChart } from "@mui/icons-material";
import { SignalResponse } from "../../models/Recording";
import { SignalLocationDialog, TabValues } from "./SignalLocationDialog";
import moment from "moment";

interface ISignalListProps {
  groupId: string;
  recordingId: string;
  signals: SignalResponse[];
}

interface ISignalListState {
  hasConversionFactor: boolean;
  signalLocationDialogOpen: boolean;
  selectedSignal: SignalResponse | undefined;
}

export class SignalList extends Component<ISignalListProps, ISignalListState> {
  constructor(props: ISignalListProps) {
    super(props);
    this.state = {
      hasConversionFactor: false,
      signalLocationDialogOpen: false,
      selectedSignal: undefined,
    };
  }

  public componentDidMount(): void {
    this.hasConversionFactor();
  }

  private hasConversionFactor(): void {
    for (const s of this.props.signals) {
      if (s.conversionFactor && s.conversionFactor !== 0) {
        this.setState({ hasConversionFactor: true });
        break;
      }
    }
  }

  private getConversionFactor(signal: SignalResponse): JSX.Element {
    if (signal.conversionFactor === 0) {
      return <></>;
    }
    const factorWithUnits = getConversionFactorWithUnits(signal);
    return (
      <ClipboardItem
        title={signal.conversionFactor.toString()}
        label={factorWithUnits}
        disableAllCaps={true}
      />
    );
  }

  public render(): JSX.Element {
    return (
      <div>
        <Table aria-label="signals" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Signal</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Channel</TableCell>
              <TableCell>Location</TableCell>
              {this.state.hasConversionFactor && (
                <TableCell align="right" size="small">
                  Conversion&nbsp;Factor
                </TableCell>
              )}
              <TableCell align="right" size="small">
                Sampling&nbsp;Rate
              </TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.signals
              .sort((s1, s2) => sortSignal(s1, s2))
              .map((signal) => {
                return (
                  <Fragment key={`${signal.type}_${signal.channel}`}>
                    <TableRow>
                      <TableCell
                        padding="checkbox"
                        size="small"
                        align="left"
                        style={{ verticalAlign: "top" }}
                      >
                        {showIcon(signal.type, signal.quality)}
                      </TableCell>
                      <TableCell align="left" style={{ verticalAlign: "top" }}>
                        {getDisplayName(signal.type)}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {signal.channel ? signal.channel : ""}
                      </TableCell>
                      <TableCell
                        style={{ verticalAlign: "top" }}
                        align="justify"
                      >
                        {!hideLocation(signal) && (
                          <Tooltip title="Set the signal location">
                            <Button
                              fullWidth={true}
                              size="medium"
                              variant={
                                signal.location === undefined
                                  ? "outlined"
                                  : "contained"
                              }
                              color={
                                signal.location === undefined
                                  ? "primary"
                                  : "secondary"
                              }
                              onClick={() => {
                                this.setState({
                                  selectedSignal: signal,
                                  signalLocationDialogOpen: true,
                                });
                              }}
                            >
                              {locationToString(signal.location) ? (
                                locationToString(signal.location)
                              ) : (
                                <span>Set&nbsp;location</span>
                              )}
                            </Button>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        size="small"
                        style={{ verticalAlign: "top" }}
                      >
                        {this.state.hasConversionFactor &&
                          this.getConversionFactor(signal)}
                      </TableCell>
                      <TableCell
                        align="right"
                        size="small"
                        style={{ verticalAlign: "top" }}
                      >
                        {getSamplingRate(signal.samplingRate)}
                      </TableCell>

                      <TableCell align="right" style={{ verticalAlign: "top" }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Algorithm
                              groupId={this.props.groupId}
                              recordingId={this.props.recordingId}
                              algorithmId={""}
                              algorithmType={signal.type}
                              url={signal.rawData}
                              buttonIcon={<Description />}
                            />
                          </Grid>
                          {signal.algorithms
                            ?.sort((a, b) => (a.type > b.type ? 1 : -1))
                            .map((algo) => (
                              <Grid item xs={12} key={algo.id}>
                                <Algorithm
                                  groupId={this.props.groupId}
                                  recordingId={this.props.recordingId}
                                  algorithmId={algo.id}
                                  algorithmType={algo.type}
                                  algorithmDate={moment.utc(algo.added * 1000)}
                                  buttonIcon={<BarChart />}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>

        <SignalLocationDialog
          groupId={this.props.groupId}
          recordingId={this.props.recordingId}
          signal={this.state.selectedSignal!}
          defaultType={getDefaultType(this.state.selectedSignal)}
          onClose={() => {
            this.setState({ signalLocationDialogOpen: false });
          }}
          open={this.state.signalLocationDialogOpen}
          leadEnabled={this.state.selectedSignal?.type === "ECG" || false}
          posNegEnabled={this.state.selectedSignal?.type === "EEG" || false}
        ></SignalLocationDialog>
      </div>
    );
  }
}

function hideLocation(signal: SignalResponse): boolean {
  return (
    signal.type === "BAT" ||
    signal.type === "LEAD_OFF" ||
    signal.type === "EVENT" ||
    signal.type === "SYNC"
  );
}

function getDefaultType(signal: SignalResponse | undefined): TabValues {
  if (signal?.location !== undefined) {
    const posLead = signal?.location as LocationLead;
    const posBipolar = signal?.location as LocationBipolar;
    const posLabeled = signal?.location as LocationLabeled;

    if (posBipolar && posBipolar.positive && posBipolar.negative) {
      return TabValues.PosNeg;
    } else if (posLead && posLead.lead !== undefined && posLead.lead !== "") {
      return TabValues.Lead;
    } else if (
      posLabeled &&
      posLabeled.label !== undefined &&
      posLabeled.label !== ""
    ) {
      return TabValues.Manual;
    }
  } else if (signal?.type === "EEG") {
    return TabValues.PosNeg;
  } else if (signal?.type === "ECG") {
    return TabValues.Lead;
  }
  return TabValues.Manual;
}

function locationToString(location: Location | undefined): string | undefined {
  if (!location) {
    return undefined;
  }

  const lbl = location as LocationLabeled;
  if (lbl && lbl.label) {
    return lbl.label;
  }
  const lead = location as LocationLead;
  if (lead && lead.lead) {
    return lead.lead;
  }
  const bp = location as LocationBipolar;
  if (bp && bp.positive && bp.negative) {
    return `${bp.positive}-${bp.negative}`;
  }
  return undefined;
}

export function getSamplingRate(samplingRate: number): string {
  if (samplingRate === 0 || isNaN(samplingRate)) {
    return "";
  } else if (samplingRate < 0.09) {
    // Wen sampling rate is lower than 1, show 1/60 Hz
    // 1/60 Hz, 1/120 Hz etc
    return `1/${Math.round(1 / samplingRate)} Hz`;
  } else {
    return `${samplingRate} Hz`;
  }
}

export function getConversionFactorWithUnits(signal: SignalResponse): string {
  if (signal.conversionFactor === 0) {
    return "";
  }
  const factor = round(signal.conversionFactor);
  let factorWithUnits = "";
  if (signal.type === "EVENT") {
    factorWithUnits = "";
  } else if (signal.type.startsWith("E")) {
    factorWithUnits = `${factor} nV`;
  } else if (signal.type === "ACC") {
    factorWithUnits = `${factor} g`;
  } else if (signal.type === "GYR") {
    factorWithUnits = `${factor} °/s`;
  } else if (signal.type === "BAT") {
    factorWithUnits = `${factor.toFixed(0)} %`;
  } else if (signal.type === "RESP") {
    factorWithUnits = `${factor.toFixed(0)} nH`;
  } else if (signal.type === "SPO2") {
    factorWithUnits = `${factor.toFixed(0)} %`;
  }
  return factorWithUnits;
}

function round(factor: number): number {
  return Math.round(factor * 10000 + Number.EPSILON) / 10000;
}

export function showIcon(signal: string, quality?: Quality) {
  const fill = "none";
  const qualityString = quality ? `(${quality})` : "";
  const title = `${getDisplayName(signal)}  ${qualityString}`;

  // if (quality) {
  //   if (quality === "PASS") {
  //     fill = "#ccffcc";
  //   } else if (quality === "CHECK") {
  //     fill = "#ffcc66";
  //   } else if (quality === "FAIL") {
  //     fill = "#ffcccc";
  //   }
  // }
  if (signal === "EEG") {
    return <BrainIcon fill={fill} title={title} />;
  } else if (signal === "EOG") {
    return <EyeIcon fill={fill} title={title} />;
  } else if (signal === "EMG") {
    return <MuscleIcon fill={fill} title={title} />;
  } else if (signal === "ECG") {
    return <HeartIcon fill={fill} title={title} />;
  } else if (signal === "ACC") {
    return <AccIcon fill={fill} title={title} />;
  } else if (signal === "GYR") {
    return <GyrIcon fill={fill} title={title} />;
  } else if (signal === "BAT") {
    return <BatteryIcon fill={fill} title={title} />;
  } else if (signal === "LEAD_OFF") {
    return <LeadOffIcon fill={fill} title={title} />;
  } else if (signal === "EVENT") {
    return <EventIcon fill={fill} title={title} />;
  } else if (signal === "RESP") {
    return <LungIcon fill={fill} title={title} />;
  } else if (signal === "SPO2") {
    return <PPGIcon fill={fill} title={title} />;
  } else if (signal === "SYNC") {
    return <SyncIcon fill={fill} title={title} />;
  } else {
    return "";
  }
}
