import { useHistory, Link } from "react-router-dom";
import { Grid, Tooltip, Fab } from "@mui/material";
import { Add } from "@mui/icons-material";
import React, { useState } from "react";
import MaterialTable from "@material-table/core";
import { AddBleDeviceDialog } from "./AddBleDeviceDialog";

interface Props {}

export function BleDevicesList(props: Props) {
  const history = useHistory();
  const [devices, setDevices] = useState<any[]>([]);
  const [inProgress, setInProgress] = useState(false);
  const [addDeviceDialogOpen, setAddDeviceDialogOpen] = useState(false);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MaterialTable
            localization={{
              body: {
                emptyDataSourceMessage: `Listing SpotCheck devices is not implemented yet.`,
              },
            }}
            isLoading={inProgress}
            columns={[
              {
                title: "Device ID",
                field: "deviceI",
                render: (dock) => (
                  <Link to={`/groups/${dock.groupId}/docks/${dock.thingName}`}>
                    {dock.thingName}
                  </Link>
                ),
              },
            ]}
            data={devices}
            options={{
              selection: false,
              paging: false,
              showTitle: false,
              search: false,
              actionsColumnIndex: -1,
              filtering: false,
              exportMenu: [],
              padding: "dense",
            }}
            actions={[]}
          />
        </Grid>
      </Grid>

      <AddBleDeviceDialog
        open={addDeviceDialogOpen}
        onClose={() => {
          setAddDeviceDialogOpen(false);
        }}
      />
      <Tooltip title="Add a SpotCheck device" aria-label="add">
        <Fab
          sx={{
            position: "fixed",
            bottom: "15px",
            right: "15px",
          }}
          color="primary"
          aria-label="add"
          onClick={() => {
            setAddDeviceDialogOpen(true);
          }}
        >
          <Add />
        </Fab>
      </Tooltip>
    </>
  );
}
