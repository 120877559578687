import React from "react";

const svg = (props: any) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    fill="none"
    stroke="black"
    strokeMiterlimit={10}
    width={props.width || 20}
  >
    <title>{props.title || "Gyroscope"}</title>
    <path
      fill={props.fill || "none"}
      stroke={props.stroke || "black"}
      strokeWidth={3}
      d="M50.2,3.2C24.3,3.2,3.4,24.1,3.4,50s20.9,46.8,46.8,46.8S97,75.9,97,50c0,0,0,0,0,0C97,24.2,76,3.2,50.2,3.2z"
    />
    <line x1="50.3" y1="9.2" x2="50.3" y2="36.7" />
    <polyline points="46,10.5 50.4,7.8 54.7,10.6 " />
    <line x1="15.3" y1="71" x2="38.2" y2="57.3" />
    <polyline points="18.6,74 14.1,71.6 14.2,66.5 " />
    <line x1="85.1" y1="71.5" x2="62.3" y2="57.4" />
    <polyline points="86.3,67.1 86.2,72.2 81.6,74.5 " />
    <circle cx="50.3" cy="50.5" r="10.3" />
    <path d="M44,52.9c0.5,1.5,1.6,2.7,2.9,3.5" />
    <path d="M54.3,45.1c-3-2.2-7.2-1.5-9.4,1.5c-0.7,0.9-1.1,2-1.2,3.1" />
  </svg>
);

export default function GyrIcon(props: {
  width?: number;
  stroke?: string;
  fill?: string;
  title?: string;
}): JSX.Element {
  return svg(props);
}
