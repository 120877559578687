import React, { Component } from "react";
import { API } from "aws-amplify";
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Button,
  FormGroup,
  LinearProgress,
} from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import {
  IShadowResponse,
  IDockShadowReport,
  ISignal,
  ModeString,
} from "../../models/DockManagement";
import { ConfigurationEditor } from "./ConfigurationEditor";
import { ONE_CHANNEL_ECG } from "./ConfigurationProfiles";
import { IDockConfiguration } from "./DockConfigurationInterface";

interface IPathParams {
  dockName: string;
}

export const convertConfiguration = (
  configuration: IDockConfiguration
): Signals => {
  const configSignals: Signals = [];
  if (configuration.channelOneConfig !== "Disabled") {
    configSignals.push({
      type: configuration.channelOneConfig,
      fs: configuration.channelOneConfig === "RESP" ? 16 : 250,
      channel: 1,
    });
  }
  if (configuration.channelTwoConfig !== "Disabled") {
    configSignals.push({
      type: configuration.channelTwoConfig,
      fs: configuration.channelTwoConfig === "RESP" ? 16 : 250,
      channel: 2,
    });
  }
  if (configuration.accelerometerOn) {
    configSignals.push({ type: "ACC", fs: configuration.accelerometerFs });
  }
  if (configuration.gyroscopeOn) {
    configSignals.push({ type: "GYR", fs: configuration.gyroscopeFs });
  }
  return configSignals;
};

interface IDockConfigState extends IDockConfiguration {
  activeStep: Steps;
  retries: number;
  requestError: boolean;
  dotMemSize?: number;
}

enum Steps {
  SetupConfiguration = 0,
  SendConfiguration = 1,
  ConfigureSensorDots = 2,
}

type Signals = ISignal[];

export class DockConfig extends Component<
  RouteComponentProps<IPathParams>,
  IDockConfigState
> {
  private dockName: string;
  private FIBONACCI = [0, 1, 1, 2, 3, 5];

  constructor(props: RouteComponentProps<IPathParams>) {
    super(props);
    this.dockName = this.props.match.params.dockName;
    this.state = {
      activeStep: Steps.SetupConfiguration,
      channelOneConfig: ONE_CHANNEL_ECG.channelOneConfig,
      channelTwoConfig: ONE_CHANNEL_ECG.channelTwoConfig,
      accelerometerOn: ONE_CHANNEL_ECG.accelerometerOn,
      accelerometerFs: ONE_CHANNEL_ECG.accelerometerFs,
      gyroscopeOn: ONE_CHANNEL_ECG.gyroscopeOn,
      gyroscopeFs: ONE_CHANNEL_ECG.gyroscopeFs,
      retries: 0,
      requestError: false,
    };
  }

  public componentDidMount() {
    this.getShadowConfiguration();
  }

  public getShadowConfiguration() {
    API.get(
      "BytefliesCloudPlatformAPI",
      `docks/${this.dockName}/shadow`,
      {}
    ).then(
      (response: IShadowResponse) => {
        const shadow: IDockShadowReport = JSON.parse(response.shadow.payload);
        if (shadow?.state?.reported?.mode === ModeString.CONFIGURATION) {
          this.handleNext(Steps.SendConfiguration);
          for (const signal of shadow.state.reported.configSignals) {
            if (signal.type.startsWith("E")) {
              if (signal.channel === 1) {
                this.setState({ channelOneConfig: signal.type });
              } else if (signal.channel === 2) {
                this.setState({ channelTwoConfig: signal.type });
              }
            } else if (signal.type.toUpperCase() === "ACC") {
              this.setState({
                accelerometerOn: true,
                accelerometerFs: signal.fs,
              });
            } else if (signal.type.toUpperCase() === "GYR") {
              this.setState({ gyroscopeOn: true, gyroscopeFs: signal.fs });
            }
          }
        }
      },
      (rejected) => {
        console.log(rejected);
        this.goToError();
      }
    );
  }

  public render() {
    return (
      <div>
        <Typography variant="h6">
          These are the configuration steps for Docking Station{" "}
          {this.props.match.params.dockName}
        </Typography>

        <Stepper activeStep={this.state.activeStep} orientation="vertical">
          {this.getStepTitles().map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <div>{this.getStepContent(index)}</div>
                <div>
                  <div>
                    {[
                      Steps.SetupConfiguration,
                      Steps.ConfigureSensorDots,
                    ].includes(this.state.activeStep) && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          this.state.accelerometerOn === false &&
                          this.state.gyroscopeOn === false &&
                          this.state.channelOneConfig === "Disabled" &&
                          this.state.channelTwoConfig === "Disabled"
                        }
                        onClick={() => this.handleNext(this.state.activeStep)}
                      >
                        {this.state.activeStep ===
                        this.getStepTitles().length - 1
                          ? "Exit config"
                          : "Enable config"}
                      </Button>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {this.state.activeStep === this.getStepTitles().length && (
          <Paper square elevation={0}>
            {!this.state.requestError && (
              <Typography>
                The Docking Station is now out of configuration mode, you can
                configure more Sensor Dots if you click on 'Start Again'
              </Typography>
            )}
            {this.state.requestError && (
              <Typography color="error">
                Something went wrong while setting up the configuration, please
                try again later.
              </Typography>
            )}
            <Button onClick={() => this.handleReset()}>
              {this.state.requestError ? "Try Again" : "Start Again"}
            </Button>
          </Paper>
        )}
      </div>
    );
  }

  private handleNext(current: Steps) {
    if (current === Steps.SetupConfiguration) {
      const configuration = convertConfiguration(this.state);
      this.sendConfiguration(true, configuration);
      this.confirmConfigurationSet();
    } else if (current === Steps.ConfigureSensorDots) {
      const configuration = convertConfiguration(this.state);
      this.sendConfiguration(false, configuration);
    }
    this.setState({
      activeStep: current + 1,
    });
  }

  private getConfiguration(): Signals {
    return convertConfiguration(this.state);
  }

  private sendConfiguration(turnOn: boolean, configSignals: Signals) {
    try {
      const params = turnOn
        ? {
            mode: "configuration",
            configSignals,
          }
        : {
            mode: "normal",
          };
      API.put("BytefliesCloudPlatformAPI", `docks/${this.dockName}/config`, {
        body: params,
      });
    } catch (err) {
      console.log(err);
      this.goToError();
    }
  }

  private confirmConfigurationSet() {
    if (this.state.retries > this.FIBONACCI.length) {
      this.goToError();
      return;
    }

    API.get(
      "BytefliesCloudPlatformAPI",
      `docks/${this.dockName}/shadow`,
      {}
    ).then(
      (response: IShadowResponse) => {
        const shadow: IDockShadowReport = JSON.parse(response.shadow.payload);
        if (this.isExpectedConfig(shadow?.state?.reported?.configSignals)) {
          this.setState({ retries: 0 });
          this.handleNext(Steps.SendConfiguration);
        } else {
          this.setState({ retries: this.state.retries + 1 });
          setTimeout(
            () => this.confirmConfigurationSet(),
            this.FIBONACCI[
              Math.min(this.state.retries, this.FIBONACCI.length - 1)
            ] * 1000
          );
        }
      },
      (rejected) => {
        console.log(rejected);
        this.goToError();
      }
    );
  }

  private isExpectedConfig(signals: Signals): boolean {
    if (!signals) {
      return false;
    }

    let channelOneValid =
      this.state.channelOneConfig === "Disabled" ? true : false;
    let channelTwoValid =
      this.state.channelTwoConfig === "Disabled" ? true : false;
    let accValid = true;
    let gyrValid = true;

    for (const signal of signals) {
      if (signal.type.startsWith("E")) {
        if (signal.channel === 1) {
          channelOneValid = signal.type === this.state.channelOneConfig;
        } else if (signal.channel === 2) {
          channelTwoValid = signal.type === this.state.channelTwoConfig;
        }
      } else if (signal.type === "ACC") {
        accValid =
          accValid &&
          this.state.accelerometerOn &&
          signal.fs === this.state.accelerometerFs;
      } else if (signal.type === "GYR") {
        gyrValid =
          gyrValid &&
          this.state.gyroscopeOn &&
          signal.fs === this.state.gyroscopeFs;
      }
    }

    return accValid && gyrValid && channelOneValid && channelTwoValid;
  }

  private goToError() {
    if (this.state.requestError) {
      return;
    }
    this.setState({ requestError: true });
    this.handleNext(Steps.ConfigureSensorDots);
  }

  private handleReset() {
    this.setState({
      activeStep: Steps.SetupConfiguration,
      requestError: false,
      retries: 0,
    });
  }

  private getStepTitles() {
    return [
      "Setup your configuration",
      `Sending your configuration to ${this.dockName}`,
      "Configure your Sensor Dots",
    ];
  }

  public getStepContent(step: Steps): JSX.Element {
    switch (step) {
      case Steps.SetupConfiguration:
        return (
          <FormGroup className={"configSelect"}>
            <ConfigurationEditor
              defaultConfiguration={this.state}
              onChange={(event) => {
                console.log(event);
                this.setState({
                  channelOneConfig: event.channelOneConfig,
                  channelTwoConfig: event.channelTwoConfig,
                  accelerometerFs: event.accelerometerFs,
                  accelerometerOn: event.accelerometerOn,
                  gyroscopeFs: event.gyroscopeFs,
                  gyroscopeOn: event.gyroscopeOn,
                });
              }}
            />
          </FormGroup>
        );
      case Steps.SendConfiguration:
        return (
          <div>
            <Typography>
              Sending the configuration to {this.dockName}
            </Typography>
            <LinearProgress />
            {this.state.retries >= 4 && (
              <Typography color="error">
                We seem to be having trouble setting the configuration, is your
                Docking Station connected to the internet?
              </Typography>
            )}
          </div>
        );
      case Steps.ConfigureSensorDots:
        return (
          <div>
            <Paper elevation={2} className={"instructions"}>
              <ul>
                <li>Place a Sensor Dot on any slot of {this.dockName}</li>
                <li>The configuration command will be sent every 10s.</li>
                <li>
                  When configuration was successful, the Sensor Dot will keep
                  blinking Green rapidly until it is undocked. When
                  configuration fails, the LED will turn red and the Sensor Dot
                  will reboot.
                </li>
                <li>
                  On success, take the Sensor Dot out from the Docking Station,
                  and that one is done!
                </li>
              </ul>

              <Typography variant={"subtitle2"}>
                Note: the configuration will only be actually performed if the
                requested configuration is different from the current one, but
                in case checking that it is the same, the LED pattern will also
                indicate success.
              </Typography>
            </Paper>
          </div>
        );
      default:
        return <div>'Unknown step'</div>;
    }
  }
}
