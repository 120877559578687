import React, { Component, ChangeEvent } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  Grid,
  DialogActions,
} from "@mui/material";
import { Device } from "../../models/PatientRecord";
import { DeviceSelection, DeviceSelectionEvent } from "../dock/DeviceSelection";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { API } from "aws-amplify";

interface IDialogState {
  patient: string;
  startTime: Moment | undefined;
  endTime: Moment | undefined;
  devices: Device[];
  inProgress: boolean;
  failed: boolean;
  helperText: JSX.Element | string;
}

export interface IDockDialogProps {
  groupId?: string;
  groupName?: string;
  open: boolean;
  onClose: () => void;
}
export class AddPatientRecordDialog extends Component<
  IDockDialogProps,
  IDialogState
> {
  constructor(props: IDockDialogProps) {
    super(props);
    this.state = {
      patient: "",
      startTime: moment().startOf("day"),
      endTime: moment().add(1, "day").endOf("day"),
      devices: [] as Device[],
      inProgress: false,
      failed: false,
      helperText: " ",
    };
  }

  public render() {
    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          Add patient record to {this.props.groupName}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required={true}
                type={"patient"}
                label={"Patient Identifier"}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  this.handlePatientChange(event)
                }
                error={!this.patientOK()}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Start time"
                  value={this.state.startTime?.toDate()}
                  onChange={(date: Date | null) => {
                    const startTime = date
                      ? moment(date)
                      : moment().startOf("day");
                    this.setState({ startTime: startTime }, () => {});
                  }}
                  maxDate={this.state.endTime?.toDate()}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="dd/MM/yyyy"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="End time"
                  value={this.state.endTime?.toDate()}
                  onChange={(date: Date | null) => {
                    const endTime = date
                      ? moment(date)
                      : moment().add(1, "day").endOf("day");
                    this.setState({ endTime: endTime }, () => {});
                  }}
                  minDate={this.state.startTime?.toDate()}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="dd/MM/yyyy"
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={10}>
              <DeviceSelection
                groupId={this.props.groupId}
                onChange={(event: DeviceSelectionEvent) => {
                  this.setState({
                    devices: event.devices.map((device) => {
                      return { type: device.type, name: device.name } as Device;
                    }),
                  });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.onClose();
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.addPatientRecord()}
            disabled={!this.canSave()}
            color="primary"
          >
            {this.state.inProgress ? <CircularProgress /> : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private addPatientRecord() {
    const patientRecord = {
      patient: this.state.patient,
      startTime: this.state.startTime?.unix(),
      endTime: this.state.endTime?.unix(),
      devices: this.state.devices.map((d) => {
        return {
          type: d.type,
          name: d.name,
        };
      }),
    };
    if (patientRecord.patient === undefined) {
      console.error("Patient ID is undefined");
      return;
    }
    console.log(`groups/${this.props.groupId}/patientrecords`);
    console.log(patientRecord);

    this.setState({ inProgress: true });
    API.post(
      "BytefliesCloudPlatformAPI",
      `groups/${this.props.groupId}/patientrecords`,
      {
        header: { contentType: "application/json" },
        body: patientRecord,
      }
    ).then(
      (response) => {
        this.setState({
          failed: false,
          helperText: "",
          inProgress: false,
          patient: "",
          startTime: moment().startOf("day"),
          endTime: moment().add(1, "day").endOf("day"),
          devices: [],
        });
        this.props.onClose();
      },
      (rejected) => {
        const state = {
          failed: true,
          helperText: "",
          inProgress: false,
        };
        if (
          rejected.response.status === 400 ||
          rejected.response.status === 500
        ) {
          state.helperText = rejected.response.data.errorMessage;
        }
        this.setState(state);
      }
    );
  }

  private handlePatientChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      patient: e.currentTarget.value,
    });
  }

  private patientOK(): boolean {
    return !this.emptyString(this.state.patient);
  }

  private timesOK(): boolean {
    return (
      this.state.startTime !== undefined && this.state.endTime !== undefined
    );
  }

  private devicesOK(): boolean {
    return this.state.devices !== undefined && this.state.devices.length > 0;
  }

  private canSave(): boolean {
    if (this.state.inProgress) {
      return false;
    } else if (!this.patientOK()) {
      console.log("patient is not OK");
      return false;
    } else if (!this.devicesOK()) {
      console.log("devices is not OK");
      return false;
    } else if (!this.timesOK()) {
      console.log("times is not OK");
      return false;
    }
    console.log("OK to save");
    return true;
  }

  private emptyString(s: string): boolean {
    if (!s) {
      return true;
    } else {
      return false;
    }
  }
}
