import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  DialogActions,
} from "@mui/material";
import { Group } from "../../models/Group";
import {
  getAvailableDocks,
  getDocksOfGroup,
  addDockToGroup,
  deleteDockFromGroup,
} from "../../api/Docks";
import MaterialTable from "@material-table/core";

interface DockSelection {
  initialState: boolean;
  dockId: string;
  selected: boolean;
}

interface IDockDialogProps {
  group?: Group;
  open: boolean;
  onClose: () => void;
}
export function ManageDockDialog(props: IDockDialogProps) {
  const { group, open, onClose } = props;
  const [docks, setDocks] = useState<DockSelection[]>([]);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if (open === true && group !== undefined && docks.length === 0) {
      getGroupDocks(group);
    }
  }, [group, open, docks, setDocks, setInProgress]);

  const getGroupDocks = async (group: Group) => {
    console.log("getGroupDocks");
    if (group.id) {
      setInProgress(true);
      const docksFromGroup = (await getDocksOfGroup(group!.id)).map((id) => {
        return {
          selected: true,
          initialState: true,
          dockId: id,
        } as DockSelection;
      });
      const availableDocks = (await getAvailableDocks()).map((id) => {
        return {
          selected: false,
          initialState: false,
          dockId: id,
        } as DockSelection;
      });
      const allDocks = docksFromGroup
        .concat(availableDocks)
        .sort((d1, d2) => d1.dockId.localeCompare(d2.dockId));
      setDocks(allDocks);
      setInProgress(false);
    }
  };

  const addToGroup = async (dockId: string) => {
    if (group?.id) {
      await addDockToGroup(dockId, group?.id);
    }
  };

  const removeFromGroup = async (dockId: string) => {
    if (group?.id) {
      await deleteDockFromGroup(dockId, group?.id);
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">
        Manage Docking Stations for {group?.name}
      </DialogTitle>

      <DialogContent className="dockDialog">
        <MaterialTable
          localization={{
            body: {
              emptyDataSourceMessage: `No available Docking Stations found.`,
            },
          }}
          isLoading={inProgress}
          columns={[
            {
              title: "DockID",
              field: "dockId",
            },
          ]}
          data={docks}
          options={{
            selection: true,
            paging: false,
            showTitle: false,
            search: true,
            selectionProps: (dock: DockSelection) => ({
              checked: dock.selected,
            }),
            showTextRowsSelected: false,
            showSelectAllCheckbox: false,
            padding: "dense",
            maxBodyHeight: 600
          }}
          onSelectionChange={(_, dock: DockSelection | undefined) => {
            if (dock !== undefined) {
              dock.selected = !dock.selected;
              const newDocks = [...docks];
              const index = newDocks.findIndex((d) => d.dockId === dock.dockId);
              newDocks[index] = dock;
              setDocks(newDocks);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          color="secondary"
          disabled={inProgress}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          disabled={
            inProgress ||
            docks.filter((dock) => dock.initialState !== dock.selected)
              .length === 0
          }
          onClick={() => {
            setInProgress(true);
            const requests: Promise<any>[] = [];
            docks.forEach((dock) => {
              if (dock.initialState === false && dock.selected) {
                console.info(`About to add ${dock.dockId}`);
                requests.push(addToGroup(dock.dockId));
              } else if (dock.initialState === true && !dock.selected) {
                console.info(`About to remove ${dock.dockId}`);
                requests.push(removeFromGroup(dock.dockId));
              }
            });

            Promise.all(requests)
              .then(() => {
                getGroupDocks(group!);
              })
              .finally(() => {
                setInProgress(false);
              });
          }}
          color="primary"
          variant="outlined"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
