import { IDockConfiguration } from "./DockConfigurationInterface";

export interface IDockConfigurationProfile extends IDockConfiguration {
  title: string;
}

export const DEFAULT: IDockConfigurationProfile = {
  title: "Default",
  channelOneConfig: "EEG",
  channelTwoConfig: "EEG",
  accelerometerOn: true,
  accelerometerFs: 25,
  gyroscopeOn: false,
  gyroscopeFs: 0,
};

export const ONE_CHANNEL_ECG: IDockConfigurationProfile = {
  title: "One Channel ECG",
  channelOneConfig: "Disabled",
  channelTwoConfig: "ECG",
  accelerometerOn: true,
  accelerometerFs: 50,
  gyroscopeOn: false,
  gyroscopeFs: 0,
};

export const ONE_CHANNEL_ECG_WITH_GYRO: IDockConfigurationProfile = {
  title: "One Channel ECG with Gyroscope",
  channelOneConfig: "Disabled",
  channelTwoConfig: "ECG",
  accelerometerOn: true,
  accelerometerFs: 50,
  gyroscopeOn: true,
  gyroscopeFs: 25,
};

export const TWO_CHANNEL_ECG: IDockConfigurationProfile = {
  title: "Two Channel ECG",
  channelOneConfig: "ECG",
  channelTwoConfig: "ECG",
  accelerometerOn: true,
  accelerometerFs: 50,
  gyroscopeOn: false,
  gyroscopeFs: 0,
};

export const TWO_CHANNEL_ECG_WITH_GYRO: IDockConfigurationProfile = {
  title: "Two Channel ECG with Gyroscope",
  channelOneConfig: "ECG",
  channelTwoConfig: "ECG",
  accelerometerOn: true,
  accelerometerFs: 50,
  gyroscopeOn: true,
  gyroscopeFs: 25,
};

export const TWO_CHANNEL_EEG: IDockConfigurationProfile = {
  title: "Two Channel EEG",
  channelOneConfig: "EEG",
  channelTwoConfig: "EEG",
  accelerometerOn: true,
  accelerometerFs: 50,
  gyroscopeOn: false,
  gyroscopeFs: 0,
};

export const TWO_CHANNEL_EEG_WITH_GYRO: IDockConfigurationProfile = {
  title: "Two Channel EEG with Gyroscope",
  channelOneConfig: "EEG",
  channelTwoConfig: "EEG",
  accelerometerOn: true,
  accelerometerFs: 50,
  gyroscopeOn: true,
  gyroscopeFs: 25,
};

export const SEIZE_IT2_EEG: IDockConfigurationProfile = {
  title: "SeizeIT2-EEG",
  channelOneConfig: "EEG",
  channelTwoConfig: "EEG",
  accelerometerOn: true,
  accelerometerFs: 25,
  gyroscopeOn: true,
  gyroscopeFs: 25,
};

export function getConfigurationProfiles(): IDockConfigurationProfile[] {
  return [
    DEFAULT,
    ONE_CHANNEL_ECG,
    ONE_CHANNEL_ECG_WITH_GYRO,
    TWO_CHANNEL_ECG,
    TWO_CHANNEL_ECG_WITH_GYRO,
    TWO_CHANNEL_EEG,
    TWO_CHANNEL_EEG_WITH_GYRO,
    SEIZE_IT2_EEG,
  ];
}
