import React, { Component } from "react";
import { ListItemIcon, MenuItem, TextField } from "@mui/material";
import HeartIcon from "../../images/Icons/HeartIcon";
import BrainIcon from "../../images/Icons/BrainIcon";
import MuscleIcon from "../../images/Icons/MuscleIcon";
import EyeIcon from "../../images/Icons/EyeIcon";
import LungIcon from "../../images/Icons/LungIcon";
import { SignalType } from "../../models/DockManagement";

interface ISignalTypeSelectionProps {
  value: SignalType | "Disabled";
  onChange: (signalType: SignalType | "Disabled") => void;
}
interface ISignalTypeSelectionState {}
export class SignalTypeSelection extends Component<
  ISignalTypeSelectionProps,
  ISignalTypeSelectionState
> {
  constructor(props: ISignalTypeSelectionProps) {
    super(props);
    this.state = {
      signalType: props.value,
    };
  }

  private toSignalType(value: string | undefined): SignalType | "Disabled" {
    if (value === undefined || value === "Disabled") {
      return "Disabled";
    } else {
      return value as SignalType;
    }
  }

  public render() {
    return (
      <TextField
        fullWidth={true}
        select
        value={this.props.value}
        onChange={(e) => {
          const signalType = this.toSignalType(e.target.value);
          this.props.onChange(signalType);
        }}
      >
        <MenuItem value={"Disabled"}>
          <ListItemIcon></ListItemIcon>Disabled
        </MenuItem>
        <MenuItem value={"ECG"}>
          <ListItemIcon>
            <HeartIcon />
          </ListItemIcon>
          ECG
        </MenuItem>
        <MenuItem value={"EEG"}>
          <ListItemIcon>
            <BrainIcon />
          </ListItemIcon>
          EEG
        </MenuItem>
        <MenuItem value={"EMG"}>
          <ListItemIcon>
            <MuscleIcon />
          </ListItemIcon>
          EMG
        </MenuItem>
        <MenuItem value={"EOG"}>
          <ListItemIcon>
            <EyeIcon />
          </ListItemIcon>
          EOG
        </MenuItem>
        <MenuItem value={"RESP"}>
          <ListItemIcon>
            <LungIcon />
          </ListItemIcon>
          RESP
        </MenuItem>
      </TextField>
    );
  }
}
