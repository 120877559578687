import React, { useState } from "react";
import { Group } from "../../models/Group";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
  DialogContentText,
  Alert,
} from "@mui/material";
import { deleteGroup } from "../../api";

interface IDialogProps {
  group?: Group;
  open: boolean;
  onClose: () => void;
}
export function DeleteGroupDialog(props: IDialogProps) {
  const [inProgress, setInProgress] = useState(false);
  const { open, group, onClose } = props;

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="warning">
            Are you sure you want to delete this group? This action cannot be
            undone.
          </Alert>
        </DialogContentText>
        <TextField
          disabled={true}
          autoFocus
          margin="dense"
          name="id"
          label="Id"
          fullWidth
          value={group?.id || ""}
        />
        <TextField
          disabled={true}
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          fullWidth
          value={group?.name || ""}
        />
        <TextField
          disabled={true}
          inputProps={{
            maxLength: 140,
          }}
          multiline
          name="description"
          maxRows="3"
          margin="dense"
          label="Description"
          fullWidth
          value={group?.description || ""}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={inProgress}
          onClick={() => {
            onClose();
          }}
          color="secondary"
        >
          Cancel
        </Button>

        <Button
          disabled={
            inProgress ||
            group === undefined ||
            group.id === undefined ||
            group.id === ""
          }
          onClick={async () => {
            try {
              setInProgress(true);
              await deleteGroup(group!);
              onClose();
            } catch (error) {
              console.log(error);
            } finally {
              setInProgress(false);
            }
          }}
          color="primary"
        >
          {inProgress ? <CircularProgress /> : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
