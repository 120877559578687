import { API } from "@aws-amplify/api";
import {
  Card,
  CardContent,
  Grid,
  Breadcrumbs,
  Typography,
  TextField,
  Button,
  Snackbar,
  CardHeader,
} from "@mui/material";
import {
  GetRecordingResponse,
  RecordingProcessingStatus,
} from "../../../dist/openapi/recording-service";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { RouteComponentProps } from "react-router";
import { IRecordingRouteState } from "../../models/RecordingRouteState";
import { DateView } from "../utils/DateView";
import { SignalList } from "./SignalList";
import SaveIcon from "@mui/icons-material/Save";
import { ClipboardItem } from "./ClipboardItem";
import { Alert } from "@mui/material";
import { RecordingActionsButton } from "./RecordingActionsButton";
import { RecordingStatusIcon } from "./RecordingStatus";
import { ArrowForward } from "@mui/icons-material";

interface IPathParams {
  groupId: string;
  recordingId: string;
}

interface IRecordingViewState {
  recording: GetRecordingResponse | undefined;
  recordingStatus: RecordingProcessingStatus | undefined;
  apiStatus: string | undefined;
  inProgress: boolean;
  groupName: string;
  newPatient: string | undefined;
}

export class RecordingView extends Component<
  RouteComponentProps<IPathParams, {}, IRecordingRouteState>,
  IRecordingViewState
> {
  constructor(
    props: RouteComponentProps<IPathParams, {}, IRecordingRouteState>
  ) {
    super(props);

    let groupName: string;
    if (
      this.props.location.state &&
      this.props.location.state.groupName !== ""
    ) {
      groupName = this.props.location.state.groupName;
    } else {
      groupName = this.props.match.params.groupId;
      const groupId = this.props.match.params.groupId;

      API.get("BytefliesCloudPlatformAPI", `groups`, {}).then((response) => {
        if (response && response instanceof Array) {
          const found = response.find((element) => element.groupId === groupId);
          if (found) {
            this.setState({ groupName: found.groupName });
          }
        }
      });
    }
    this.state = {
      recording: undefined,
      recordingStatus: undefined,
      apiStatus: undefined,
      inProgress: false,
      groupName,
      newPatient: undefined,
    };
  }

  updatePatient = (event: any) => {
    this.setState({ newPatient: event.target.value });
  };

  savePatient = (event: any) => {
    API.put(
      "BytefliesCloudPlatformAPI",
      `groups/${this.props.match.params.groupId}/recordings/${this.props.match.params.recordingId}`,
      {
        header: { contentType: "application/json" },
        body: { patient: this.state.newPatient },
      }
    ).then(
      (_) => {
        this.setState({
          inProgress: false,
          newPatient: undefined,
        });
      },
      (rejected) => {
        const status = rejected.response?.status as number;
        this.setState({
          inProgress: false,
          apiStatus: this.statusToError(status),
        });
        console.log("failure");
      }
    );
  };

  public render() {
    if (this.state.inProgress) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      );
    } else if (this.state.recording !== undefined) {
      return (
        <Card style={{ padding: 15 }}>
          <CardHeader
            data-cy={`recording-${this.state.recording?.id}`}
            title={
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={`/groups`} color="inherit">
                  Groups
                </Link>
                <Typography color="textPrimary">
                  <Link
                    to={{
                      pathname: `/groups/${this.props.match.params.groupId}/recordings`,
                      search: this.props.location?.state?.queryParams,
                      state: {
                        groupName: this.state.groupName,
                      } as IRecordingRouteState,
                    }}
                    color="inherit"
                  >
                    {this.state.groupName}
                  </Link>
                </Typography>
                <Typography color="textPrimary" component="span">
                  <ClipboardItem
                    title={this.state.recording?.id}
                    label={`${this.state.recording?.id}..`}
                  />
                  &nbsp;
                  <RecordingStatusIcon
                    groupId={this.props.match.params.groupId}
                    recordingId={this.state.recording.id}
                    status={this.state.recordingStatus}
                    onRefresh={(recording) => {
                      if (recording !== undefined) {
                        this.setState({
                          recording: recording,
                          recordingStatus: recording.status,
                        });
                      }
                    }}
                  />
                </Typography>
              </Breadcrumbs>
            }
            action={
              <RecordingActionsButton
                groupId={this.props.match.params.groupId}
                recording={this.state.recording}
                onDelete={(groupId, _) => {
                  this.props.history.push(`/groups/${groupId}/recordings`, {
                    groupName: this.state.groupName,
                  } as IRecordingRouteState);
                }}
                onRetrigger={() => {
                  this.setState({ recordingStatus: "processing" });
                }}
              />
            }
          ></CardHeader>
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <Typography variant="body2" component="p">
                  <strong>Sensor&nbsp;Dot</strong>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body2" component="p">
                  <ClipboardItem
                    data-cy={`dot-id-${this.state.recording?.dotId}`}
                    title={this.state.recording?.dotId || ""}
                    label={this.state.recording?.dotId || ""}
                  />
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2" component="p">
                  <strong>Sensor&nbsp;Dot&nbsp;Firmware</strong>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body2" component="p">
                  {!this.state.recording?.firmwareVersion ||
                  this.state.recording?.firmwareVersion === ""
                    ? "Unknown"
                    : this.state.recording?.firmwareVersion}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2" component="p">
                  <strong>Docking&nbsp;Station</strong>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body2" component="p">
                  <ClipboardItem
                    title={this.state.recording?.dockName || ""}
                    label={this.state.recording?.dockName || ""}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2" component="p">
                  <strong>Patient</strong>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  size="small"
                  onChange={this.updatePatient}
                  style={{ fontSize: "small" }}
                  placeholder="Patient Identifier"
                  defaultValue={this.state.recording?.patient || ""}
                  inputProps={{ "aria-label": "Patient Identifier" }}
                />
                {this.state.newPatient !== undefined ? (
                  <Button
                    onClick={this.savePatient}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2" component="p">
                  <strong>Start&nbsp;Date</strong>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <ClipboardItem
                  title={String(this.state.recording?.startDate)}
                  label={`${DateView.toStringFullMillis(
                    new Date((this.state.recording?.startDate || 0) * 1000)
                  )}`}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2" component="p">
                  <strong>End&nbsp;Date</strong>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <ClipboardItem
                  title={String(
                    (this.state.recording?.startDate || 0) +
                      (this.state.recording?.duration || 0)
                  )}
                  label={`${DateView.toStringFullMillis(
                    new Date(
                      ((this.state.recording?.startDate || 0) +
                        (this.state.recording?.duration || 0)) *
                        1000
                    )
                  )}`}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2" component="p">
                  <strong>Upload&nbsp;Date</strong>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <ClipboardItem
                  title={String(this.state.recording?.uploadDate)}
                  label={`${DateView.toStringFullMillis(
                    new Date((this.state.recording?.uploadDate || 0) * 1000)
                  )}`}
                />
              </Grid>
              <Grid item xs={1}>
                <ArrowForward />
              </Grid>
              <Grid item xs={2}>
                <ClipboardItem
                  title={String(this.state.recording?.uploadEndDate)}
                  label={`${DateView.toStringFullMillis(
                    new Date((this.state.recording?.uploadEndDate || 0) * 1000)
                  )}`}
                />
              </Grid>
              <Grid item xs={4}></Grid>

              <Grid item xs={2}>
                <Typography variant="body2" component="p">
                  <strong>Duration</strong>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                {DateView.secondsToHms(this.state.recording?.duration || 0)}
              </Grid>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
              <Grid item xs={12}>
                <SignalList
                  groupId={this.props.match.params.groupId}
                  recordingId={this.state.recording?.id || ""}
                  signals={(this.state.recording?.signals as any[]) || []}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Snackbar
            open={this.state.apiStatus !== undefined}
            autoHideDuration={10000}
            onClose={() => {
              this.setState({ apiStatus: undefined });
            }}
          >
            <Alert
              severity="error"
              onClose={() => {
                this.setState({ apiStatus: undefined });
              }}
            >
              {this.state.apiStatus}
            </Alert>
          </Snackbar>
        </Card>
      );
    } else {
      return (
        <Alert severity="error">
          Something went wrong loading the recording. Please try again.
        </Alert>
      );
    }
  }

  public componentDidMount(): void {
    this.getRecording();
  }

  public statusToError(status: number | undefined): string | undefined {
    if (status === undefined) {
      return undefined;
    } else if (status === 401) {
      return "Not authorized.";
    } else if (status === 403) {
      return "Access denied.";
    } else if (status === 404) {
      return "Recording not found.";
    } else {
      return "Something went wrong. Please try again.";
    }
  }

  private getRecording() {
    this.setState({
      inProgress: true,
      apiStatus: undefined,
    });
    API.get(
      "BytefliesCloudPlatformAPI",
      `groups/${this.props.match.params.groupId}/recordings/${this.props.match.params.recordingId}`,
      {}
    ).then(
      (response) => {
        if (response) {
          const recording = response as GetRecordingResponse;
          this.setState({
            recording: recording,
            recordingStatus: recording?.status,
            inProgress: false,
            apiStatus: undefined,
          });
        }
      },
      (rejected) => {
        const status = rejected.response?.status as number;
        console.error(rejected);
        this.setState({
          inProgress: false,
          apiStatus: this.statusToError(status),
        });
      }
    );
  }
}
