import { useParams } from "react-router-dom";
import {
  Typography,
  Breadcrumbs,
  Grid,
  Alert,
  Button,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import {
  deleteDeviceFromDock,
  getDockStatus,
  getGroups,
  listDevicesOfDock,
} from "../../api";
import MaterialTable from "@material-table/core";
import { SystemUpdate } from "@mui/icons-material";
import { DeviceInfoDialog } from "./DeviceInfoDialog";
import {
  ListDevicesFromDockResponseBody,
  DeviceReference,
  GetDockStatusResponse,
} from "../../../dist/openapi/recording-service";
import { BleDeviceDialog } from "./BleDeviceDialog";
import { ClipboardItem } from "../recordings/ClipboardItem";
import { DateView } from "../utils/DateView";
interface IPathParams {
  groupId: string;
  dockId: string;
}

export function DockDetails() {
  const { groupId, dockId } = useParams<IPathParams>();
  const [apiError, setApiError] = useState<Error | undefined>();
  const [dockStatus, setDockStatus] = useState<GetDockStatusResponse>();
  const [linkedDevices, setLinkedDevices] =
    useState<ListDevicesFromDockResponseBody>();
  const [dockUpdateStatusOpen, setDockUpdateStatusOpen] = useState(false);
  const [addBleDeviceOpen, setAddBleDeviceOpen] = useState(false);
  const [groupName, setGroupName] = useState<string>();

  React.useEffect(() => {
    if (dockStatus === undefined) {
      getDockStatus(groupId, dockId)
        .then((status) => setDockStatus(status))
        .catch((e) => setApiError(e as Error));
    }
  }, [dockStatus, setDockStatus, groupId, dockId, setApiError]);

  React.useEffect(() => {
    if (groupId !== undefined || groupName === undefined) {
      getGroups()
        .then((response) => {
          if (response && response instanceof Array) {
            const found = response.find((element) => element.id === groupId);
            if (found) {
              setGroupName(found.name);
            }
          }
        })
        .catch((e) => setApiError(e as Error));
    }
  }, [groupId, setGroupName]);

  React.useEffect(() => {
    if (groupId === undefined || dockId === undefined) {
      return;
    }
    if (linkedDevices === undefined) {
      listDevicesOfDock(groupId, dockId)
        .then((response) => setLinkedDevices(response))
        .catch((e) => setApiError(e as Error));
    }
  }, [groupId, dockId, linkedDevices, setLinkedDevices, setApiError]);

  return (
    <>
      {apiError && (
        <Alert severity="error">
          Something went wrong loading the docking station status.
        </Alert>
      )}

      <DeviceInfoDialog
        dockId={dockId}
        open={dockUpdateStatusOpen}
        onClose={() => {
          setDockUpdateStatusOpen(false);
        }}
      />

      <BleDeviceDialog
        groupId={groupId}
        dockId={dockId}
        open={addBleDeviceOpen}
        onCancel={() => {
          setAddBleDeviceOpen(false);
        }}
        onDeviceAttached={(groupId, dockId, deviceId) => {
          setAddBleDeviceOpen(false);

          // update the device list
          const updatedDeviceList: ListDevicesFromDockResponseBody = {
            ...linkedDevices,
            devices: [...linkedDevices!.devices, { deviceId: deviceId }],
          };
          setLinkedDevices(updatedDeviceList);
        }}
      />

      <Grid container spacing={1}>
        <Grid item xs={12} data-cy={`dock-details-${dockId}`}>
          <Breadcrumbs aria-label="breadcrumb" style={{ padding: 15 }}>
            <Link to={`/groups`} color="inherit">
              Groups
            </Link>
            <Link to={`/groups/${groupId}/docks`} color="inherit">
              {groupName || groupId}
            </Link>
            <Link to={`/groups/${groupId}/docks`} color="inherit">
              Docks
            </Link>
            <Typography color="textPrimary">{dockId}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          {dockStatus?.status === "online" ? (
            <CloudDoneIcon color="primary" />
          ) : (
            <CloudOffIcon color="primary" />
          )}
        </Grid>

        <Grid item xs={3}>
          Firmware version:
        </Grid>
        <Grid item xs={9}>
          <Button
            onClick={() => {
              setDockUpdateStatusOpen(true);
            }}
          >
            {dockStatus?.firmwareVersion || ""}

            {dockStatus?.firmwareVersion !== undefined &&
              dockStatus?.firmwareVersion !== "" &&
              dockStatus?.desiredFirmwareVersion !== undefined &&
              dockStatus?.desiredFirmwareVersion !== "" &&
              dockStatus?.desiredFirmwareVersion !==
                dockStatus?.firmwareVersion && (
                <Tooltip
                  title={`Desired firmware version is ${dockStatus?.desiredFirmwareVersion}`}
                >
                  <SystemUpdate />
                </Tooltip>
              )}
          </Button>
        </Grid>
        <Grid item xs={3}>
          Desired firmware version:
        </Grid>
        <Grid item xs={9}>
          {dockStatus?.desiredFirmwareVersion}
        </Grid>

        <Grid item xs={12}>
          Dots
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            isLoading={dockStatus === undefined}
            columns={[
              {
                title: "Position",
                field: "position",
                filtering: false,
              },
              {
                title: "Dot ID",
                field: "dotId",
                render: (dotStatus) => (
                  <Link
                    to={`/realtime?groupId=${groupId}&dotId=${dotStatus.dotId}`}
                  >
                    {dotStatus.dotId}
                  </Link>
                ),
              },
              {
                title: "Last activity",
                field: "lastActivity",
              },
            ]}
            data={dockStatus?.dots || []}
            options={{
              selection: false,
              paging: false,
              showTitle: false,
              search: false,
              filtering: false,
              exportMenu: [],
              padding: "dense",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          Linked dots
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            isLoading={dockStatus === undefined}
            columns={[
              {
                title: "Dot ID",
                field: "dotId",
                render: (dotStatus) => (
                  <Link
                    to={`/realtime?groupId=${groupId}&dotId=${dotStatus.dotId}`}
                  >
                    {dotStatus.dotId}
                  </Link>
                ),
              },
            ]}
            data={dockStatus?.linkedDots || []}
            options={{
              selection: false,
              paging: false,
              showTitle: false,
              search: false,
              filtering: false,
              exportMenu: [],
              padding: "dense",
            }}
          />
        </Grid>

        <Grid item xs={10}>
          Attached SpotCheck devices
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => {
              setAddBleDeviceOpen(true);
            }}
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            isLoading={
              linkedDevices === undefined || linkedDevices.devices === undefined
            }
            columns={[
              {
                title: "Device ID",
                field: "deviceId",
                render: (device) => device.deviceId,
              },
              {
                title: "State",
                field: "state",
              },
              {
                title: "Last activity",
                field: "lastActivity",
                type: "datetime",
                render: (rowData) => (
                  <ClipboardItem
                    title={String(rowData.lastActivity)}
                    label={`${DateView.toString(
                      new Date((rowData.lastActivity || 0) * 1000)
                    )}`}
                  />
                ),
              },
            ]}
            data={linkedDevices?.devices || []}
            actions={[
              {
                icon: "delete",
                iconProps: { color: "primary" },
                tooltip: "Delete",
                onClick: async (_event, rowData) => {
                  try {
                    const device = rowData as DeviceReference;
                    if (device.deviceId === undefined) {
                      return;
                    }
                    await deleteDeviceFromDock(
                      groupId,
                      dockId,
                      device.deviceId
                    );

                    // update the device list
                    const updatedDeviceList: ListDevicesFromDockResponseBody = {
                      ...linkedDevices,
                      devices: linkedDevices!.devices.filter(
                        (d) => d.deviceId !== device.deviceId
                      ),
                    };
                    setLinkedDevices(updatedDeviceList);
                  } catch (e) {
                    setApiError(e as Error);
                  }
                },
              },
            ]}
            options={{
              selection: false,
              paging: false,
              showTitle: false,
              search: false,
              filtering: false,
              exportMenu: [],
              padding: "dense",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
