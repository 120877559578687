import { Button, Input, Paper } from "@mui/material";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import React, { Component } from "react";
import QRCode from "qrcode.react";

interface IMfaSetupState {
  code: string;
  user?: CognitoUser;
  mfatype?: "NOMFA" | "TOTP" | "SOFTWARE_TOKEN_MFA";
  verification: string;
}

export class MfaSetup extends Component<{}, IMfaSetupState> {
  constructor(props: any) {
    super(props);
    this.state = {
      code: "",
      user: undefined,
      mfatype: undefined,
      verification: "",
    };
  }

  private setupMfa() {
    Auth.setupTOTP(this.state.user).then((code) => {
      this.setState({ code });
    });
  }

  private disableMfa() {
    if (this.state.user) {
      Auth.setPreferredMFA(this.state.user, "NOMFA");
      this.setState({ mfatype: "NOMFA" });
    }
  }

  private getQRCode(code: string) {
    if (this.state.user && code) {
      const issuer = "Byteflies Cloud";
      const qrValue = `otpauth://totp/AWSCognito:${this.state.user.getUsername()}?secret=${code}&issuer=${issuer}`;

      return (
        <div>
          <Paper className={"container"}>
            Add the QR code to your authenticator
            <p />
            <QRCode value={qrValue} />
            <p />
            or enter it manually :{" "}
            <span className="breakword">{this.state.code}</span>
            <p />
            <div>
              verify :&nbsp;
              <Input
                name="challengeAnswer"
                value={this.state.verification}
                onChange={this.handleChange.bind(this)}
              />
            </div>
            <br />
            <div>
              <Button
                color={"secondary"}
                variant="contained"
                onClick={() => this.verifyMfa()}
              >
                Verify
              </Button>
            </div>
          </Paper>
        </div>
      );
    } else return "";
  }

  private handleChange(event: any) {
    this.setState({ verification: event.target.value });
  }

  private verifyMfa() {
    Auth.verifyTotpToken(this.state.user, this.state.verification).then(
      (response) => {
        Auth.setPreferredMFA(this.state.user, "TOTP").then((_) => {
          this.setState({ mfatype: "TOTP" });
        });
      }
    );
  }

  private getCurrentMfa(user: CognitoUser) {
    Auth.getPreferredMFA(user, {
      bypassCache: false,
    }).then((mfatype) => {
      if (
        mfatype === "SOFTWARE_TOKEN_MFA" ||
        mfatype === "TOTP" ||
        mfatype === "NOMFA"
      ) {
        this.setState({ user, mfatype });
      }
    });
  }

  public render() {
    return (
      <div>
        <Paper className={"container"}>
          {this.state.mfatype === "NOMFA" ? (
            !this.state.code ? (
              <Button
                color={"secondary"}
                variant="contained"
                onClick={() => this.setupMfa()}
              >
                Configure MFA
              </Button>
            ) : (
              this.getQRCode(this.state.code)
            )
          ) : (
            <div>
              MFA is successfully configured. <p />
              <Button
                color={"secondary"}
                variant="contained"
                onClick={() => this.disableMfa()}
              >
                Disable MFA
              </Button>
            </div>
          )}
        </Paper>
      </div>
    );
  }

  public componentDidMount(): void {
    Auth.currentAuthenticatedUser().then(
      (user: CognitoUser) => {
        this.getCurrentMfa(user);
      },
      (reject) => {
        console.error(reject);
      }
    );
  }
}
