import React from "react";
import { Amplify, Auth } from "aws-amplify";
import App from "./components/app/App";
import { createRoot } from "react-dom/client";
import process from "process";

Amplify.configure({
  Auth: {
    region: process?.env?.REACT_APP_COGNITO_REGION,
    userPoolId: process?.env?.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process?.env?.REACT_APP_COGNITO_WEB_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "BytefliesCloudPlatformAPI",
        endpoint: process?.env?.REACT_APP_API_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          };
        },
      },
    ],
  },
});

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
