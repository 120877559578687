import React, { useState } from "react";
import { API } from "aws-amplify";
import { Group } from "../../models/Group";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Fab,
  Tooltip,
  InputAdornment,
  Grid,
  TextField,
  LinearProgress,
} from "@mui/material";
import {
  Edit,
  PersonAdd,
  Add,
  Update,
  Search,
  Delete,
} from "@mui/icons-material";
import { ManageGroupDialog } from "./ManageGroupDialog";
import { ManageDockDialog } from "./ManageDockDialog";
import { ManageUserDialog } from "./ManageUserDialog";
import { ManageAutoUpdateDialog } from "./ManageAutoUpdateDialog";
import DockIcon from "../../images/Icons/DockIcon";
import { getAllDocks, DockApi } from "../../api";
import { Autocomplete } from "@mui/material";
import { DeleteGroupDialog } from "./DeleteGroupDialog";
import { useHistory } from "react-router-dom";
import { IRecordingRouteState } from "../../models/RecordingRouteState";

export function GroupManagement() {
  const [groups, setGroups] = useState<Group[]>([]);
  const [allDocks, setAllDocks] = useState<DockApi[]>([]);
  const [openManageGroupDialog, setOpenManageGroupDialog] = useState(false);
  const [openManageDockDialog, setOpenManageDockDialog] = useState(false);
  const [openManageUserDialog, setOpenManageUserDialog] = useState(false);
  const [openManageAutoUpdateDialog, setOpenManageAutoUpdateDialog] =
    useState(false);
  const [openDeleteGroupDialog, setOpenDeleteGroupDialog] = useState(false);
  const [currentGroup, setCurrentGroup] = useState<Group>();
  const [currentAction, setCurrentAction] = useState<
    "Create" | "Edit" | "Delete"
  >("Create");
  const history = useHistory();

  const getGroups = (dockId?: string) => {
    let path = "admin/groups";
    if (dockId !== undefined && dockId !== "") {
      path = `admin/groups?dockId=${dockId}`;
    }
    API.get("BytefliesCloudPlatformAPI", path, {})
      .then(
        (response: Group[]) => {
          response.sort((a: Group, b: Group) => {
            if (!a.name || !b.name) {
              return 0;
            } else {
              return a.name.localeCompare(b.name);
            }
          });
          setGroups(response);
        },
        (rejected) => {
          console.error(rejected);
          setGroups([]);
        }
      )
      .catch((error) => {
        console.error(error);
        setGroups([]);
      });
  };

  React.useEffect(() => {
    if (groups === undefined || groups.length === 0) {
      getGroups();
    }
  }, [groups, getGroups]);

  React.useEffect(() => {
    if (allDocks === undefined || allDocks.length === 0) {
      getAllDocks()
        .then((docks) => {
          setAllDocks(docks);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [allDocks, getAllDocks, setAllDocks]);

  return (
    <div>
      <Paper>
        <Grid container sx={{ p: 1 }}>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Autocomplete
              multiple={false}
              options={allDocks}
              autoHighlight
              getOptionLabel={(dock) => dock?.dockId || `${dock}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by Dock ID"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              onChange={(e, dock) => {
                if (dock === null) {
                  getGroups(undefined);
                } else {
                  getGroups(dock.dockId);
                }
              }}
            />
          </Grid>
        </Grid>
        <Table size="small" data-cy="groups">
          <TableHead>
            <TableRow>
              <TableCell>Group Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.length > 0 ? (
              groups.map((group) => {
                return (
                  <TableRow
                    key={group.id}
                    hover={true}
                    data-cy={`group-${group.id}`}
                  >
                    <TableCell style={{fontSize: 12, color: "#818181"}}>
                      {group.id}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        history.push(`/groups/${group.id}/docks`, {
                          groupName: group.name || "",
                        } as IRecordingRouteState);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {group.name}
                    </TableCell>
                    <TableCell>{group.description}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Update Group">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setCurrentAction("Edit");
                            setCurrentGroup(group);
                            setOpenManageGroupDialog(true);
                          }}
                        >
                          <Edit color="primary" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Add or remove users">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setCurrentGroup(group);
                            setOpenManageUserDialog(true);
                          }}
                        >
                          <PersonAdd color="primary" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Add or remove Docking Stations">
                        <IconButton
                          size="small"
                          aria-label="docks"
                          onClick={() => {
                            setCurrentGroup(group);
                            setOpenManageDockDialog(true);
                          }}
                        >
                          <DockIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Configure automatic updates">
                        <IconButton
                          size="small"
                          aria-label="docks"
                          onClick={() => {
                            setCurrentGroup(group);
                            setCurrentAction("Edit");
                            setOpenManageAutoUpdateDialog(true);
                          }}
                        >
                          <Update color="primary" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete Group">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setCurrentGroup(group);
                            setCurrentAction("Delete");
                            setOpenDeleteGroupDialog(true);
                          }}
                        >
                          <Delete color="primary" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow key={"empty"}>
                <TableCell colSpan={3}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
      <ManageGroupDialog
        open={openManageGroupDialog}
        actionTitle={currentAction}
        group={currentGroup || ({} as Group)}
        onClose={() => {
          getGroups();
          setOpenManageGroupDialog(false);
          setCurrentAction("Create");
          setCurrentGroup(undefined);
        }}
      />
      <DeleteGroupDialog
        open={openDeleteGroupDialog}
        group={currentGroup}
        onClose={() => {
          getGroups();
          setOpenDeleteGroupDialog(false);
          setCurrentAction("Create");
        }}
      />
      <ManageDockDialog
        open={openManageDockDialog}
        group={currentGroup}
        onClose={() => {
          setOpenManageDockDialog(false);
          setCurrentGroup(undefined);
        }}
      />
      <ManageUserDialog
        open={openManageUserDialog}
        group={currentGroup || ({} as Group)}
        onClose={() => {
          setOpenManageUserDialog(false);
          setCurrentGroup(undefined);
        }}
      />
      <ManageAutoUpdateDialog
        open={openManageAutoUpdateDialog}
        group={currentGroup}
        onClose={() => {
          setOpenManageAutoUpdateDialog(false);
          setCurrentGroup(undefined);
        }}
      />
      <Tooltip title="Create a new group" aria-label="add">
        <Fab
          sx={{
            position: "fixed",
            bottom: "15px",
            right: "15px",
          }}
          color="primary"
          aria-label="add"
          onClick={() => {
            setCurrentGroup(undefined);
            setCurrentAction("Create");
            setOpenManageGroupDialog(true);
          }}
        >
          <Add />
        </Fab>
      </Tooltip>
    </div>
  );
}
