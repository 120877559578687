import React from "react";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Logo } from "./dot.svg";

export default function DotIcon(props: {}) {
  return (
    <SvgIcon {...props}>
      <Logo />
    </SvgIcon>
  );
}
