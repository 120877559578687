import React from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import "./Realtime.css";
import Multi from "./Graph/Multi";
import { API, Auth } from "aws-amplify";
import {
  Typography,
  TextField,
  Button,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
  Checkbox,
  Alert,
} from "@mui/material";
import { EventMessage } from "./Events";

dayjs.extend(duration);

interface Props {}

export const getWsUrl = async () => {
  const events = await API.get("BytefliesCloudPlatformAPI", `ws-events`, {});
  const url = events.url as string;
  return url;
};

const Realtime: React.FC<Props> = (props: Props) => {
  // TODO BLE service not returning nicely in response to subscribe call of websocket API? 502 - timeout on axios?
  // TODO You now have to copy paste ID's before recording - how can we query the DotID's easily
  // TODO Dot ID should be in the message or packet, to know which do (if subscribed to multiple) it came from
  // TODO latency is 4-6 seconds consistently - should we reduce?

  const [error, setError] = React.useState("");
  const [websocketUrl, setWebsocketUrl] = React.useState("");
  const [websocket, setWebsocket] = React.useState<WebSocket | undefined>(
    undefined
  );
  const [eventLog, setEventLog] = React.useState<EventMessage[]>([]);
  const [websocketEventLog, setWebsocketEventLog] = React.useState<Event[]>([]);

  const [dotIds, setDotIds] = React.useState<string[]>([]);
  const [dotId, setDotId] = React.useState<string | undefined>(undefined); // temporary
  const [dockIds, setDocks] = React.useState<string[]>([]);
  const [groupIds, setGroupIds] = React.useState<string[]>([]);
  const [groupId, setGroupId] = React.useState<string | undefined>(undefined); // temporary
  const [eventTypes, setEventTypes] = React.useState<string[]>([]);

  const [subscribedToDotIds, setSubscribedToDotIds] = React.useState<string[]>(
    []
  );
  const [subscribedToDockIds, setSubscribedToDockIds] = React.useState<
    string[]
  >([]);
  const [subscribedToGroupIds, setSubscribedToGroupIds] = React.useState<
    string[]
  >([]);
  const [subscribedToEventTypes, setSubscribedToEventTypes] = React.useState<
    string[]
  >([]);

  const refreshWebsocketUrl = React.useCallback(async () => {
    const url = await getWsUrl().catch((e) =>
      console.error("Failed to get url", e)
    );
    setWebsocketUrl(url as string);
  }, [websocketUrl]);

  React.useEffect(() => {
    refreshWebsocketUrl().catch((e) => console.error("Failed to get url", e));
  }, [refreshWebsocketUrl]);

  React.useEffect(() => {
    const url: URL = new URL(window.location.href);
    const params: URLSearchParams = url.searchParams;
    const dotIdParam = params.get("dotId");
    if (dotIdParam !== undefined && dotIdParam !== null) {
      setDotId(dotIdParam);
    }
    const groupIdParam = params.get("groupId");
    if (groupIdParam !== undefined && groupIdParam !== null) {
      setGroupId(groupIdParam);
    }
  }, []);

  const refreshWebsocket = React.useCallback(async () => {
    setEventLog([]); // clean log
    setWebsocketEventLog([]); // clean log

    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const websocket = new WebSocket(websocketUrl + `?token=${token}`);

    websocket.onopen = (ev: Event) => {
      setWebsocketEventLog((prevValue) => prevValue.concat([ev]));
      setWebsocket(websocket);
    };

    websocket.onerror = (ev: Event) => {
      setWebsocketEventLog((prevValue) => prevValue.concat([ev]));
      setWebsocket(undefined);
    };

    websocket.onclose = (ev: Event) => {
      setWebsocketEventLog((prevValue) => prevValue.concat([ev]));
      setWebsocket(undefined);
    };
  }, [websocketUrl]);

  React.useEffect(() => {
    if (websocketUrl) {
      refreshWebsocket();

      return () => {
        if (websocket) {
          websocket.close(); // cleanup connection
        }
      };
    }
  }, [websocketUrl]);

  // Listen to websocket events
  React.useEffect(() => {
    const handleMessage = (ev: Event) => {
      setWebsocketEventLog((prevValue) => prevValue.concat([ev]));
    };

    if (websocket) {
      websocket.addEventListener("evt", handleMessage);
    }

    return () => {
      if (websocket) {
        websocket.removeEventListener("evt", handleMessage);
      }
    };
  }, [websocket]);

  // Listen to messages
  React.useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      let data: EventMessage;

      // skip empty messages
      if (!message.data || message.data === "") {
        return;
      }

      // parse
      try {
        data = JSON.parse(message.data);
      } catch {
        console.log("Invalid JSON");
        return;
      }

      // only display interesting ones
      const typesToIgnore = ["livestream"];
      if (!typesToIgnore.includes(data["detail-type"])) {
        setEventLog((prevValue) => prevValue.concat([data]));
      }
    };
    if (websocket) {
      websocket.addEventListener("message", handleMessage);
    }

    return () => {
      if (websocket) {
        websocket.removeEventListener("message", handleMessage);
      }
    };
  }, [websocket]);

  const subscribe = React.useCallback(() => {
    if (websocket) {
      const subscription = {
        action: "subscribe",
        "detail-type": eventTypes,
        detail: {
          dotId: dotIds,
          dockId: dockIds,
          groupId: groupIds,
        },
      };
      websocket.send(JSON.stringify(subscription));
    }
  }, [dotIds, dockIds, groupIds, eventTypes]);

  // Subscribe to the thing
  /*
  React.useEffect(() => {
    if (websocket) {
      subscribe();
    }
  }, [websocket]);
  */

  const handleDotId = (event: any) => {
    setDotId(event.target.value);
  };

  React.useEffect(() => {
    if (dotId) {
      setDotIds([dotId]);
    } else {
      setDotIds([]);
    }
  }, [dotId]);

  const handleGroupId = (event: any) => {
    setGroupId(event.target.value);
  };

  React.useEffect(() => {
    if (groupId) {
      setGroupIds([groupId]);
    } else {
      setGroupIds([]);
    }
  }, [groupId]);

  const handleEventTypes = (event: any) => {
    if (event.target.checked) {
      setEventTypes((prev) => prev.concat([event.target.name]));
    } else {
      setEventTypes((prev) =>
        prev.filter((value) => value !== event.target.name)
      );
    }
  };

  const validSubscription = React.useCallback(() => {
    return (groupId || dotId) && eventTypes.length;
  }, [dotId, groupId, eventTypes]);

  return (
    <div
      data-cy="realtime"
      style={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          margin: 7,
          gap: 7,
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 15,
            borderRadius: 10,
            boxShadow: "0px 0px 5px -3px",
          }}
        >
          <div
            style={{
              alignItems: "center",
              paddingLeft: 15,
              paddingRight: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 7,
                marginTop: 12,
                marginBottom: 12,
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1">Subscription</Typography>
              <Typography style={{ display: "inline" }} variant="subtitle2">
                (*Only a single DotID with livestream events are currently
                supported)
              </Typography>
            </div>
            <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
              <TextField
                id="filled-basic"
                label="GroupID"
                variant="filled"
                value={groupId}
                onChange={handleGroupId}
              />
              <TextField
                id="filled-basic"
                label="DockID"
                variant="filled"
                disabled
              />
              <TextField
                id="filled-basic"
                label="DotID*"
                variant="filled"
                value={dotId}
                onChange={handleDotId}
              />
              <FormControl
                sx={{ m: 1 }}
                component="fieldset"
                variant="standard"
              >
                <FormLabel component="legend">Event Types</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="docked"
                        checked={eventTypes.includes("docked")}
                        onChange={handleEventTypes}
                      />
                    }
                    label="Docked"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="undocked"
                        checked={eventTypes.includes("undocked")}
                        onChange={handleEventTypes}
                      />
                    }
                    label="Undocked"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eventTypes.includes("livestream")}
                        onChange={handleEventTypes}
                        name="livestream"
                      />
                    }
                    label="Livestream"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div style={{ textAlign: "right" }}>
              <Button
                disabled={!websocket || !validSubscription()}
                onClick={subscribe}
                variant="contained"
              >
                Subscribe
              </Button>
            </div>
            <div>
              {subscribedToDotIds.length ||
              subscribedToDockIds.length ||
              subscribedToGroupIds.length ? (
                <Typography variant="subtitle2">
                  Already subscribed to dots:{subscribedToDotIds}
                </Typography>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        data-cy="events"
        style={{
          display: "flex",
          margin: 7,
          gap: 7,
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 15,
            borderRadius: 10,
            boxShadow: "0px 0px 5px -3px",
          }}
        >
          <div
            data-cy="eventlog"
            style={{
              width: "100%",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                alignItems: "center",
                paddingLeft: 15,
                paddingRight: 5,
              }}
            >
              <Typography
                variant="subtitle1"
                style={{ marginTop: 12, marginBottom: 12 }}
              >
                Events
              </Typography>
              <div style={{ height: 150 }}>
                {!eventLog.length && (
                  <Alert
                    severity="info"
                    style={{
                      width: "fit-content",
                      margin: "auto",
                    }}
                  >
                    {"No events yet"}
                  </Alert>
                )}
                {eventLog.map((event: EventMessage, index: number) => (
                  <div style={{ fontSize: "small" }} key={index}>
                    {JSON.stringify(event)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-cy="livestream"
        style={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        {error && (
          <Alert severity="error" onClose={() => setError("")}>
            {error}
          </Alert>
        )}
        <Multi showACC={false} websocket={websocket} />
      </div>
      <div
        data-cy="websocket-events"
        style={{
          display: "flex",
          margin: 7,
          gap: 7,
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 15,
            borderRadius: 10,
            boxShadow: "0px 0px 5px -3px",
          }}
        >
          <div
            data-cy="eventlog"
            style={{
              width: "100%",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                alignItems: "center",
                paddingLeft: 15,
                paddingRight: 5,
              }}
            >
              <Typography
                variant="subtitle1"
                style={{ marginTop: 12, marginBottom: 12 }}
              >
                Websocket Events
              </Typography>
              <div style={{ height: 50 }}>
                {!websocketEventLog.length && (
                  <Alert
                    severity="info"
                    style={{
                      width: "fit-content",
                      margin: "auto",
                    }}
                  >
                    {"No websocket events yet"}
                  </Alert>
                )}
                {websocketEventLog.map((event: Event, index: number) => (
                  <div style={{ fontSize: "small" }} key={index}>
                    {JSON.stringify(event)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Realtime;
