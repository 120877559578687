import {
  Button,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Tabs,
  Tab,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GraphSelection } from "./GraphInterfaces";
import ACC from "./ACC";
import RAW from "./RAW";

import "./Multi.css";

interface Props {
  showACC: boolean;
  websocket?: WebSocket;
}

const Multi: React.FC<Props> = (props: Props) => {
  const [eegSelection, setEegSelection] = useState<GraphSelection>({
    start: 0,
    end: 10,
  });

  const [secondarySelection, setSecondarySelection] =
    useState<GraphSelection>();
  const [ch1Title, setCh1Title] = useState("");
  const [ch2Title, setCh2Title] = useState("");
  const [browserWarningOpen, setBrowserWarningOpen] = React.useState(false);

  useEffect(() => {
    console.log("Checking browser compatibility");
    const browser = (function () {
      const ua = navigator.userAgent;
      let tem;
      let M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: "IE", version: tem[1] || "" };
      }

      if (M[1] !== "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) {
          return { name: tem[1].replace("OPR", "Opera"), version: tem[2] };
        }
      }

      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }

      return { name: M[0], version: M[1] };
    })();
    if (browser.name !== "Chrome") {
      setBrowserWarningOpen(true);
    }
  }, []);

  const setSelection = React.useCallback((pointMS: number) => {
    setEegSelection({
      start: pointMS / 1000,
      end: pointMS / 1000 + 10,
    });
    setSecondarySelection({ start: pointMS, end: pointMS + 10000 });
  }, []);

  return (
    <div
      className="multi"
      style={{
        width: "100%",
        textAlign: "center",
        height: "100%",
      }}
    >
      <div>
        {1 + 1 == 1 && secondarySelection && (
          <div
            style={{
              display: "flex",
              margin: 7,
              gap: 7,
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 15,
                borderRadius: 10,
                boxShadow: "0px 0px 5px -3px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 5,
                  paddingRight: 5,
                  backgroundColor: "white",
                }}
              >
                {props.showACC && (
                  <Paper
                    style={{
                      marginTop: 12,
                      marginBottom: 4,
                      width: "fit-content",
                    }}
                  >
                    <Tabs
                      value={"acc"}
                      style={{
                        minHeight: 0,
                      }}
                      TabIndicatorProps={{
                        style: {
                          display: "none",
                        },
                      }}
                    >
                      <Tab
                        key="acc"
                        value="acc"
                        style={{
                          minHeight: 0,
                          padding: 0,
                          minWidth: 0,
                          textAlign: "left",
                        }}
                        label={
                          <Typography
                            variant="subtitle1"
                            style={{
                              display: "inline",
                              textTransform: "none",
                            }}
                          >
                            {"Accelerometer"}
                          </Typography>
                        }
                      />
                    </Tabs>
                  </Paper>
                )}
              </div>
              {1 + 1 === 1 && (
                <ACC
                  selection={secondarySelection}
                  onSelect={(start) => setSelection(start)}
                />
              )}
            </div>
          </div>
        )}
        <div>
          {eegSelection && (
            <RAW
              ch1Title={ch1Title}
              ch2Title={ch2Title}
              selection={eegSelection}
              onSelectionChange={(s) => {
                setSecondarySelection(s);
              }}
              websocket={props.websocket}
            />
          )}
        </div>
      </div>
      {browserWarningOpen && (
        <Dialog
          open={browserWarningOpen}
          onClose={() => {
            setBrowserWarningOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"We recommend using Google Chrome"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"We recommend using "}
              <Link href="https://www.google.com/chrome/">Google Chrome</Link>
              {
                " for this application as it will provide the smoothest experience for loading and visualizing all the data"
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setBrowserWarningOpen(false);
              }}
              color="primary"
              autoFocus
            >
              {"I understand"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Multi;
