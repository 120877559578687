import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { ChangeEvent, Component } from "react";

interface IPasswordFieldProps {
  id: string;
  enableToggleView: boolean;
  onToggle?: (hide: boolean) => void;
  label: string;
  hide: boolean;
  validateRequiredOnly: boolean;
  onChange: (password: string) => void;
  onValidate: (errors: JSX.Element[] | undefined) => void;
  helperTextErrors: JSX.Element[] | undefined;
}

interface IPasswordFieldState {
  showPassword: boolean;
  password: string;
}

export class PasswordField extends Component<
  IPasswordFieldProps,
  IPasswordFieldState
> {
  public static defaultProps = {
    enableToggleView: false,
    hide: false
  };

  constructor(props: IPasswordFieldProps) {
    super(props);
    this.state = {
      showPassword: false,
      password: ""
    };
  }

  public render() {
    return !this.props.hide && (
      <TextField
        id={this.props.id}
        required={true}
        className={"item"}
        variant="outlined"
        type={this.state.showPassword ? "text" : "password"}
        label={this.props.label}
        value={this.state.password}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          this.handleStateChange(event)
        }
        InputProps={
          this.props.enableToggleView
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => this.handleClickShowPassword()}
                      size="large">
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }
            : undefined
        }
        error={
          this.props.helperTextErrors &&
          this.props.helperTextErrors.length > 0
            ? true
            : false
        }
        helperText={this.props.helperTextErrors}
      />
    );
  }

  public updatePassword(newPassword: string) {
    this.setState({ password: newPassword });
  }

  private handleStateChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      password: e.currentTarget.value
    });
    this.validate(e.currentTarget.value);
    this.props.onChange(e.currentTarget.value);
  }

  private handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    });

    if (this.props.onToggle) {
      this.props.onToggle(!this.state.showPassword);
    }
  }

  private validate(passwordInput: string): void {
    const errors = [];

    const emptyPassword = this.validateRequired(passwordInput);
    if (emptyPassword) {
      errors.push(emptyPassword);
    }

    if (!this.props.validateRequiredOnly) {
      errors.push(...this.validateFull(passwordInput));
    }

    if (errors.length === 0) {
      this.props.onValidate(undefined);
    } else {
      errors.unshift(<div key="head">The {this.props.label} field must:</div>);
      this.props.onValidate(errors);
    }
  }

  private validateRequired(password: string): JSX.Element | null {
    if (!password) {
      return <div key="required">- not be empty</div>;
    } else {
      return null;
    }
  }

  private validateFull(password: string): JSX.Element[] {
    const errors = [];
    if (this.isNotOfValidLength(password)) {
      errors.push(<div key="length">- be at least 12 characters long</div>);
    }
    if (this.doesNotContainsLowerCase(password)) {
      errors.push(
        <div key="lowercase">- contain at least one LOWERCASE character</div>
      );
    }
    if (this.doesNotContainsUpperCase(password)) {
      errors.push(
        <div key="uppercase">- contain at least one UPPERCASE character</div>
      );
    }
    if (this.doesNotContainNumber(password)) {
      errors.push(<div key="number">- contain at least one NUMBER</div>);
    }
    if (this.doesNotContainSymbol(password)) {
      errors.push(
        <div key="symbols">
          - contain at least one special character ^$*.[]{}
          ()?-\"!@#%&amp;/\\,&gt;&lt;':;|_~`
        </div>
      );
    }
    return errors;
  }

  private doesNotContainSymbol(password: string) {
    return !/[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/.test(password);
  }

  private doesNotContainNumber(password: string) {
    return !/[0-9]/.test(password);
  }

  private doesNotContainsUpperCase(password: string) {
    return !/[A-Z]/.test(password);
  }

  private doesNotContainsLowerCase(password: string) {
    return !/[a-z]/.test(password);
  }

  private isNotOfValidLength(password: string) {
    return password.length < 12 && password.length >= 0;
  }
}
