import { Auth, CognitoUser } from "@aws-amplify/auth";
import { useParams, Link, useLocation } from "react-router-dom";
import { API } from "@aws-amplify/api";
import {
  Typography,
  Breadcrumbs,
  Grid,
  Alert,
  Autocomplete,
  TextField,
  InputAdornment,
} from "@mui/material";

import React, { useState } from "react";
import { DateView } from "../utils/DateView";
import { Admin } from "../admin/Admin";
import MaterialTable from "@material-table/core";
import { getDocksOfGroup, listDatapoints, SchemaName } from "../../api/Docks";
import { OmhDataPointResponseBody } from "../../../dist/openapi/recording-service";
import moment from "moment";
import { Search } from "@mui/icons-material";
import { IRecordingRouteState } from "../../models/RecordingRouteState";

interface IPathParams {
  groupId: string;
}

interface Props {}

export const dpCompareFn = (
  a: OmhDataPointResponseBody,
  b: OmhDataPointResponseBody
) => {
  if (!a.header || !b.header) {
    return 0;
  }
  const ahaps = a.header.acquisition_provenance?.source_creation_date_time;
  const bhaps = b.header.acquisition_provenance?.source_creation_date_time;
  if (ahaps && bhaps) {
    return bhaps.localeCompare(ahaps);
  }
  const ahc = a.header.creation_date_time;
  const bhc = b.header.creation_date_time;
  if (ahc && bhc) {
    return bhc.localeCompare(ahc);
  }
  return 0;
};

export function DatapointsList(props: Props) {
  const { groupId } = useParams<IPathParams>();
  const location = useLocation<IRecordingRouteState>();
  const [dockIds, setDockids] = useState<string[]>();
  const [dockId, setDockId] = useState<string>();
  const [types, setTypes] = useState<SchemaName[]>([
    "blood-pressure",
    "heart-rate",
    "body-weight",
    "body-temperature",
    "oxygen-saturation",
  ]);
  const [type, setType] = useState<SchemaName>("blood-pressure");
  const [apiError, setApiError] = useState<Error | undefined>();
  const [groupName, setGroupName] = useState<string>();
  const [datapoints, setDatapoints] = useState<OmhDataPointResponseBody[]>();
  const [inProgress, setInProgress] = useState(false);

  const loadDatapoints = (
    groupId: string,
    dockId: string,
    type: SchemaName
  ) => {
    setInProgress(true);
    const createdOnOfAfter = moment().subtract(1, "weeks").toDate();
    listDatapoints(groupId, dockId, createdOnOfAfter, type)
      .then((response) => {
        const sortedDatapoints = response.sort(dpCompareFn);
        setDatapoints(sortedDatapoints);
        setApiError(undefined);
      })
      .catch((error) => {
        console.error("Error getting datapoints", error);
        setApiError(error);
        setDatapoints([]);
      })
      .finally(() => {
        setInProgress(false);
      });
  };

  React.useEffect(() => {
    if (groupId !== undefined && groupName === undefined) {
      if (location.state && location.state.groupName) {
        setGroupName(location.state.groupName);
      } else {
        API.get("BytefliesCloudPlatformAPI", `groups`, {}).then((response) => {
          if (response && response instanceof Array) {
            const found = response.find(
              (element) => element.groupId === groupId
            );
            if (found) {
              setGroupName(found.groupName);
            }
          }
        });
      }
    }
  }, [groupId, groupName, setGroupName]);

  React.useEffect(() => {
    if (groupId !== undefined && dockIds === undefined) {
      getDocksOfGroup(groupId)
        .then((docks) => {
          setDockids(docks);
        })
        .catch((error) => {
          console.error("Error getting docks of group", error);
          setApiError(error);
          setDockids([]);
        });
    }
  }, [groupId, dockIds, setDockids, setApiError]);

  React.useEffect(() => {
    if (
      groupId !== undefined &&
      !inProgress &&
      dockId !== undefined &&
      type !== undefined
    ) {
      loadDatapoints(groupId, dockId, type);
    }
  }, [groupId, type, dockId]);

  return (
    <>
      {apiError && (
        <Alert severity="error">
          Something went wrong loading the datapoints.
        </Alert>
      )}

      <Grid container spacing={1}>
        {groupId !== undefined && (
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={{ padding: 15 }}>
              <Link to={`/groups`} color="inherit">
                Groups
              </Link>
              <Typography color="textPrimary">
                {groupName || groupId}
              </Typography>
            </Breadcrumbs>
          </Grid>
        )}

        <Grid item xs={3}>
          <Autocomplete
            multiple={false}
            options={dockIds || []}
            disableClearable={true}
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by Dock ID"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            onChange={(e, dock) => {
              console.log("dock", dock);
              if (dock !== null) {
                setDockId(dock);
              } else {
                setDockId(undefined);
              }
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            multiple={false}
            options={types}
            value={type || ""}
            inputValue={type || ""}
            disableClearable={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by Type"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            onChange={(e, t) => {
              if (t !== null && t !== undefined && dockId !== undefined) {
                setType(t);
              }
            }}
          />
        </Grid>

        <Grid item xs={6}></Grid>

        <Grid item xs={12}>
          <MaterialTable
            localization={{
              body: {
                emptyDataSourceMessage: `No Datapoints found.`,
              },
            }}
            isLoading={inProgress}
            columns={[
              {
                title: "ID",
                field: "id",
                render: (datapoint) => {
                  return datapoint.header.id;
                },
              },
              {
                title: "Source Timestamp",
                field: "timestamp",
                render: (datapoint) => {
                  const cd =
                    datapoint.header?.acquisition_provenance
                      ?.source_creation_date_time;
                  return cd || "";
                },
              },
              {
                title: "Type",
                field: "type",
                render: (datapoint) => {
                  return datapoint.header.schema_id!.name;
                },
              },
              {
                title: "Value",
                field: "value",
                render: (datapoint) => {
                  const body = datapoint.body;
                  if (
                    body.systolic_blood_pressure &&
                    body.diastolic_blood_pressure
                  ) {
                    const sysValue = body.systolic_blood_pressure.value;
                    const sysUnit = body.systolic_blood_pressure.unit;
                    const diaValue = body.diastolic_blood_pressure.value;
                    return `${sysValue}/${diaValue}  (${sysUnit})`;
                  }
                  if (body.body_weight) {
                    const value = body.body_weight.value;
                    const unit = body.body_weight.unit;
                    return `${value}  (${unit})`;
                  }
                  if (body.body_temperature) {
                    const value = body.body_temperature.value;
                    const unit = body.body_temperature.unit;
                    return `${value}  (${unit})`;
                  }
                  if (body.oxygen_saturation) {
                    const value = body.oxygen_saturation.value;
                    const unit = body.oxygen_saturation.unit;
                    return `${value}  (${unit})`;
                  }
                  if (body.heart_rate) {
                    const value = body.heart_rate.value;
                    const unit = body.heart_rate.unit;
                    return `${value}  (${unit})`;
                  }

                  return "";
                },
              },
            ]}
            data={datapoints || []}
            options={{
              selection: false,
              paging: false,
              showTitle: false,
              search: false,
              actionsColumnIndex: -1,
              filtering: false,
              exportMenu: [],
              padding: "dense",
            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                disabled:
                  inProgress ||
                  groupId === undefined ||
                  groupId === null ||
                  type === null ||
                  type === undefined ||
                  dockId === undefined ||
                  dockId === null,
                onClick: async () => loadDatapoints(groupId, dockId!, type!),
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
}
