import {
  Typography,
  Button,
  Paper,
  TextField,
  CircularProgress,
  Box,
} from "@mui/material";
import { Admin } from "../admin/Admin";
import React, { Component, ChangeEvent } from "react";
import { API } from "aws-amplify";
import { CheckCircle } from "@mui/icons-material";

interface IUserManagementState {
  email: string;
  errors: boolean;
  inProgress: boolean;
  failed: boolean;
  helperText: JSX.Element | string;
}

export class UserManagement extends Component<{}, IUserManagementState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      email: "",
      errors: true,
      inProgress: false,
      failed: false,
      helperText: "",
    };
  }

  public render() {
    return (
      <Admin>
        <Paper className={"container"} sx={{ p: 2 }}>
          <Typography variant="h6">Create User</Typography>
          <div>
            <Typography variant="caption">
              The user will get an automatic email with a temporary password for
              their first login. They will be prompted to change this password
              on their first login.
            </Typography>
          </div>
          <Box sx={{ display: "flex", gap: 2, paddingY: 1 }}>
            <TextField
              sx={{ minWidth: 300 }}
              variant="outlined"
              required={true}
              type={"email"}
              label={"Email"}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                this.handleStateChange(event)
              }
              error={this.state.errors || this.state.inProgress}
              helperText={this.state.helperText}
            />
            {this.state.inProgress && <CircularProgress />}
            <Button
              onClick={() => this.createUser()}
              disabled={this.state.errors}
              color="primary"
            >
              Create
            </Button>
          </Box>
        </Paper>
      </Admin>
    );
  }

  private handleStateChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      email: e.currentTarget.value,
    });
    this.validate(e.currentTarget.value);
  }

  private validate(emailInput: string): void {
    this.setState({
      errors: this.emptyEmail(emailInput) || !this.validEmail(emailInput),
      helperText: "",
    });
  }

  private validEmail(email: string): boolean {
    if (email.includes("@") && this.validDomain(email)) {
      return true;
    } else {
      return false;
    }
  }

  private validDomain(email: string): boolean {
    const domain = this.getLastElement(email.split("@"));
    if (
      this.isEmpty(domain) ||
      !domain.includes(".") ||
      this.hasEmptyNameServer(domain)
    ) {
      return false;
    }
    return true;
  }

  private isEmpty(domain: string): boolean {
    return !domain || domain === "";
  }

  private hasEmptyNameServer(domain: string): boolean {
    return this.getLastElement(domain.split(".")).length < 1;
  }

  private getLastElement(array: Array<string>): string {
    return array.slice(-1)[0];
  }

  private emptyEmail(email: string): boolean {
    return !email;
  }

  public createUser() {
    this.setState({ inProgress: true });
    API.post("BytefliesCloudPlatformAPI", "user", {
      header: { contentType: "application/json" },
      body: { email: this.state.email },
    }).then(
      (response) => {
        this.setState({
          errors: false,
          failed: false,
          helperText: (
            <div>
              <CheckCircle style={{ color: "#228B22" }} />
              {response.message}
            </div>
          ),
          inProgress: false,
        });
      },
      (rejected) => {
        const state = {
          errors: true,
          failed: true,
          helperText: "",
          inProgress: false,
        };
        if (
          rejected.response.status === 400 ||
          rejected.response.status === 500
        ) {
          state.helperText = rejected.response.data.errorMessage;
        }
        this.setState(state);
      },
    );
  }
}
