import { Button, Paper, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { PasswordField } from "./PasswordField";

interface IPasswordChangeState {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
  hideRepeat: boolean;
  oldPasswordErrors: JSX.Element[] | undefined;
  newPasswordErrors: JSX.Element[] | undefined;
  repeatNewPasswordErrors: JSX.Element[] | undefined;
  passwordChangeSuccess: boolean;
}

export class PasswordChange extends Component<{}, IPasswordChangeState> {
  private repeatNewPasswordFieldRef = React.createRef<PasswordField>();

  constructor(props: any) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      hideRepeat: false,
      oldPasswordErrors: undefined,
      newPasswordErrors: undefined,
      repeatNewPasswordErrors: undefined,
      passwordChangeSuccess: false,
    };
  }

  public render() {
    return (
      <div>
        {!this.state.passwordChangeSuccess && (
          <Paper className={"container"}>
            <Typography variant={"h6"} align={"center"}>
              Change Account Password
            </Typography>
            <PasswordField
              id="old_password"
              label={"Old Password"}
              enableToggleView={true}
              validateRequiredOnly={true}
              onChange={(password: string) => {
                this.handlePasswordChange("oldPassword", password);
              }}
              onValidate={(errors) => {
                this.setState({ oldPasswordErrors: errors });
              }}
              helperTextErrors={this.state.oldPasswordErrors}
            />
            <PasswordField
              id="new_password"
              label={"New Password"}
              enableToggleView={true}
              validateRequiredOnly={false}
              onToggle={(hide: boolean) => {
                this.setState({
                  hideRepeat: hide,
                  repeatNewPassword: this.state.newPassword,
                  repeatNewPasswordErrors: undefined,
                });

                const node = this.repeatNewPasswordFieldRef!.current;
                if (node) {
                  node.updatePassword(this.state.newPassword);
                }
              }}
              onChange={(password: string) => {
                this.handlePasswordChange("newPassword", password);
              }}
              onValidate={(errors) => {
                this.setState({ newPasswordErrors: errors });
              }}
              helperTextErrors={this.state.newPasswordErrors}
            />
            <PasswordField
              id="confirm_new_password"
              label={"Repeat New Password"}
              enableToggleView={false}
              hide={this.state.hideRepeat}
              ref={this.repeatNewPasswordFieldRef}
              validateRequiredOnly={true}
              onChange={(password: string) => {
                this.handlePasswordChange("repeatNewPassword", password);
              }}
              onValidate={(errors) => {
                this.setState({ repeatNewPasswordErrors: errors });
              }}
              helperTextErrors={this.state.repeatNewPasswordErrors}
            />
            <div className={"item"}>
              <Button
                color={"secondary"}
                variant="contained"
                onClick={() => this.changePassword()}
              >
                Change Password
              </Button>
            </div>
          </Paper>
        )}
        {this.state.passwordChangeSuccess && (
          <Paper className={"container"}>
            <Typography variant={"h6"} align={"center"}>
              Password Successfully Changed
            </Typography>
          </Paper>
        )}
      </div>
    );
  }

  private async changePassword() {
    if (this.isValidForm()) {
      const user = await Auth.currentAuthenticatedUser();
      try {
        const response = await Auth.changePassword(
          user,
          this.state.oldPassword,
          this.state.newPassword
        );
        if (response === "SUCCESS") {
          this.setState({ passwordChangeSuccess: true });
        }
      } catch (err) {
        const e = err as any;
        if (e && e.code === "NotAuthorizedException") {
          const errors = this.state.oldPasswordErrors
            ? (this.state.oldPasswordErrors as JSX.Element[])
            : [];
          if (errors.length === 0) {
            errors.push(<div key="wrong-pw">Old Password isn't valid</div>);
          }
          this.setState({ oldPasswordErrors: errors });
        }
      }
    }
  }

  private handlePasswordChange(
    key: keyof IPasswordChangeState,
    password: string
  ) {
    this.setState(this.updateState(key, password));
  }

  private isValidForm(): boolean {
    this.updateErrors("oldPassword", "oldPasswordErrors", "Old Password");
    this.updateErrors("newPassword", "newPasswordErrors", "New Password");
    this.updateErrors(
      "repeatNewPassword",
      "repeatNewPasswordErrors",
      "Repeat New Password"
    );
    this.validateEqualPasswords("Repeat New Password");

    return (
      this.state.oldPasswordErrors === undefined &&
      this.state.newPasswordErrors === undefined &&
      (this.state.repeatNewPasswordErrors === undefined ||
        this.state.hideRepeat)
    );
  }

  private validateEqualPasswords(field: string): void {
    if (
      this.state.newPassword !== this.state.repeatNewPassword &&
      !this.state.hideRepeat
    ) {
      const head = <div key="head">The {field} must:</div>;
      const secondOnly = (
        <div key="equal">- match with the New Password field</div>
      );
      this.appendError("repeatNewPasswordErrors", [head], secondOnly);
    }
  }

  private updateErrors(
    key: keyof IPasswordChangeState,
    errorKey: keyof IPasswordChangeState,
    field: string
  ) {
    if (!this.state[key]) {
      this.appendError(errorKey, [
        <div key="head">The {field} must:</div>,
        <div key="required">- not be empty</div>,
      ]);
    }
  }

  private appendError(
    errorKey: keyof IPasswordChangeState,
    whenEmpty: JSX.Element[],
    error?: JSX.Element
  ) {
    const errors = this.state[errorKey]
      ? (this.state[errorKey] as JSX.Element[])
      : [];
    if (errors.length === 0) {
      errors.push(...whenEmpty);
    }
    if (error && errors.filter((err) => err.key === error.key).length === 0) {
      errors.push(error);
    }
    this.setState(this.updateState(errorKey, errors));
  }

  private updateState<T>(key: keyof IPasswordChangeState, value: T) {
    return (prevState: IPasswordChangeState): IPasswordChangeState => ({
      ...prevState,
      [key]: value,
    });
  }
}
