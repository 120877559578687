import React, { ChangeEvent, useEffect, useState } from "react";
import { Group } from "../../models/Group";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { API } from "aws-amplify";
import { StringMappingType } from "typescript";

export interface IDialogProps {
  group: Group;
  open: boolean;
  actionTitle: "Create" | "Edit" | "Delete";
  onClose: () => void;
}
export function ManageGroupDialog(props: IDialogProps) {
  const { open, actionTitle, onClose, group } = props;
  const [id, setId] = useState<string>();
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [inProgress, setInProgress] = useState(false);

  React.useEffect(() => {
    if (open) {
      setId(group.id);
      setName(group.name);
      setDescription(group.description);
    } else {
      setId(undefined);
      setName(undefined);
      setDescription(undefined);
    }
  }, [open, setId, setName, setDescription, group]);

  const saveDisabled = (): boolean => {
    if (
      name === "" ||
      validateGroupDescription(description) !== undefined ||
      inProgress
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateGroupDescription = (
    description: string | undefined
  ): string | undefined => {
    if (containsNewline(description)) {
      return "Description should not contain a newline character";
    } else {
      return undefined;
    }
  };

  const containsNewline = (s: string | undefined) => {
    if (s === undefined) {
      return false;
    } else if (s.indexOf("\n") !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const putGroup = async (group: Group) => {
    await API.put("BytefliesCloudPlatformAPI", "groups", {
      body: group,
    });
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {actionTitle.concat(" Group")}
      </DialogTitle>
      <DialogContent>
        {id && (
          <TextField
            disabled={true}
            autoFocus
            margin="dense"
            name="id"
            label="Id"
            required={false}
            fullWidth
            value={id}
          />
        )}

        <TextField
          disabled={inProgress}
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          required={true}
          fullWidth
          value={name}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setName(value);
          }}
        />

        <TextField
          disabled={inProgress}
          inputProps={{
            maxLength: 140,
          }}
          multiline
          name="description"
          maxRows="3"
          margin="dense"
          label="Description"
          error={validateGroupDescription(description) !== undefined}
          helperText={validateGroupDescription(description) || undefined}
          fullWidth
          value={description}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setDescription(value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={inProgress}
          onClick={() => {
            onClose();
          }}
          color="secondary"
          variant="outlined"
        >
          Cancel
        </Button>

        <Button
          disabled={saveDisabled()}
          onClick={async () => {
            setInProgress(true);
            try {
              const g: Group = {
                id: id,
                name: name,
                description: description,
              };
              await putGroup(g);
              onClose();
            } catch (error) {
              console.log(error);
            } finally {
              setInProgress(false);
            }
          }}
          color="primary"
          variant="outlined"
        >
          {inProgress ? <CircularProgress /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
