import React from "react";

const svg = (props: any) => (
  <svg
    width={props.width || "20"}
    height={props.height || "20"}
    version="1.1"
    viewBox="0 0 82.553 82.553"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.title || "Lead-off"}</title>
    <g transform="translate(29.277 29.277)">
      <path d="m0 0h24v24h-24z" fill="none" />
    </g>
    <g transform="translate(27.319 29.107)">
      <g
        transform="matrix(2.963 0 0 2.4696 -21.598 -17.466)"
        strokeWidth=".36968"
      >
        <path
          d="m20 5v-1c0-0.55-0.45-1-1-1h-2c-0.55 0-1 0.45-1 1v1h-1v4c0 0.55 0.45 1 1 1h1v7c0 1.1-0.9 2-2 2s-2-0.9-2-2v-10c0-2.21-1.79-4-4-4s-4 1.79-4 4v7h-1c-0.55 0-1 0.45-1 1v4h1v1c0 0.55 0.45 1 1 1h2c0.55 0 1-0.45 1-1v-1h1v-4c0-0.55-0.45-1-1-1h-1v-7c0-1.1 0.9-2 2-2s2 0.9 2 2v10c0 2.21 1.79 4 4 4s4-1.79 4-4v-7h1c0.55 0 1-0.45 1-1v-4z"
          strokeWidth=".36968"
        />
      </g>
      <path
        d="m13.958-27.831c-22.094 0-40 17.906-40 40 0 22.094 17.906 40 40 40 22.094 0 40-17.906 40-40 0-22.094-17.906-40-40-40z"
        clipRule="evenodd"
        fill={props.fill || "none"}
        stroke={props.stroke || "black"}
        strokeMiterlimit="10"
        strokeWidth="3"
      />
    </g>
  </svg>
);

export default function LeadOffIcon(props: {
  width?: number;
  height?: number;
  stroke?: string;
  fill?: string;
  title?: string;
}): JSX.Element {
  return svg(props);
}
