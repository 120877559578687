import React, { useState } from "react";
import { MenuItem, TextField } from "@mui/material";

interface ISampleRateSelectionProps {
  value: number;
  onSampleRateChange: (enabled: boolean, sampleRate: number) => void;
}
export function SampleRateSelection(props: ISampleRateSelectionProps) {
  const { value, onSampleRateChange } = props;
  const [sampleRate, setSampleRate] = useState(value);

  React.useEffect(() => {
    if (sampleRate !== value) {
      setSampleRate(value);
    }
  }, [value, sampleRate, setSampleRate]);

  return (
    <TextField
      fullWidth={true}
      select
      value={sampleRate}
      onChange={(event) => {
        const sampleRate = +event.target.value;
        setSampleRate(sampleRate);
        onSampleRateChange(sampleRate !== 0, sampleRate);
      }}
    >
      <MenuItem value={0}>Disabled</MenuItem>
      <MenuItem value={25}>25Hz</MenuItem>
      <MenuItem value={50}>50Hz</MenuItem>
      <MenuItem value={100}>100Hz</MenuItem>
      <MenuItem value={200}>200Hz</MenuItem>
    </TextField>
  );
}
