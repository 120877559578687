import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  TextField,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  FormGroup,
  Divider,
  TableHead,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import { Group } from "../../models/Group";
import { API } from "aws-amplify";
import { CognitoUser } from "../../models/CognitoUser";

export interface IUserDialogProps {
  group: Group;
  open: boolean;
  onClose: () => void;
}
export function ManageUserDialog(props: IUserDialogProps) {
  const { group, open, onClose } = props;
  const [users, setUsers] = useState<CognitoUser[]>([]);
  const [allUsers, setAllUsers] = useState<CognitoUser[]>([]);
  const [usersToAdd, setUsersToAdd] = useState<CognitoUser[]>([]);
  const [inProgress, setInProgress] = useState(false);

  const getAllUsers = () => {
    API.get("BytefliesCloudPlatformAPI", "users", {}).then(
      (response) => {
        if (response) {
          setAllUsers(response);
        }
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  };

  const getGroupUsers = (group: Group) => {
    if (group?.id) {
      API.get(
        "BytefliesCloudPlatformAPI",
        `groups/${group?.id}/users`,
        {}
      ).then(
        (response) => {
          if (response) {
            setUsers(response);
          }
        },
        (rejected) => {
          console.log(rejected);
        }
      );
    }
  };

  const addMultipleUsers = () => {
    setInProgress(true);
    const requests: Promise<any>[] = [];
    usersToAdd.forEach((element) => {
      requests.push(addToGroup(element.id));
    });
    Promise.all(requests).then(() => {
      setInProgress(false);
      setUsersToAdd([]);
      getGroupUsers(group);
    });
  };

  const addToGroup = (userid: string): Promise<any> => {
    return API.put(
      "BytefliesCloudPlatformAPI",
      `groups/${group?.id}/users/${userid}`,
      {}
    ).then(
      () => {
        console.log("added.");
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  };

  const removeUserFromGroup = (userid: string) => {
    API.del(
      "BytefliesCloudPlatformAPI",
      `groups/${group?.id}/users/${userid}`,
      {}
    ).then(
      () => {
        console.log("deleted.");
        getGroupUsers(group);
      },
      (rejected) => {
        console.log(rejected);
      }
    );
  };

  React.useEffect(() => {
    if (users === undefined || users.length === 0) {
      getAllUsers();
    }
  }, [users, setInProgress]);

  React.useEffect(() => {
    if (group?.id !== undefined) {
      getGroupUsers(group);
    }
  }, [getGroupUsers]);

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h6">Manage Users for {group?.name}</Typography>

        <FormGroup row={true} className="adderForm"
                   style={{width: "100%", gap: 12, margin: "16px 0"}}
        >
          <Autocomplete
            className="selecter"
            multiple
            noOptionsText={"No available users"}
            onChange={(event, value) => setUsersToAdd(value)}
            value={usersToAdd}
            options={allUsers.filter(
              (value) => !users.some((e) => e.id === value.id)
            )}
            getOptionLabel={(option) => option.email}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Select one or multiple Users"
                variant="outlined"
              />
            )}
            style={{flexGrow: "3"}}

          />
          <Button
            disabled={inProgress}
            onClick={() => {
              addMultipleUsers();
            }}
            color="primary"
            variant="outlined"
          >
            {inProgress ? <CircularProgress /> : "Add"}
          </Button>
        </FormGroup>
      </DialogTitle>
      <DialogContent className="dockDialog">
        <Paper>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  Current Users in {group?.name}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.length > 0 &&
                users.map((item) => {
                  return (
                    <TableRow key={item.id} hover={true}>
                      <TableCell style={{ width: "90%" }}>
                        {item.email}
                      </TableCell>
                      <TableCell padding="none" align="center" size="small">
                        <IconButton
                          size="small"
                          disabled={inProgress}
                          onClick={() => {
                            removeUserFromGroup(item.id);
                          }}
                        >
                          <Delete color="primary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={inProgress}
          onClick={() => {
            onClose();
          }}
          color="secondary"
          variant="outlined"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
