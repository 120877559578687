export class DateView {
  public static toString(date: Date) {
    if (date && !isNaN(date.getTime())) {
      return `${DateView.getYear(date)}-${DateView.getMonth(
        date
      )}-${DateView.getDate(date)}  ${DateView.getHours(
        date
      )}:${DateView.getMinutes(date)}`;
    } else {
      return "";
    }
  }

  private static timestampToDate(timestamp: number): Date {
    const s = `${timestamp}`;

    const d = new Date(0);
    if (Number.isInteger(timestamp) && s.length === 19) {
      // epoch in nanoseconds
      d.setUTCMilliseconds(timestamp / 1000000);
    } else if (Number.isInteger(timestamp) && s.length === 13) {
      // epoch in milliseconds
      d.setUTCMilliseconds(timestamp);
    } else if (Number.isInteger(timestamp) && s.length === 10) {
      // epoch in seconds
      d.setUTCSeconds(timestamp);
    } else if (DateView.isFloat(timestamp)) {
      d.setUTCSeconds(timestamp);
    } else {
      d.setUTCMilliseconds(timestamp);
    }
    return d;
  }

  private static isFloat(n: number) {
    return Number(n) === n && n % 1 !== 0;
  }

  public static timestampToStringFull(ts: number) {
    return DateView.toStringFull(this.timestampToDate(ts));
  }

  public static toStringFull(date: Date) {
    if (date && !isNaN(date.getTime())) {
      return `${date.getFullYear()}-${DateView.getMonth(
        date
      )}-${DateView.getDate(date)}  ${DateView.getHours(
        date
      )}:${DateView.getMinutes(date)}:${DateView.getSeconds(date)}`;
    } else {
      return "";
    }
  }

  public static toStringFullMillis(date: Date) {
    if (date && !isNaN(date.getTime())) {
      return `${date.getFullYear()}-${DateView.getMonth(
        date
      )}-${DateView.getDate(date)}  ${DateView.getHours(
        date
      )}:${DateView.getMinutes(date)}:${DateView.getSeconds(
        date
      )}.${DateView.getMilliseconds(date)}`;
    } else {
      return "";
    }
  }

  private static pad(n: string, width: number, z: string) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  public static secondsToHms(d: number) {
    if (isNaN(d)) return "";
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    if (d > 3600) {
      return (h > 0 ? h + "h" : "") + this.pad("" + m, 2, "0") + "m";
    } else if (d > 60) {
      return m + "m" + this.pad("" + s, 2, "0");
    } else {
      return s + " sec";
    }
  }

  private static getYear(date: Date) {
    return date.getFullYear().toString();
  }
  private static getDate(date: Date) {
    return `${date.getDate()}`.padStart(2, "0");
  }

  private static getSeconds(date: Date) {
    return `${date.getSeconds()}`.padStart(2, "0");
  }

  private static getMilliseconds(date: Date) {
    return `${date.getMilliseconds()}`.padStart(3, "0");
  }

  private static getMonth(date: Date) {
    return `${date.getMonth() + 1}`.padStart(2, "0");
  }

  private static getHours(date: Date) {
    return `${date.getHours()}`.padStart(2, "0");
  }

  private static getMinutes(date: Date) {
    return `${date.getMinutes()}`.padStart(2, "0");
  }
}
