import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  Grid,
  DialogActions,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import { putRecording } from "../../api/Recording";
import {
  SignalResponse,
  Location,
  LocationBipolar,
  LocationLead,
  LocationLabeled,
  PutRecordingRequest,
} from "../../models/Recording";
import { Autocomplete } from "@mui/material";

export enum TabValues {
  PosNeg,
  Lead,
  Manual,
}
interface ILocationDialogState {
  temporaryLocation: Location | undefined;
  currentTab: TabValues;
  inProgress: boolean;
  failed: boolean;

  helperText: JSX.Element | string;
}

export interface ILocationDialogProps {
  groupId: string;
  recordingId: string;
  signal: SignalResponse;
  defaultType: TabValues;
  open: boolean;
  posNegEnabled: boolean;
  leadEnabled: boolean;
  onClose: () => void;
}

export class SignalLocationDialog extends Component<
  ILocationDialogProps,
  ILocationDialogState
> {
  constructor(props: ILocationDialogProps) {
    super(props);
    this.state = {
      temporaryLocation: undefined,
      currentTab: props.defaultType,
      inProgress: false,
      failed: false,
      helperText: " ",
    };
  }

  signalToState(
    signal: SignalResponse | undefined,
    defaultType: TabValues
  ): ILocationDialogState {
    const newState = {} as ILocationDialogState;

    const location = signal?.location;
    const posLead = location as LocationLead;
    const posPn = location as LocationBipolar;

    if (posPn && posPn.positive && posPn.negative) {
      newState.temporaryLocation = {
        positive: posPn.positive,
        negative: posPn.negative,
      };
      newState.currentTab = TabValues.PosNeg;
    } else if (posLead && posLead.lead) {
      newState.temporaryLocation = { lead: posLead.lead };
      newState.currentTab = TabValues.Lead;
    } else {
      const l = location as LocationLabeled;
      newState.temporaryLocation = { label: l?.label || "" };
      newState.currentTab = defaultType;
    }
    return newState;
  }

  componentDidUpdate(prevProps: ILocationDialogProps) {
    if (prevProps.signal?.id !== this.props.signal?.id) {
      const newState = this.signalToState(
        this.props.signal,
        this.props.defaultType
      );

      this.setState({
        currentTab: newState.currentTab,
        temporaryLocation: newState.temporaryLocation,
      });
      console.log(this.state);
    }
  }

  public render() {
    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          Signal location ({this.props.signal?.type})
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
            }}
          >
            <FormControl component="fieldset">
              <RadioGroup name="location-type" value={this.state.currentTab}>
                {this.props.posNegEnabled && (
                  <FormControlLabel
                    value={TabValues.PosNeg}
                    control={
                      <Radio
                        onChange={() => {
                          this.setState({ currentTab: TabValues.PosNeg });
                        }}
                      />
                    }
                    label="BIPOLAR"
                  />
                )}
                {this.props.leadEnabled && (
                  <FormControlLabel
                    value={TabValues.Lead}
                    control={
                      <Radio
                        onChange={() => {
                          this.setState({ currentTab: TabValues.Lead });
                        }}
                      />
                    }
                    label="LEAD"
                  />
                )}
                <FormControlLabel
                  value={TabValues.Manual}
                  control={
                    <Radio
                      onChange={() => {
                        this.setState({ currentTab: TabValues.Manual });
                      }}
                    />
                  }
                  label="MANUAL"
                />
              </RadioGroup>
              <FormHelperText>Location type</FormHelperText>
            </FormControl>

            <Box width={1} p={3} height={1}>
              {this.state.currentTab === TabValues.PosNeg && (
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={12}>
                    For a 2-channel signal, select a positive and negative
                    position
                  </Grid>
                  <Grid item xs={5}>
                    <ChannelSelection
                      label="Positive"
                      value={
                        (this.state.temporaryLocation as LocationBipolar)
                          ?.positive
                      }
                      onChannelChange={(channel) => {
                        const positive = channel;
                        const negative = (
                          this.state.temporaryLocation! as LocationBipolar
                        )?.positive;
                        const updateState = {
                          temporaryLocation: {
                            positive: positive,
                            negative: negative,
                          },
                        };
                        this.setState(updateState);
                        console.log(JSON.stringify(updateState));
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    -
                  </Grid>
                  <Grid item xs={5}>
                    <ChannelSelection
                      label="Negative"
                      value={
                        (this.state.temporaryLocation as LocationBipolar)
                          ?.negative
                      }
                      onChannelChange={(channel) => {
                        const positive = (
                          this.state.temporaryLocation! as LocationBipolar
                        )?.positive;
                        const negative = channel;
                        const updateState = {
                          temporaryLocation: {
                            positive: positive,
                            negative: negative,
                          },
                        };
                        this.setState(updateState);
                        console.log(JSON.stringify(updateState));
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {this.state.currentTab === TabValues.Lead && (
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={12}>
                    Choose the Lead below
                  </Grid>
                  <Grid item xs={12}>
                    <LeadSelection
                      location={this.state.temporaryLocation as LocationLead}
                      onLeadChange={(location) => {
                        const updateState = {
                          temporaryLocation: location,
                        };
                        this.setState(updateState);
                        console.log(JSON.stringify(updateState));
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {this.state.currentTab === TabValues.Manual && (
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={12}>
                    Define a manual location when none of the other options
                    apply
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={
                        (this.state.temporaryLocation as LocationLabeled)
                          ?.label || ""
                      }
                      onChange={(event) => {
                        const value = event.target.value;
                        this.setState({
                          temporaryLocation: {
                            label: value,
                          },
                        });
                      }}
                      placeholder="Manual location"
                      fullWidth={true}
                    ></TextField>
                  </Grid>
                </Grid>
              )}
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.onClose();
            }}
            color="secondary"
          >
            Cancel
          </Button>

          <Button
            onClick={() => this.saveLocation()}
            disabled={!this.canSave()}
            color="primary"
          >
            {this.state.inProgress ? <CircularProgress /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private saveLocation() {
    const location = this.state.temporaryLocation;
    const updateRequest: PutRecordingRequest = {
      signals: [{ id: this.props.signal.id, location: location }],
    };
    console.log(updateRequest);
    putRecording(
      this.props.groupId,
      this.props.recordingId,
      updateRequest
    ).then(
      (_response) => {
        this.setState({
          failed: false,
          inProgress: false,
        });
        this.props.onClose();
      },
      (rejected) => {
        console.error(rejected);
        const state = {
          failed: true,
          helperText: "",
          inProgress: false,
        };
        if (rejected.response.status === 400) {
          state.helperText = "Bad request";
        } else if (rejected.response.status === 500) {
          state.helperText = "Internal server error";
        }
        this.setState(state);
      }
    );
  }

  private canSave(): boolean {
    if (this.state.inProgress) {
      return false;
    }
    const location = this.state.temporaryLocation;
    if (!location) {
      return false;
    }
    const ch = location as LocationBipolar;
    if ((ch.positive && !ch.negative) || (!ch.positive && ch.negative)) {
      // positive and negative either both have to be set, or none
      return false;
    }
    return true;
  }
}

const ECGVALUES = [
  {
    value: "Lead I",
    label: "Lead I: RA (-) to LA (+)",
  },
  {
    value: "Lead II",
    label: "Lead II: RA (-) to LL (+)",
  },
  {
    value: "Lead III",
    label: "Lead III: LA (-) to LL (+)",
  },
];

interface ILeadProps {
  location: LocationLead;
  onLeadChange: (location: LocationLead | undefined) => void;
}

interface ILeadState {}

class LeadSelection extends Component<ILeadProps, ILeadState> {
  constructor(props: ILeadProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <Autocomplete
        options={ECGVALUES}
        value={this.findEcgValue(this.props.location)}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        // the "value" state with the value/onChange props combination.
        // This state represents the value selected by the user, for instance when pressing Enter.
        onChange={(
          _event: any,
          newValue: { value: string; label: string } | null
        ) => {
          if (newValue) {
            this.props.onLeadChange({ lead: newValue.value });
          } else {
            this.props.onLeadChange(undefined);
          }
        }}
      />
    );
  }

  private findEcgValue(
    location: LocationLead | undefined
  ): { value: string; label: string } | null {
    if (!location || !location.lead) {
      return null;
    } else {
      return ECGVALUES.find((v) => v.value === location.lead) || null;
    }
  }
}

enum LMR {
  Left = "Left",
  Middle = "Middle",
  Right = "Right",
}
enum TopDown {
  Top = "Top",
  Down = "Down",
}
enum ChannelLocation {
  Frontal = "Frontal",
  Frontopolar = "Frontopolar",
  Temporal = "Temporal",
  Central = "Central",
  Parietal = "Parietal",
  Occipital = "Occipital",
  Auricular = "Auricular",
  BehindTheEars = "Behind-The-Ears",
}
interface Channel {
  value: string;
  label: string;
  direction: LMR;
  location: ChannelLocation;
  topdown?: TopDown;
}

interface IChannelProps {
  label: string;
  value: string | undefined;
  onChannelChange: (channel: string) => void;
}
interface IChannelState {}
class ChannelSelection extends Component<IChannelProps, IChannelState> {
  channels: Channel[];
  constructor(props: IChannelProps) {
    super(props);
    this.state = {};
    this.channels = this.getAllChannels();
  }

  public render() {
    return (
      <Autocomplete
        options={this.channels}
        value={this.findChannel(this.props.value)}
        getOptionLabel={(option) => option.label}
        groupBy={(option) => option.direction}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={this.props.label} />
        )}
        // the "value" state with the value/onChange props combination.
        // This state represents the value selected by the user, for instance when pressing Enter.
        onChange={(
          _event: any,
          newValue: { value: string; label: string; direction: string } | null
        ) => {
          console.trace(`onChange(event, ${JSON.stringify(newValue)})`);
          if (newValue) {
            this.props.onChannelChange(newValue.value);
          }
        }}
      />
    );
  }

  private findChannel(value: string | undefined): Channel | null {
    if (!value || value === "") {
      return null;
    } else {
      return this.channels.find((v) => v.value === value) || null;
    }
  }

  private getAllChannels(): Channel[] {
    let r: Channel[] = [];
    r = r.concat(
      // Left
      this.chs("F", [1, 3, 5, 7], LMR.Left, ChannelLocation.Frontal),
      this.chs("Fp", [1], LMR.Left, ChannelLocation.Frontopolar),
      this.chs("T", [1, 3, 5, 7], LMR.Left, ChannelLocation.Temporal),
      this.chs("C", [1, 3, 5, 7], LMR.Left, ChannelLocation.Central),
      this.chs("P", [1, 3, 5, 7], LMR.Left, ChannelLocation.Parietal),
      this.chs("O", [1, 3, 5, 7], LMR.Left, ChannelLocation.Occipital),
      this.chs("A", [1], LMR.Left, ChannelLocation.Auricular),
      this.chs("B", [1], LMR.Left, ChannelLocation.BehindTheEars, TopDown.Top),
      this.chs("B", [3], LMR.Left, ChannelLocation.BehindTheEars, TopDown.Down),
      // Middle
      this.chs("Fp", ["Z"], LMR.Middle, ChannelLocation.Frontopolar),
      this.chs("F", ["Z"], LMR.Middle, ChannelLocation.Frontal),
      this.chs("T", ["Z"], LMR.Middle, ChannelLocation.Temporal),
      this.chs("C", ["Z"], LMR.Middle, ChannelLocation.Central),
      this.chs("P", ["Z"], LMR.Middle, ChannelLocation.Parietal),
      this.chs("O", ["Z"], LMR.Middle, ChannelLocation.Occipital),
      // Right
      this.chs("F", [2, 4, 6, 8], LMR.Right, ChannelLocation.Frontal),
      this.chs("Fp", [2], LMR.Right, ChannelLocation.Frontopolar),
      this.chs("T", [2, 4, 6, 8], LMR.Right, ChannelLocation.Temporal),
      this.chs("C", [2, 4, 6, 8], LMR.Right, ChannelLocation.Central),
      this.chs("P", [2, 4, 6, 8], LMR.Right, ChannelLocation.Parietal),
      this.chs("O", [2, 4, 6, 8], LMR.Right, ChannelLocation.Occipital),
      this.chs("A", [2], LMR.Right, ChannelLocation.Auricular),
      this.chs("B", [2], LMR.Right, ChannelLocation.BehindTheEars, TopDown.Top),
      this.chs("B", [4], LMR.Right, ChannelLocation.BehindTheEars, TopDown.Down)
    );

    return r;
  }

  private chs(
    value: string,
    numbers: any[], // number or Z
    lmr: LMR,
    loc: ChannelLocation,
    topdown?: TopDown
  ): Channel[] {
    return numbers.map((number) => {
      const lnr = value + number;
      const lbl = topdown
        ? `${lnr}: ${loc}, ${lmr}, ${topdown}`
        : `${lnr}: ${loc}, ${lmr}`;
      return {
        value: lnr,
        label: lbl,
        direction: lmr,
        location: loc,
        topdown: topdown,
      };
    });
  }
}
